// auth
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const RESET_AUTH_TOKEN = 'RESET_AUTH_TOKEN';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CATCH_UNAUTHORIZED = 'CATCH_UNAUTHORIZED';

export const SET_PROFILE = 'SET_PROFILE';

// company
export const SET_COMPANY_OPTIONS = 'SET_COMPANY_OPTIONS';
export const SET_COMPANIES_LIST = 'SET_COMPANIES_LIST';
export const SET_LOADING = 'SET_LOADING';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_USERS = 'SET_USERS';
export const CLEAR_COMPANY_STATE = 'CLEAR_COMPANY_STATE';

// modals
export const SHOW_USERS_POPUP = 'SHOW_USERS_POPUP';
export const SHOW_PRICING_POPUP = 'SHOW_PRICING_POPUP';
