import React from 'react';

export function IconSearch() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4183 3C9.45583 3 3.00098 9.31229 3.00098 17.0989C3.00098 24.8855 9.45583 31.1978 17.4183 31.1978C20.824 31.1978 23.9538 30.043 26.4208 28.112L31.1057 32.6815L31.2303 32.7865C31.6654 33.1013 32.2822 33.0655 32.6759 32.6795C33.1089 32.2549 33.108 31.5674 32.6739 31.144L28.044 26.6282C30.3985 24.1188 31.8356 20.773 31.8356 17.0989C31.8356 9.31229 25.3807 3 17.4183 3ZM17.4172 5.17196C24.1532 5.17196 29.6139 10.512 29.6139 17.0992C29.6139 23.6865 24.1532 29.0265 17.4172 29.0265C10.6812 29.0265 5.22054 23.6865 5.22054 17.0992C5.22054 10.512 10.6812 5.17196 17.4172 5.17196Z"
        fill="#344054"
      />
    </svg>
  );
}
