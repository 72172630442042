import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CompanyLogo, IconChevron } from 'components';
import {
  INSTALLATIONS_FREELANCER_SERVICE_ID,
  INSTALLATIONS_SERVICE_ID,
  PRINT_SERVICE_ID,
  SIGNAGE_SERVICE_ID,
  SUPPLIER_SERVICE_ID,
} from 'utils';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { RootState } from 'redux/reducers';
import { IProfile } from 'interfaces';
import { sendContactEmailAPI } from 'api';

const useStyles = makeStyles({
  infoContainer: {
    borderRadius: '8px',
    background: 'rgba(236, 236, 236, 0.3)',
    padding: '24px',
    marginRight: '20px',
  },
  accordion: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  rowText: {
    textAlign: 'right',
    width: '70%',
  },
  formSentMessage: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
});

const rows = {
  CERTIFICATIONS: 1,
  INSTALLATIONS_CAPABILITIES: 2,
  PRINT_SECTORS: 3,
  PRINT_CAPABILITIES: 4,
  SIGNAGE_CAPABILITIES: 5,
  SUPPLIER_SERVICES: 6,
};

const CompanyDetailsPopup = ({ company }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(1);
  const [submitted, setSubmitted] = useState(false);

  const profile = useSelector<RootState>((state) => state.auth.profile) as IProfile;

  const findService = (arr, id) => {
    return arr?.find((option) => option['id'] === id);
  };

  const isInstallationCompany = useMemo(
    () =>
      company?.services?.some(
        (el) => el.id === INSTALLATIONS_SERVICE_ID || el.id === INSTALLATIONS_FREELANCER_SERVICE_ID
      ),
    [company]
  );

  const InfoRow = ({ title, text }) => (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" my="14px">
        <Typography variant="subtitle2" className="is-uppercase" style={{ fontSize: '1.4rem' }}>
          {title}
        </Typography>

        <Typography
          className={classes.rowText}
          style={{ fontSize: '1.5rem', fontWeight: 500 }}
          variant="subtitle1"
        >
          {text}
        </Typography>
      </Box>

      <Divider variant="fullWidth" />
    </>
  );

  const ExpandableInfoRow = ({ id, title, options }) => (
    <>
      <Accordion
        expanded={expanded === id}
        onChange={(e, expanded) => setExpanded(expanded ? id : null)}
      >
        <AccordionSummary expandIcon={<IconChevron />}>
          <Typography variant="subtitle2" className="is-uppercase" style={{ fontSize: '1.4rem' }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordion}>
          {options &&
            options.map((option) => (
              <Chip label={option?.name} color="secondary" variant="filled" key={option?.id} />
            ))}
        </AccordionDetails>
      </Accordion>

      <Divider variant="fullWidth" />
    </>
  );

  const validationSchema = Yup.object().shape({
    notes: Yup.string().required(t('formErrors.required')).max(500, t('formErrors.maxLength')),
  });

  const onSubmit = async (values) => {
    try {
      await sendContactEmailAPI(company?.id, values);
      setSubmitted(true);
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <Box mt={2}>
      <Grid container gap="28px" flexWrap="nowrap">
        <Grid item xs={7} className={classes.infoContainer}>
          <Box display="flex" gap="20px" height="80px" mb="20px">
            <Box width="80px" borderRadius="8px" display="flex" alignItems="center">
              <CompanyLogo src={company?.thumbLogo} />
            </Box>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
              <Typography variant="body1" style={{ fontWeight: 500, fontSize: '18px' }}>
                {company?.name}
              </Typography>
              <Typography variant="body2" style={{ fontWeight: 500 }}>
                {company?.address?.city || 'N/A'}
              </Typography>
              <Typography variant="body2">
                <a href={`${company?.link}`} rel="noreferrer" target="_blank" className="is-link">
                  {company?.link}
                </a>
              </Typography>
            </Box>
          </Box>

          <Box>
            <Divider variant="fullWidth" />
            <InfoRow
              text={company?.services.map((service) => service.name).join(', ')}
              title={t('formLabel.services')}
            />
            <InfoRow title={t('formLabel.tradeType')} text={company?.tradeType?.name} />

            {isInstallationCompany && (
              <ExpandableInfoRow
                id={rows['CERTIFICATIONS']}
                options={company?.certifications}
                title={t('formLabel.certifications')}
              />
            )}

            {isInstallationCompany && (
              <ExpandableInfoRow
                id={rows['INSTALLATIONS_CAPABILITIES']}
                title={t('formLabel.installationsServices')}
                options={
                  findService(company?.services, INSTALLATIONS_SERVICE_ID)?.children ||
                  findService(company?.services, INSTALLATIONS_FREELANCER_SERVICE_ID)?.children
                }
              />
            )}

            {findService(company?.services, PRINT_SERVICE_ID) && (
              <>
                <ExpandableInfoRow
                  id={rows['PRINT_CAPABILITIES']}
                  options={findService(company?.services, PRINT_SERVICE_ID)?.children}
                  title={t('formLabel.printServices')}
                />
                <ExpandableInfoRow
                  id={rows['PRINT_SECTORS']}
                  options={company?.categories}
                  title={t('formLabel.sectors')}
                />
              </>
            )}

            {findService(company?.services, SIGNAGE_SERVICE_ID) && (
              <ExpandableInfoRow
                id={rows['SIGNAGE_CAPABILITIES']}
                options={findService(company?.services, SIGNAGE_SERVICE_ID)?.children}
                title={t('formLabel.signageServices')}
              />
            )}

            {findService(company?.services, SUPPLIER_SERVICE_ID) && (
              <ExpandableInfoRow
                id={rows['SUPPLIER_SERVICES']}
                options={findService(company?.services, SUPPLIER_SERVICE_ID)?.children}
                title={t('formLabel.supplierServices')}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={5} position="relative">
          <Box pt="24px" pb="16px">
            <Typography
              variant="body1"
              style={{ fontWeight: 500, fontSize: '18px', paddingBottom: '15px' }}
            >
              {t('directory.getInTouch')}
            </Typography>

            <Typography variant="subtitle2" style={{ fontSize: '1.rem', fontWeight: 500 }}>
              {`Let ${company?.name} know what you need.`}
              <br />
              {t('directory.willSendCopy')}
            </Typography>
          </Box>

          <Divider variant="fullWidth" />

          {submitted && (
            <Box className={classes.formSentMessage}>
              <Typography variant="h3">{t('directory.messageWasSent')}</Typography>
            </Box>
          )}
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: `${profile?.firstName} ${profile?.lastName}`,
              email: profile?.email,
              notes: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form style={{ visibility: submitted ? 'hidden' : 'visible' }}>
                <Box mt="18px">
                  <InputLabel>{t('formLabel.name')}</InputLabel>
                  <Typography variant="body1">{values.name}</Typography>
                </Box>

                <Box mt="18px" mb="13px">
                  <InputLabel>{t('formLabel.yourEmail')}</InputLabel>
                  <Typography variant="body1">{values.email}</Typography>
                </Box>

                <Field
                  autoComplete="none"
                  component={TextField}
                  label={t('formLabel.message')}
                  multiline
                  name="notes"
                  placeholder={t('placeholders.message')}
                  rows={5}
                  type="text"
                  maxLength="500"
                />

                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                    {t('action.send')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

export { CompanyDetailsPopup };
