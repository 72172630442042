import React, { Fragment, useState } from 'react';

import { IconDots } from 'components';
import { IconButton, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  icon: {
    border: '1px solid #E6E8EC !important',
    borderRadius: '8px !important',
    transition: '0.2s ease-out',

    '&:hover': {
      background: '#0046FF !important',

      '& path': {
        fill: 'white',
      },
    },
  },
});

export const TableAction = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton className={classes.icon} size="small" aria-controls="menu" onClick={handleClick}>
        <IconDots />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClick={() => setAnchorEl(null)}
      >
        {typeof children === 'function' ? children({ handleClose }) : children}
      </Menu>
    </Fragment>
  );
};
