import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconChevron, IconRefresh } from 'components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import {
  // END_CLIENTS_ONLY_ID,
  FULL_DIRECTORY,
  INSTALLATIONS_FREELANCER_SERVICE_ID,
  INSTALLATIONS_SERVICE_ID,
  KILOMETERS,
  MILES,
  PRINT_SERVICE_ID,
  SIGNAGE_SERVICE_ID,
  SUPPLIER_SERVICE_ID,
  TRADE_AND_END_CLIENTS_ID,
  TRADE_ONLY_ID,
} from 'utils';

const useStyles = makeStyles({
  actions: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '5px',
  },
  accordion: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
});

export const Filters = ({
  certifications,
  categories,
  distanceUnit,
  filtering,
  services,
  setDistanceUnit,
  setFilter,
  setManyFilters,
  // tradeTypes,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string>('');

  // const setTradeType = useCallback((values) => setFilter('tradeType', values), [setFilter]);
  const directoryType = localStorage.getItem('pathType') || '/installer-directory';

  // const tradeTypeOptions = useMemo(
  //   () =>
  //     tradeTypes
  //       .filter((el) => el.id !== END_CLIENTS_ONLY_ID)
  //       .map((el) => {
  //         if (el.id === TRADE_AND_END_CLIENTS_ID) {
  //           return { ...el, name: 'Trade and/or end clients' };
  //         }
  //
  //         return el;
  //       }),
  //   []
  // );

  const { installationServices, signageServices, printServices, printSectors, supplierServices } =
    useMemo(() => {
      const installation = services.find(
        (service) => service.id === INSTALLATIONS_SERVICE_ID
      )?.children;
      const signage = services.find((service) => service.id === SIGNAGE_SERVICE_ID)?.children;
      const print = services.find((service) => service.id === PRINT_SERVICE_ID)?.children;
      const supplier = services.find((service) => service.id === SUPPLIER_SERVICE_ID)?.children;

      return {
        installationServices: installation,
        signageServices: signage,
        printServices: print,
        printSectors: categories,
        supplierServices: supplier,
      };
    }, [services, categories]);

  const handleSelectChip = useCallback((name, option) => {
    const currentValue = filtering[name].value;

    if (currentValue.includes(option)) {
      setFilter(
        name,
        currentValue.filter((item) => item !== option)
      );
    } else {
      setFilter(name, [...currentValue, option]);
    }
  }, []);

  const onFiltersClear = useCallback(
    () =>
      setManyFilters({
        certifications: [],
        installerServices: [],
        signageServices: [],
        printServices: [],
        printSectors: [],
        supplierServices: [],
        tradeType: TRADE_AND_END_CLIENTS_ID,
      }),
    [setManyFilters]
  );

  const handleChangeAccordion = (name, isExpanded) => {
    setExpanded(isExpanded ? name : false);
  };

  const AccordionFilter = ({ name, label, options }) => {
    return (
      <Accordion
        expanded={expanded === name}
        onChange={(e, expanded) => handleChangeAccordion(name, expanded)}
      >
        <AccordionSummary expandIcon={<IconChevron />}>
          <Typography variant="subtitle2" className="is-uppercase" style={{ fontSize: '1.4rem' }}>
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordion}>
          {options.map((service) => (
            <Chip
              label={service.name}
              variant="outlined"
              key={service.id}
              onClick={() => handleSelectChip(name, service.name)}
              color={filtering[name].value.includes(service.name) ? 'primary' : 'secondary'}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Box className={classes.actions}>
        <Button
          color="primary"
          onClick={() => onFiltersClear()}
          startIcon={<IconRefresh />}
          style={{ height: '40px' }}
          variant="outlined"
        >
          {t('action.resetFilters')}
        </Button>

        <ToggleButtonGroup
          exclusive
          onChange={(e, value) => value && setDistanceUnit(value)}
          value={distanceUnit}
        >
          <ToggleButton value={KILOMETERS}>{KILOMETERS}</ToggleButton>
          <ToggleButton value={MILES}>{MILES}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Divider variant="fullWidth" />

      {/*<>*/}
      {/*  <Box mb="10px" mt="5px">*/}
      {/*    <MuiSelect*/}
      {/*      items={tradeTypeOptions}*/}
      {/*      label={t('formLabel.tradeSuppliers')}*/}
      {/*      onChange={setTradeType}*/}
      {/*      placeholder={t('formLabel.tradeType') as string}*/}
      {/*      value={filtering.tradeType.value}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*  <Divider variant="fullWidth" />*/}
      {/*</>*/}

      {directoryType === FULL_DIRECTORY ? (
        <>
          <AccordionFilter
            name="signageServices"
            label={t('formLabel.signageServices')}
            options={signageServices}
          />

          <Divider variant="fullWidth" />

          <AccordionFilter
            name="printServices"
            label={t('formLabel.printServices')}
            options={printServices}
          />

          <Divider variant="fullWidth" />

          <AccordionFilter
            name="installerServices"
            label={t('formLabel.installationsServices')}
            options={installationServices}
          />

          <Divider variant="fullWidth" />

          <AccordionFilter
            name="certifications"
            label={t('formLabel.certifications')}
            options={certifications}
          />

          <Divider variant="fullWidth" />

          <AccordionFilter
            name="printSectors"
            label={t('formLabel.sectors')}
            options={printSectors}
          />

          <Divider variant="fullWidth" />

          <AccordionFilter
            name="supplierServices"
            label={t('formLabel.supplierServices')}
            options={supplierServices}
          />

          <Divider variant="fullWidth" />
        </>
      ) : (
        <>
          <AccordionFilter
            name="installerServices"
            label={t('formLabel.installationsServices')}
            options={installationServices}
          />

          <Divider variant="fullWidth" />

          <AccordionFilter
            name="certifications"
            label={t('formLabel.certifications')}
            options={certifications}
          />

          <Divider variant="fullWidth" />
        </>
      )}
    </>
  );
};

export const getFilterOptions = () => ({
  tradeType: {
    value: TRADE_AND_END_CLIENTS_ID,
    handler(company) {
      if (this.value === TRADE_AND_END_CLIENTS_ID) {
        return (
          company?.tradeType?.id === TRADE_ONLY_ID ||
          company?.tradeType?.id === TRADE_AND_END_CLIENTS_ID
        );
      } else {
        return company?.tradeType?.id === this.value;
      }
    },
  },
  certifications: {
    value: [],
    handler(company) {
      if (!this.value.length) return true;
      const values: number[] = this.value;

      return values.every(
        (value) => !!company.certifications.find((certification) => certification.name === value)
      );
    },
  },
  installerServices: {
    value: [],
    handler(company) {
      if (!this.value.length) return true;

      const values: string[] = this.value;
      const installationService =
        company.services.find(
          (el) =>
            el.id === INSTALLATIONS_SERVICE_ID || el.id === INSTALLATIONS_FREELANCER_SERVICE_ID
        ) ?? [];

      return values.every(
        (value) => !!installationService.children?.find((service) => service.name === value)
      );
    },
  },
  signageServices: {
    value: [],
    handler(company) {
      if (!this.value.length) return true;

      const values: string[] = this.value;
      const signageService = company.services.find((el) => el.id === SIGNAGE_SERVICE_ID) ?? [];

      return values.every(
        (value) => !!signageService.children?.find((service) => service.name === value)
      );
    },
  },
  printServices: {
    value: [],
    handler(company) {
      if (!this.value.length) return true;

      const values: string[] = this.value;
      const printService = company.services.find((el) => el.id === PRINT_SERVICE_ID) ?? [];

      return values.every(
        (value) => !!printService.children?.find((service) => service.name === value)
      );
    },
  },
  printSectors: {
    value: [],
    handler(company) {
      if (!this.value.length) return true;

      const values: string[] = this.value;

      return values.every(
        (value) => !!company.categories?.find((category) => category.name === value)
      );
    },
  },
  supplierServices: {
    value: [],
    handler(company) {
      if (!this.value.length) return true;

      const values: string[] = this.value;
      const supplierService = company.services.find((el) => el.id === SUPPLIER_SERVICE_ID) ?? [];

      return values.every(
        (value) => !!supplierService.children?.find((service) => service.name === value)
      );
    },
  },
});
