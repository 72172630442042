import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  IconLogoCom,
  IconLogoDirectory,
  IconLogoFullDirectory,
  IconSettings,
  IconUKGALogo,
} from 'components';
import { routes } from 'config';
import { Settings } from './Settings';
// import { showPricingPopup } from 'redux/actions';
// import { useTypedDispatch } from 'redux/reducers';
// import { useProfileInfo } from 'hooks';
import styles from './header.module.scss';

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  // const dispatch = useTypedDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const { isPlanExpired } = useProfileInfo();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  return (
    <Box sx={{ boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.05)' }}>
      {/* Temporary hide banner */}
      {/*{location.pathname === routes.directory && isPlanExpired && (*/}
      {/*  <Box*/}
      {/*    height="35px"*/}
      {/*    bgcolor="rgba(0, 70, 255, 0.1)"*/}
      {/*    display="flex"*/}
      {/*    alignItems="center"*/}
      {/*    justifyContent="center"*/}
      {/*  >*/}
      {/*    <Typography variant="body1" style={{ color: 'black' }}>*/}
      {/*      {t('common.freeUntil')}*/}
      {/*      {'Save 70% '}*/}
      {/*      <span*/}
      {/*        className="is-pointer is-underlined is-bold"*/}
      {/*        onClick={() => dispatch(showPricingPopup(true))}*/}
      {/*      >*/}
      {/*        upgrade here*/}
      {/*      </span>*/}
      {/*      {' before then.'}*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*)}*/}
      <div className={styles.container}>
        <Box className="is-pointer" onClick={() => history.push(routes.home)}>
          {location?.pathname === routes.installerDirectory ? (
            <IconLogoDirectory />
          ) : location?.pathname === routes.fullDirectory ? (
            <IconLogoFullDirectory />
          ) : (
            <IconLogoCom />
          )}
        </Box>

        {location?.pathname.includes(routes.entry) && (
          <Box ml="20px">
            <IconUKGALogo width="75px" />
          </Box>
        )}

        <Box display="flex" alignItems="center" ml="auto">
          {location.pathname !== routes.home && location?.pathname === routes.tickets && (
            <Box display="flex" mr={2}>
              <Typography
                className="is-pointer"
                onClick={() => history.push(routes.installerDirectory)}
              >
                {t('action.goToDirectory')}
              </Typography>
              <Box mx={1} />
            </Box>
          )}

          <IconButton aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            <IconSettings />
          </IconButton>
        </Box>

        <Settings anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </div>
    </Box>
  );
};

export { Header };
