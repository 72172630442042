import React from 'react';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import Container from './Container';
import { Box, Button, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';

import { routes } from 'config';
import { buildPasswordRegex } from 'utils';
import { resetPassword } from 'redux/actions';
import { useTypedDispatch } from 'redux/reducers';

import styles from './auth.module.scss';

const ResetPassword: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useTypedDispatch();
  const { token } = queryString.parse(location.search);

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('formErrors.required'))
      .matches(buildPasswordRegex(8), t('formErrors.passwordTooWeak')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], t('formErrors.passwordNotMatch'))
      .required(t('formErrors.required')),
  });

  const onSubmit = async (values) => {
    try {
      await dispatch(resetPassword(token as string, values));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <Box pb={3}>
        <Typography component="h1" variant="h1">
          {t('auth.resetPassword')}
        </Typography>
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { isSubmitting } = props;

          return (
            <>
              <Form className={styles.form} noValidate autoComplete="off">
                <Field
                  autoComplete="none"
                  component={TextField}
                  label={t('formLabel.password')}
                  name="password"
                  placeholder={t('placeholders.password')}
                  type="password"
                />
                <Field
                  autoComplete="none"
                  component={TextField}
                  label={t('formLabel.passwordConfirm')}
                  name="passwordConfirmation"
                  placeholder={t('placeholders.passwordConfirm')}
                  type="password"
                />
                <Box mt={2} ml="auto">
                  <Button
                    className={styles.prevBtn}
                    onClick={() => history.push(routes.signIn)}
                    variant="outlined"
                    size="small"
                  >
                    {t('action.back')}
                  </Button>
                  <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                    {t('action.resetPassword')}
                  </Button>
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
      <Box position="absolute" bottom="10%">
        <Typography variant="body2">
          {t('auth.dontHaveAccount')}
          <Link to={routes.getStarted} className="is-link">
            {t('action.signUp')}
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export { ResetPassword };
