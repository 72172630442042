import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';

const useStyles = makeStyles({
  root: {
    fontSize: '32px',
  },
});

export const NotFound = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <h1 className={classes.root}>404: NOT FOUND =(</h1>
      <Button onClick={() => history.push('/')} color="primary" variant="contained">
        Go Home
      </Button>
    </Box>
  );
};
