import React from 'react';

export function IconLogoCom() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200.47 144.04"
      width="240px"
      height="30px"
    >
      <rect x="910.03" y="93.36" width="21.78" height="19.16" />
      <path
        d="M95.36,303.35q-6.63-5.52-21.13-8.83a44.82,44.82,0,0,1-9-2.89,9.58,9.58,0,0,1-4.14-3.24,7.14,7.14,0,0,1-1-3.76,6.92,6.92,0,0,1,3-5.77c2-1.49,4.8-2.23,8.51-2.23a22.61,22.61,0,0,1,6,.72A13.76,13.76,0,0,1,82,279.51l1.47,6.76a4.45,4.45,0,0,0,4.33,3.49h11l-.21-21.35A48,48,0,0,0,86.31,264a71.46,71.46,0,0,0-16.12-1.7q-10.53,0-17.28,3.25a23.16,23.16,0,0,0-10,8.58,22.15,22.15,0,0,0-3.28,11.83,20.15,20.15,0,0,0,6.56,15.51q6.57,6.06,20.41,8.94,9.17,1.89,12.23,4.37a7.27,7.27,0,0,1,3.06,5.73A7.11,7.11,0,0,1,79,326.25q-2.88,2.31-9.95,2.31a28,28,0,0,1-6.68-.72A25.64,25.64,0,0,1,57,325.89L55,318a4.44,4.44,0,0,0-4.3-3.36H39.53l.22,22.07a74,74,0,0,0,14.46,4.76,75.42,75.42,0,0,0,16.27,1.66q15.72,0,23.62-6.71t7.9-17.6Q102,308.88,95.36,303.35Z"
        transform="translate(-39.53 -229.1)"
      />
      <rect x="75.41" width="21.78" height="17.96" />
      <path
        d="M143.33,328.24l-6.6-1.41V263.57H104.42v9A4.42,4.42,0,0,0,108,277l7.66,1.47v48.4L109,328.25a4.42,4.42,0,0,0-3.51,4.33v9h41.33v-9A4.44,4.44,0,0,0,143.33,328.24Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M206.89,263.57H204.2a4.43,4.43,0,0,0-4.38,3.76l-.93,6.05a25.62,25.62,0,0,0-8.59-8.33,23.6,23.6,0,0,0-12-2.92,25.6,25.6,0,0,0-16.09,5.16,32.53,32.53,0,0,0-10.31,14.39,58.92,58.92,0,0,0-3.61,21.56v1.52a51.91,51.91,0,0,0,3.61,20.12,29.94,29.94,0,0,0,10.46,13.45,27.78,27.78,0,0,0,16.37,4.8,24,24,0,0,0,10.75-2.31,23.65,23.65,0,0,0,8.15-6.7v5.12c0,5.57-1.31,9.93-3.93,13.05S186.91,357,181.29,357a48.8,48.8,0,0,1-10.57-1.26q-3.25-.74-6.32-1.75a4.4,4.4,0,0,0-5.63,3.1l-2.66,10.22a52.54,52.54,0,0,0,13,4.33,66.35,66.35,0,0,0,13.27,1.52q11.32,0,19.47-4.19a29.71,29.71,0,0,0,12.52-11.75,35.23,35.23,0,0,0,4.36-17.89V278.22l5.65-1.2a4.44,4.44,0,0,0,3.51-4.34v-9.11h-21Zm-9.3,56.26a13.73,13.73,0,0,1-5.27,5.16,15.89,15.89,0,0,1-7.72,1.77q-8.22,0-11.75-5.88t-3.54-16.12v-1.52a47,47,0,0,1,1.59-12.94,18.43,18.43,0,0,1,5-8.66,12.45,12.45,0,0,1,8.8-3.14,14.63,14.63,0,0,1,7.61,1.95,14.85,14.85,0,0,1,5.3,5.48Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M318.29,328.24l-6.59-1.41v-34q0-15.87-6.57-23.3T287,262.13a25.71,25.71,0,0,0-13.09,3.32,28.46,28.46,0,0,0-9.63,9.3l-.59-7.12a4.44,4.44,0,0,0-4.42-4.06H232.5v9a4.42,4.42,0,0,0,3.59,4.35l7.59,1.47v48.4l-6.6,1.41a4.44,4.44,0,0,0-3.5,4.34v9h39.67v-9.18a4.43,4.43,0,0,0-3.34-4.3l-5.17-1.31v-41a15.87,15.87,0,0,1,5.59-5.44,15.64,15.64,0,0,1,7.9-1.92q6.12,0,9.23,3.29t3.1,11.14v33.9l-5.62,1.35a4.43,4.43,0,0,0-3.39,4.31v9.13h40.24v-9A4.44,4.44,0,0,0,318.29,328.24Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M398.06,328.45l-4.34-.61a22.19,22.19,0,0,1-.25-3c0-1,0-2.08,0-3.14V289.54q0-13.2-8.87-20.3t-24.17-7.11a55,55,0,0,0-15.94,2.16,65.94,65.94,0,0,0-13.27,5.63l.29,20.27h11.37a4.44,4.44,0,0,0,4.38-3.73l1.06-6.59a17,17,0,0,1,4.87-2.34,20.81,20.81,0,0,1,6.09-.83q6.57,0,9.85,3.5a12.82,12.82,0,0,1,3.28,9.12v5.77H362.05q-11.46,0-19.29,3.18A26.22,26.22,0,0,0,330.93,307a21.52,21.52,0,0,0-4,12.92q0,10.74,6.85,17t18.46,6.24a24,24,0,0,0,11.94-2.85,29.53,29.53,0,0,0,8.84-7.61,26.9,26.9,0,0,0,1.15,4.62c.16.46.32.92.48,1.36a4.44,4.44,0,0,0,4.18,3h23v-8.78A4.44,4.44,0,0,0,398.06,328.45Zm-25.69-8.11a15.58,15.58,0,0,1-6.13,5.23,18.53,18.53,0,0,1-8.37,2q-4.69,0-7.32-2.27a8.06,8.06,0,0,1-2.63-6.46,9.86,9.86,0,0,1,3.57-7.64Q355.06,308,362,308h10.39Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M463.27,263.57h-2.69a4.45,4.45,0,0,0-4.38,3.76l-.93,6.05a25.62,25.62,0,0,0-8.59-8.33,23.6,23.6,0,0,0-12-2.92,25.64,25.64,0,0,0-16.09,5.16,32.53,32.53,0,0,0-10.31,14.39,58.92,58.92,0,0,0-3.61,21.56v1.52a51.91,51.91,0,0,0,3.61,20.12,29.94,29.94,0,0,0,10.46,13.45,27.8,27.8,0,0,0,16.37,4.8,24,24,0,0,0,10.75-2.31,23.65,23.65,0,0,0,8.15-6.7v5.12c0,5.57-1.31,9.93-3.93,13.05S443.29,357,437.67,357a48.87,48.87,0,0,1-10.57-1.26,64.85,64.85,0,0,1-6.31-1.75,4.41,4.41,0,0,0-5.64,3.1l-2.65,10.22a52.46,52.46,0,0,0,13,4.33,66.43,66.43,0,0,0,13.27,1.52q11.33,0,19.47-4.19a29.71,29.71,0,0,0,12.52-11.75,35.23,35.23,0,0,0,4.36-17.89V278.22l5.66-1.2a4.44,4.44,0,0,0,3.5-4.34v-9.11h-21ZM454,319.83A13.73,13.73,0,0,1,448.7,325a15.85,15.85,0,0,1-7.71,1.77q-8.24,0-11.76-5.88t-3.54-16.12v-1.52a47,47,0,0,1,1.59-12.94,18.43,18.43,0,0,1,5-8.66,12.45,12.45,0,0,1,8.8-3.14,14.63,14.63,0,0,1,7.61,1.95,14.85,14.85,0,0,1,5.3,5.48Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M537.58,266.31q-7.22-4.17-17.82-4.18a32.87,32.87,0,0,0-18.64,5.09,34.21,34.21,0,0,0-12.23,14.13,46.82,46.82,0,0,0-4.33,20.6v2.88a41.62,41.62,0,0,0,4.58,19.76,33.92,33.92,0,0,0,12.95,13.6,37.76,37.76,0,0,0,19.55,4.94,50.68,50.68,0,0,0,16.77-2.56,40.71,40.71,0,0,0,12.15-6.45l-3.77-8.6a4.4,4.4,0,0,0-5.87-2.26,40.66,40.66,0,0,1-5.83,2.24A38.16,38.16,0,0,1,524.31,327a18.37,18.37,0,0,1-9.45-2.27,15.56,15.56,0,0,1-6-6.27,23.44,23.44,0,0,1-2.56-9.13l.15-.43h41.44a4.43,4.43,0,0,0,4.43-4.43v-7q0-11.17-3.75-19A27,27,0,0,0,537.58,266.31Zm-5,28.64h-26l-.21-.36a31.3,31.3,0,0,1,2.2-8.66,14.5,14.5,0,0,1,4.5-6,11.31,11.31,0,0,1,7.07-2.2,12.6,12.6,0,0,1,7.18,1.88,10.59,10.59,0,0,1,4,5.3,23.72,23.72,0,0,1,1.26,8.18Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M640.24,308.8a64.79,64.79,0,0,0,2.45-18.32h-11.5a4.39,4.39,0,0,0-4.4,3.94,37.05,37.05,0,0,1-1.26,6.73,38.92,38.92,0,0,1-4,9.38L601.58,287.3l6.71-4.76A34.54,34.54,0,0,0,618,272.3a24.43,24.43,0,0,0,3.54-12.91,23.47,23.47,0,0,0-3.28-12.26,24.32,24.32,0,0,0-9.09-8.77,27,27,0,0,0-13.45-3.28,32,32,0,0,0-15.11,3.39,24.28,24.28,0,0,0-10,9.52,28.51,28.51,0,0,0-3.54,14.5,27.47,27.47,0,0,0,2.42,11.18,64.78,64.78,0,0,0,7.32,12.12c-.24.14-.49.31-.76.5l-.76.58q-8.79,6.27-13.12,12.3a23.6,23.6,0,0,0-4.33,14.17A28.65,28.65,0,0,0,562,328.92,28.33,28.33,0,0,0,574,339.38a41.13,41.13,0,0,0,18.17,3.75,47.45,47.45,0,0,0,13.92-2,50.34,50.34,0,0,0,12.63-5.95l5.55,6.42H647.6l.14-.36-15-17.6A44,44,0,0,0,640.24,308.8Zm-51.86-54.35a8.73,8.73,0,0,1,7.21-3.14,9,9,0,0,1,6.89,2.81,9.14,9.14,0,0,1,2.64,6.42,12.9,12.9,0,0,1-1.41,6,14.94,14.94,0,0,1-5.45,5.34l-6.2,4a39.28,39.28,0,0,1-4.65-7.25,14.83,14.83,0,0,1-1.55-6.39A12.09,12.09,0,0,1,588.38,254.45ZM600.86,326a30.86,30.86,0,0,1-7.65,1,13.71,13.71,0,0,1-10.38-4.14,14.66,14.66,0,0,1-4-10.57,17.41,17.41,0,0,1,1.34-6.6,19.75,19.75,0,0,1,5-6.74l1.37-.94,21.64,25.1A31.12,31.12,0,0,1,600.86,326Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M697.49,262.13a24.51,24.51,0,0,0-19.77,9.09l-.34-3.63a4.43,4.43,0,0,0-4.41-4h-15.3V371.62h20.85V334.84q7.28,8.22,19.11,8.22,13.92,0,22.11-10.86t8.18-28.88v-1.09q0-18.75-8-29.42T697.49,262.13Zm-5.34,64.12q-9.81,0-13.63-7.71v-32q3.68-7.5,13.48-7.5A12.56,12.56,0,0,1,703.22,285q3.85,5.91,3.86,16.8Q707.08,326.25,692.15,326.25Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M756.5,272.88l-.32-5.15a4.43,4.43,0,0,0-4.42-4.16H736.23v78.05h20.84V290.55q3.69-8,15.51-8c.88,0,1.81,0,2.8.1a4.45,4.45,0,0,0,4.77-4.36l.22-15.21a21.67,21.67,0,0,0-6.49-.94Q762.77,262.13,756.5,272.88Z"
        transform="translate(-39.53 -229.1)"
      />
      <rect x="748.71" width="21.78" height="17.96" />
      <rect x="749.14" y="34.48" width="20.92" height="78.04" />
      <path
        d="M864.84,262.13q-14.05,0-22.43,10.46l-.35-4.9a4.44,4.44,0,0,0-4.42-4.12h-15.5v78.05H843v-55a14.33,14.33,0,0,1,13.2-7.5q6.7,0,9.59,3t2.89,9.63v49.92h20.84V291.2q-.15-14.51-6.2-21.78T864.84,262.13Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M928.57,324c-1.1-1.25-1.66-3.32-1.66-6.21V278.86h13.35V263.57H926.91V244.39H910.5a4.43,4.43,0,0,0-4.43,4.43v14.75h-11.4v10.86a4.43,4.43,0,0,0,4.43,4.43h7v42.06q.43,22.14,22.36,22.14a42.91,42.91,0,0,0,12.62-1.88V325.39a33.84,33.84,0,0,1-6.13.5C931.79,325.89,929.68,325.27,928.57,324Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M999.35,284.25q3.72-5.31,11-5.31a12.53,12.53,0,0,1,9.52,3.8,13.34,13.34,0,0,1,3.33,6.35,4.48,4.48,0,0,0,4.34,3.55h15.63q-.15-13.84-9-22.18t-23.48-8.33q-16.73,0-26.32,10.75t-9.6,28.78V303q0,18.75,9.67,29.39t26.4,10.64a36.55,36.55,0,0,0,16.33-3.64,28.54,28.54,0,0,0,11.65-10.25,26.72,26.72,0,0,0,4.33-14.53h-15.89a4.36,4.36,0,0,0-4.14,3.28,10.58,10.58,0,0,1-3.27,5.16,13.64,13.64,0,0,1-9.37,3.17q-7.51,0-11.18-5.27t-3.68-17.34v-2.17Q995.64,289.56,999.35,284.25Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M1111.12,271.8q-10-9.68-25.79-9.67a37.55,37.55,0,0,0-19.51,5A32.72,32.72,0,0,0,1053,281.17a46.31,46.31,0,0,0-4.47,20.7v.94q0,18.62,10.06,29.43t26.94,10.82q16.88,0,26.9-10.86t10-28.81l-.15-5.34Q1121.11,281.46,1111.12,271.8Zm-13.81,48.64a13.85,13.85,0,0,1-11.83,5.81q-7.8,0-12-5.88t-4.18-17q0-12.5,4.18-18.45a13.6,13.6,0,0,1,11.83-6,13.85,13.85,0,0,1,12,6q4.25,6,4.25,16.91Q1101.56,314.62,1097.31,320.44Z"
        transform="translate(-39.53 -229.1)"
      />
      <path
        d="M1233.8,269.52q-6.21-7.38-18.9-7.39-15.23,0-23.44,11.9-5.62-11.89-20.7-11.9-14.15,0-22.44,10.17l-.34-4.62a4.43,4.43,0,0,0-4.42-4.11h-15.43v78.05H1149V286.44q4-7.37,12.84-7.36,6.42,0,9.09,3t2.67,9.81v49.7h20.84l-.07-53.6q3.62-8.94,13-8.94,6.56,0,9.16,3.07t2.59,9.7v49.77H1240V291.78Q1240,276.92,1233.8,269.52Z"
        transform="translate(-39.53 -229.1)"
      />
    </svg>
  );
}
