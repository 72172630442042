import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  Header,
  IconDirectoryMultiline,
  IconFullDirectoryMultiline,
  IconUKGALogo,
  Loading,
} from 'components';
import { Box, Typography } from '@mui/material';
import { routes } from 'config';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { makeStyles } from '@mui/styles';
import { FULL_DIRECTORY } from 'utils';

const useStyles = makeStyles({
  container: {
    width: '100%',
    flexWrap: 'wrap',
  },
  title: {
    ['@media (max-width: 762px)']: {
      '&&': {
        fontSize: '3rem',
      },
    },
  },
});

export const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [showTickets, setShowTickets] = useState(false);
  const loading = useSelector<RootState>((state) => state.company?.loading) as boolean;
  const directoryType = localStorage.getItem('pathType') || '/installer-directory';

  if (loading) {
    return <Loading size={64} />;
  }

  return (
    <Box height="100vh">
      <Header />

      <Box
        style={{
          height: 'calc(90% - 70px)',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h1"
          className={classes.title}
          style={{ textAlign: 'center', color: 'black' }}
        >
          {t('home.whereToGo')}
        </Typography>
        <Box
          className={classes.container}
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
          mt={5}
        >
          {/*<Box>*/}
          {/*  <Box*/}
          {/*    className="is-pointer"*/}
          {/*    display="flex"*/}
          {/*    mr="50px"*/}
          {/*    onClick={() => history.push(routes.entry)}*/}
          {/*  >*/}
          {/*    <IconFingerCrossed />*/}
          {/*  </Box>*/}
          {/*  <Box my="25px" />*/}
          {/*  <Typography variant="subtitle1">{t('home.checkYourEntry')}</Typography>*/}
          {/*</Box>*/}

          {/*<Box mx="2%" />*/}

          <Box>
            <Box
              className="is-pointer"
              mr="50px"
              display="flex"
              onClick={() => setShowTickets(true)}
            >
              <IconUKGALogo />
            </Box>
            <Box my="25px" />
            <Typography variant="subtitle1">{t('home.buyTickets')}</Typography>
          </Box>

          <Box mx="2%" />

          <Box>
            <Box
              className="is-pointer"
              onClick={() =>
                history.push(
                  directoryType === FULL_DIRECTORY
                    ? routes.fullDirectory
                    : routes.installerDirectory
                )
              }
            >
              {directoryType === FULL_DIRECTORY ? (
                <IconFullDirectoryMultiline />
              ) : (
                <IconDirectoryMultiline />
              )}
            </Box>
            <Typography variant="subtitle1">
              {t(directoryType === FULL_DIRECTORY ? 'home.directory' : 'home.installerDirectory')}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Dialog isOpen={showTickets} maxWidth="lg" onClose={() => setShowTickets(false)}>
        <Box>
          <iframe
            src="https://www.signageandprint.com/embed/sign-wrap-awards-2024-tickets"
            title="tickets"
            style={{ width: '100%', height: '80vh', border: 'none', padding: '5px' }}
          ></iframe>
        </Box>
      </Dialog>
    </Box>
  );
};
