import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from './useDebounce';

export const useFiltering = (data, filterOptions) => {
  const [items, setItems] = useState(data);
  const [filtering, setFiltering] = useState<{ [key: string]: any }>(filterOptions);

  const debouncedFilter = useDebounce(filtering, 200);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const setFilter = (filterName, value) => {
    const updatedFilter = {
      ...debouncedFilter,
    };

    updatedFilter[filterName].value = value;

    setFiltering(updatedFilter);
  };

  const setManyFilters = (filters) => {
    const updatedFilter = {
      ...debouncedFilter,
    };
    const filtersToUpdate = Object.keys(updatedFilter);

    filtersToUpdate.forEach((el) => (updatedFilter[el].value = filters[el]));

    setFiltering(updatedFilter);
  };

  const filteredData = useMemo(() => {
    const filterOptions = Object.keys(debouncedFilter);
    if (!filterOptions.length) return items;

    return items.filter((member) =>
      filterOptions.every((option) => debouncedFilter[option].handler(member))
    );
  }, [debouncedFilter, items]);

  return {
    filteredData,
    setFilter,
    setManyFilters,
    filtering,
  };
};
