import React from 'react';

export function IconFullDirectoryMultiline() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1366 426"
      width="313"
      height="150px"
    >
      <defs></defs>
      <path d="m26.34,369.98h-8.34l31.89-82.93h8.29l-31.84,82.93Z" />
      <path d="m68.99,123.14c-5.8-1.34-10.21-2.76-13.25-4.24-3.04-1.49-5.07-3.08-6.1-4.77-1.02-1.7-1.54-3.53-1.54-5.51,0-3.46,1.45-6.29,4.35-8.48,2.9-2.19,7.07-3.29,12.51-3.29,3.25,0,6.17.36,8.75,1.06,2.58.71,4.82,1.77,6.73,3.18l2.17,9.93c.65,2.99,3.3,5.12,6.36,5.12h16.17l-.32-31.38c-4.88-2.69-10.9-4.86-18.07-6.52-7.17-1.66-15.07-2.49-23.69-2.49-10.32,0-18.78,1.59-25.39,4.77-6.61,3.18-11.52,7.38-14.74,12.61-3.22,5.23-4.82,11.03-4.82,17.39,0,9.26,3.22,16.86,9.65,22.79,6.43,5.94,16.43,10.32,30,13.14,8.98,1.84,14.96,3.98,17.97,6.42,3,2.44,4.51,5.25,4.51,8.43,0,3.39-1.41,6.22-4.24,8.48-2.83,2.26-7.7,3.39-14.63,3.39-3.68,0-6.94-.36-9.81-1.06-2.86-.7-5.5-1.66-7.9-2.86l-2.9-11.6c-.73-2.9-3.33-4.93-6.32-4.93h-16.43l.32,32.44c6.78,3.04,13.87,5.37,21.26,7,7.38,1.62,15.35,2.44,23.91,2.44,15.41,0,26.98-3.29,34.72-9.86,7.74-6.57,11.61-15.19,11.61-25.87,0-9.75-3.25-17.33-9.75-22.74-6.5-5.4-16.86-9.74-31.06-12.98Z" />
      <path d="m170.55,172.71l-9.69-2.07v-92.97h-47.49v13.28c0,3.12,2.22,5.81,5.28,6.4l11.25,2.17v71.13l-9.79,2.08c-3.01.64-5.16,3.3-5.16,6.37v13.28h60.74v-13.29c0-3.07-2.15-5.72-5.15-6.37Z" />
      <rect x="128.84" y="26.99" width="32.02" height="26.4" />
      <path d="m263.97,77.66h-3.96c-3.21,0-5.95,2.35-6.44,5.52l-1.37,8.9c-3.46-5.3-7.67-9.38-12.62-12.24-4.95-2.86-10.85-4.29-17.7-4.29-9.19,0-17.07,2.53-23.64,7.58-6.57,5.06-11.63,12.11-15.16,21.15-3.53,9.05-5.3,19.61-5.3,31.7v2.23c0,11.24,1.77,21.1,5.3,29.58,3.53,8.48,8.66,15.07,15.37,19.77,6.71,4.7,14.74,7.05,24.06,7.05,5.94,0,11.2-1.13,15.8-3.39,4.59-2.26,8.59-5.55,11.98-9.86v7.53c0,8.2-1.93,14.59-5.78,19.19-3.85,4.59-9.91,6.89-18.18,6.89-4.81,0-9.98-.62-15.53-1.85-3.19-.71-6.29-1.57-9.28-2.58-3.55-1.19-7.34.94-8.28,4.56l-3.9,15.03c5.51,2.76,11.87,4.88,19.08,6.36,7.21,1.49,13.71,2.23,19.51,2.23,11.09,0,20.64-2.05,28.62-6.15,7.98-4.1,14.12-9.86,18.39-17.28,4.27-7.42,6.41-16.19,6.41-26.29v-89.78l8.31-1.77c3.01-.64,5.15-3.3,5.15-6.37v-13.39h-30.85Zm-13.68,82.69c-1.98,3.32-4.56,5.85-7.74,7.58-3.18,1.73-6.96,2.6-11.34,2.6-8.06,0-13.82-2.88-17.28-8.64-3.46-5.76-5.19-13.66-5.19-23.69v-2.23c0-7.28.78-13.62,2.33-19.03,1.55-5.4,3.99-9.65,7.31-12.72,3.32-3.07,7.63-4.61,12.93-4.61,4.24,0,7.97.95,11.18,2.86,3.21,1.91,5.81,4.6,7.79,8.06v49.82Z" />
      <path d="m427.7,172.71l-9.69-2.07v-49.93c0-15.55-3.22-26.96-9.65-34.24-6.43-7.28-15.34-10.92-26.71-10.92-7.21,0-13.62,1.63-19.24,4.88-5.62,3.25-10.34,7.81-14.15,13.67l-.88-10.46c-.28-3.37-3.1-5.97-6.49-5.97h-39.28v13.28c0,3.12,2.21,5.8,5.28,6.4l11.16,2.16v71.13l-9.69,2.07c-3,.65-5.15,3.3-5.15,6.37v13.29h58.31v-13.49c0-2.98-2.02-5.58-4.91-6.31l-7.6-1.93v-60.21c2.19-3.46,4.93-6.13,8.22-8,3.29-1.87,7.16-2.81,11.61-2.81,6.01,0,10.53,1.61,13.57,4.83,3.04,3.21,4.56,8.67,4.56,16.38v49.82l-8.26,1.98c-2.93.7-4.99,3.32-4.99,6.34v13.42h59.15v-13.29c0-3.07-2.15-5.72-5.15-6.37Z" />
      <path d="m544.94,173.02l-6.38-.9c-.21-1.41-.34-2.88-.37-4.4-.04-1.52-.05-3.06-.05-4.61v-47.28c0-12.93-4.35-22.88-13.04-29.84-8.69-6.96-20.53-10.44-35.51-10.44-8.62,0-16.43,1.06-23.43,3.18-7,2.12-13.5,4.88-19.51,8.27l.42,29.79h16.71c3.2,0,5.92-2.32,6.43-5.48l1.56-9.68c2.12-1.49,4.51-2.63,7.16-3.45,2.65-.81,5.64-1.22,8.96-1.22,6.43,0,11.25,1.72,14.47,5.14,3.21,3.43,4.82,7.9,4.82,13.41v8.48h-15.16c-11.24,0-20.69,1.56-28.36,4.67-7.67,3.11-13.46,7.38-17.39,12.83-3.92,5.44-5.88,11.77-5.88,18.97,0,10.53,3.36,18.85,10.07,24.97,6.71,6.11,15.76,9.17,27.14,9.17,6.71,0,12.56-1.39,17.54-4.19,4.98-2.79,9.31-6.52,12.99-11.18.35,2.26.92,4.52,1.7,6.79.23.68.47,1.35.7,2.01.93,2.61,3.38,4.36,6.15,4.36h33.86v-12.9c0-3.25-2.39-5.99-5.6-6.45Zm-37.76-11.93c-2.12,3.18-5.12,5.74-9.01,7.69-3.89,1.94-7.99,2.91-12.3,2.91-4.59,0-8.18-1.11-10.76-3.34-2.58-2.23-3.87-5.39-3.87-9.49,0-4.38,1.75-8.13,5.25-11.24,3.5-3.11,8.64-4.66,15.42-4.66h15.27v18.13Z" />
      <path d="m640.79,77.66h-3.96c-3.21,0-5.95,2.35-6.44,5.52l-1.37,8.9c-3.46-5.3-7.67-9.38-12.62-12.24-4.95-2.86-10.85-4.29-17.7-4.29-9.19,0-17.07,2.53-23.64,7.58-6.57,5.06-11.63,12.11-15.16,21.15-3.53,9.05-5.3,19.61-5.3,31.7v2.23c0,11.24,1.77,21.1,5.3,29.58,3.53,8.48,8.66,15.07,15.37,19.77,6.71,4.7,14.74,7.05,24.06,7.05,5.94,0,11.2-1.13,15.8-3.39,4.59-2.26,8.59-5.55,11.98-9.86v7.53c0,8.2-1.93,14.59-5.78,19.19-3.85,4.59-9.91,6.89-18.18,6.89-4.81,0-9.98-.62-15.53-1.85-3.19-.71-6.29-1.57-9.28-2.58-3.55-1.19-7.35.94-8.28,4.56l-3.9,15.03c5.51,2.76,11.87,4.88,19.08,6.36,7.21,1.49,13.71,2.23,19.51,2.23,11.1,0,20.64-2.05,28.62-6.15,7.98-4.1,14.12-9.86,18.39-17.28,4.27-7.42,6.41-16.19,6.41-26.29v-89.78l8.31-1.77c3.01-.64,5.15-3.3,5.15-6.37v-13.39h-30.85Zm-13.68,82.69c-1.98,3.32-4.56,5.85-7.74,7.58-3.18,1.73-6.96,2.6-11.34,2.6-8.06,0-13.82-2.88-17.28-8.64-3.46-5.76-5.19-13.66-5.19-23.69v-2.23c0-7.28.78-13.62,2.33-19.03,1.55-5.4,3.99-9.65,7.31-12.72,3.32-3.07,7.63-4.61,12.93-4.61,4.24,0,7.97.95,11.18,2.86,3.21,1.91,5.81,4.6,7.79,8.06v49.82Z" />
      <path d="m749.99,81.69c-7.07-4.1-15.8-6.15-26.18-6.15-10.53-.07-19.67,2.42-27.4,7.48-7.74,5.05-13.73,11.98-17.97,20.78-4.24,8.8-6.36,18.89-6.36,30.26v4.24c0,10.89,2.24,20.57,6.73,29.05,4.49,8.48,10.83,15.14,19.03,19.98,8.2,4.84,17.77,7.26,28.73,7.26,9.26,0,17.47-1.26,24.65-3.76,7.17-2.51,13.13-5.67,17.86-9.49l-5.54-12.63c-1.45-3.31-5.35-4.88-8.62-3.33-2.82,1.34-5.68,2.43-8.58,3.29-4.91,1.45-10.2,2.17-15.85,2.17s-10.07-1.11-13.89-3.34c-3.82-2.23-6.77-5.3-8.85-9.23-2.09-3.92-3.34-8.39-3.76-13.41l.21-.64h60.91c3.6,0,6.51-2.92,6.51-6.51v-10.34c0-10.95-1.84-20.28-5.51-27.99-3.68-7.7-9.05-13.6-16.11-17.71Zm-7.31,42.09h-38.27l-.32-.53c.56-4.73,1.64-8.97,3.23-12.72,1.59-3.75,3.8-6.69,6.63-8.85,2.83-2.16,6.29-3.23,10.39-3.23,4.31,0,7.83.92,10.55,2.75,2.72,1.84,4.7,4.44,5.94,7.79,1.24,3.36,1.86,7.37,1.86,12.03v2.76Z" />
      <path d="m900.89,144.13c2.4-8.2,3.6-17.17,3.6-26.92h-16.91c-3.32,0-6.15,2.49-6.47,5.79-.32,3.42-.94,6.72-1.85,9.9-1.41,4.95-3.39,9.54-5.94,13.78l-29.26-34.14,9.86-7c6.01-4.31,10.74-9.33,14.21-15.06,3.46-5.72,5.19-12.05,5.19-18.97s-1.61-12.65-4.82-18.02c-3.22-5.37-7.67-9.67-13.36-12.88-5.69-3.22-12.28-4.83-19.77-4.83-8.48,0-15.88,1.66-22.21,4.98-6.33,3.32-11.22,7.99-14.68,13.99-3.46,6.01-5.19,13.11-5.19,21.31,0,5.58,1.18,11.06,3.55,16.43,2.37,5.37,5.95,11.31,10.76,17.81-.35.21-.73.46-1.11.74-.39.28-.76.57-1.11.85-8.62,6.15-15.05,12.18-19.29,18.07-4.24,5.9-6.36,12.85-6.36,20.83,0,8.69,2.07,16.33,6.2,22.9,4.13,6.57,9.98,11.7,17.55,15.37,7.56,3.68,16.47,5.51,26.71,5.51,7.28,0,14.1-.97,20.46-2.91,6.36-1.94,12.54-4.86,18.55-8.74l8.16,9.43h34.35l.21-.53-22.05-25.87c4.95-6.36,8.62-13.64,11.03-21.84Zm-76.22-79.88c2.47-3.07,6.01-4.61,10.6-4.61,4.17,0,7.54,1.38,10.12,4.13,2.58,2.76,3.87,5.9,3.87,9.43,0,3.18-.69,6.12-2.07,8.8-1.38,2.69-4.05,5.3-8,7.85l-9.12,5.94c-3.04-3.89-5.32-7.44-6.84-10.65-1.52-3.22-2.28-6.35-2.28-9.38,0-4.59,1.24-8.43,3.71-11.5Zm18.34,105.16c-3.75.95-7.49,1.43-11.24,1.43-6.29,0-11.38-2.03-15.27-6.09-3.89-4.06-5.83-9.24-5.83-15.53,0-3.25.65-6.48,1.96-9.7,1.31-3.21,3.76-6.52,7.37-9.91l2.01-1.38,31.8,36.89c-3.46,1.91-7.07,3.34-10.81,4.29Z" />
      <path d="m985.02,75.54c-11.95,0-21.63,4.45-29.05,13.36l-.5-5.34c-.32-3.35-3.12-5.9-6.48-5.9h-22.48v158.8h30.64v-54.07c7.14,8.06,16.5,12.08,28.09,12.08,13.64,0,24.47-5.32,32.49-15.96,8.02-10.64,12.03-24.79,12.03-42.45v-1.59c0-18.38-3.92-32.79-11.77-43.25-7.84-10.46-18.84-15.69-32.97-15.69Zm-7.84,94.25c-9.61,0-16.29-3.78-20.04-11.34v-46.96c3.6-7.35,10.21-11.03,19.82-11.03,7.21,0,12.7,2.9,16.48,8.69,3.78,5.79,5.67,14.03,5.67,24.7,0,23.96-7.31,35.94-21.94,35.94Z" />
      <path d="m1071.75,91.34l-.47-7.57c-.21-3.43-3.06-6.11-6.5-6.11h-22.82v114.71h30.64v-75.06c3.6-7.84,11.2-11.77,22.79-11.77,1.29,0,2.66.04,4.11.14,3.75.24,6.96-2.64,7.01-6.4l.32-22.36c-2.97-.92-6.15-1.38-9.54-1.38-10.89,0-19.4,5.27-25.55,15.8Z" />
      <rect x="1118.42" y="26.99" width="32.02" height="26.4" />
      <rect x="1119.05" y="77.66" width="30.74" height="114.71" />
      <path d="m1230.99,75.54c-13.78,0-24.77,5.13-32.97,15.37l-.52-7.21c-.25-3.4-3.08-6.04-6.5-6.04h-22.77v114.71h30.64v-80.89c4.31-7.35,10.78-11.03,19.4-11.03,6.57,0,11.27,1.47,14.1,4.4,2.83,2.93,4.24,7.65,4.24,14.15v73.36h30.64v-74.1c-.14-14.21-3.18-24.88-9.12-32.02-5.94-7.14-14.98-10.71-27.14-10.71Z" />
      <path d="m1333.99,169.26c-4.59,0-7.7-.92-9.33-2.75-1.63-1.84-2.44-4.88-2.44-9.12v-57.25h19.61v-22.47h-19.61v-28.2h-24.12c-3.6,0-6.51,2.92-6.51,6.51v21.69h-16.75v15.96c0,3.6,2.92,6.51,6.51,6.51h10.24v61.8c.42,21.7,11.38,32.55,32.86,32.55,6.36,0,12.54-.92,18.55-2.75v-23.22c-2.62.5-5.62.74-9.01.74Z" />
      <g>
        <path d="m116.68,335.21c0-8.78,2.08-15.84,6.24-21.19s9.61-8.01,16.35-8.01,12.01,2.29,15.92,6.88v-29.84h9.79v81.26h-8.99l-.48-6.14c-3.91,4.8-9.36,7.19-16.35,7.19s-12.03-2.72-16.21-8.15c-4.18-5.43-6.27-12.52-6.27-21.27v-.74Zm9.79,1.11c0,6.49,1.34,11.57,4.02,15.24,2.68,3.67,6.38,5.5,11.11,5.5,6.21,0,10.74-2.79,13.6-8.36v-26.29c-2.93-5.4-7.43-8.09-13.49-8.09-4.79,0-8.54,1.85-11.21,5.55s-4.02,9.19-4.02,16.45Z" />
        <path d="m180.21,291.89c0-1.59.48-2.93,1.45-4.02.97-1.09,2.41-1.64,4.31-1.64s3.35.55,4.34,1.64c.99,1.09,1.48,2.43,1.48,4.02s-.5,2.91-1.48,3.97-2.43,1.59-4.34,1.59-3.34-.53-4.31-1.59-1.45-2.38-1.45-3.97Zm10.58,72.42h-9.79v-57.24h9.79v57.24Z" />
        <path d="m234.12,315.85c-1.48-.25-3.09-.37-4.81-.37-6.42,0-10.77,2.73-13.07,8.2v40.63h-9.79v-57.24h9.52l.16,6.61c3.21-5.11,7.76-7.67,13.65-7.67,1.9,0,3.35.25,4.34.74v9.1Z" />
        <path d="m265.86,365.37c-7.76,0-14.07-2.55-18.94-7.64-4.87-5.1-7.3-11.91-7.3-20.45v-1.8c0-5.68,1.08-10.75,3.25-15.21,2.17-4.46,5.2-7.95,9.1-10.47,3.89-2.52,8.12-3.78,12.67-3.78,7.44,0,13.23,2.45,17.35,7.35,4.13,4.9,6.19,11.92,6.19,21.05v4.07h-38.78c.14,5.64,1.79,10.2,4.95,13.67,3.15,3.47,7.17,5.21,12.03,5.21,3.46,0,6.38-.71,8.78-2.12,2.4-1.41,4.5-3.28,6.3-5.61l5.98,4.66c-4.79,7.37-11.99,11.06-21.58,11.06Zm-1.22-51.31c-3.95,0-7.27,1.44-9.95,4.31-2.68,2.87-4.34,6.9-4.97,12.09h28.67v-.74c-.28-4.97-1.62-8.83-4.02-11.56-2.4-2.73-5.64-4.1-9.73-4.1Z" />
        <path d="m322.46,357.38c3.49,0,6.54-1.06,9.15-3.17s4.05-4.76,4.34-7.94h9.26c-.18,3.28-1.3,6.4-3.39,9.36s-4.86,5.33-8.33,7.09-7.15,2.65-11.03,2.65c-7.8,0-13.99-2.6-18.59-7.8-4.6-5.2-6.9-12.32-6.9-21.35v-1.64c0-5.57,1.02-10.53,3.07-14.87s4.98-7.71,8.81-10.1c3.83-2.4,8.35-3.6,13.57-3.6,6.42,0,11.75,1.92,16,5.77,4.25,3.84,6.51,8.83,6.8,14.97h-9.26c-.28-3.7-1.69-6.74-4.21-9.13s-5.63-3.57-9.34-3.57c-4.97,0-8.83,1.79-11.56,5.37-2.73,3.58-4.1,8.76-4.1,15.53v1.85c0,6.6,1.36,11.67,4.07,15.24,2.72,3.56,6.59,5.34,11.64,5.34Z" />
        <path d="m369.49,293.21v13.86h10.69v7.56h-10.69v35.54c0,2.3.48,4.02,1.43,5.16s2.57,1.72,4.87,1.72c1.13,0,2.68-.21,4.66-.63v7.89c-2.57.7-5.08,1.05-7.51,1.05-4.37,0-7.67-1.32-9.89-3.97-2.22-2.65-3.33-6.4-3.33-11.27v-35.5h-10.42v-7.56h10.42v-13.86h9.79Z" />
        <path d="m387.95,335.16c0-5.61,1.1-10.65,3.31-15.13,2.2-4.48,5.27-7.94,9.2-10.37s8.42-3.65,13.46-3.65c7.8,0,14.1,2.7,18.91,8.09s7.22,12.57,7.22,21.53v.69c0,5.57-1.06,10.57-3.2,15-2.14,4.43-5.18,7.87-9.15,10.34-3.97,2.47-8.52,3.7-13.67,3.7-7.76,0-14.05-2.7-18.86-8.09s-7.22-12.54-7.22-21.42v-.69Zm9.84,1.16c0,6.35,1.47,11.44,4.42,15.29,2.94,3.84,6.88,5.77,11.82,5.77s8.92-1.95,11.85-5.85c2.93-3.9,4.39-9.35,4.39-16.37,0-6.28-1.49-11.36-4.47-15.26-2.98-3.9-6.94-5.85-11.88-5.85s-8.73,1.92-11.69,5.77c-2.96,3.84-4.44,9.35-4.44,16.5Z" />
        <path d="m480,315.85c-1.48-.25-3.09-.37-4.81-.37-6.42,0-10.77,2.73-13.07,8.2v40.63h-9.79v-57.24h9.52l.16,6.61c3.21-5.11,7.76-7.67,13.65-7.67,1.9,0,3.35.25,4.34.74v9.1Z" />
        <path d="m508.67,349.97l13.33-42.9h10.47l-23.01,66.07c-3.56,9.52-9.22,14.28-16.98,14.28l-1.85-.16-3.65-.69v-7.94l2.65.21c3.31,0,5.9-.67,7.75-2.01,1.85-1.34,3.38-3.79,4.58-7.35l2.17-5.82-20.42-56.6h10.69l14.28,42.9Z" />
      </g>
    </svg>
  );
}
