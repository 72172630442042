import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCalendarSmall } from 'components';

const useStyles = makeStyles({
  btnIcon: {
    '&:hover': {
      background: 'transparent',
    },
  },
  datePicker: {
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
    '& .MuiPickersCalendarHeader-label': {
      fontSize: '1.6rem',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: '#3851ff !important',
    },
    '& .MuiPickersSlideTransition-root': {
      minHeight: '235px',
    },
  },
});
export const DatePicker = (props) => {
  const { value, dateName, onChange, onBlur, helperText, error, disabled = false, label } = props;
  const classes = useStyles();

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box display="flex" width="100%">
          <Box flex={2}>
            <MuiDatePicker
              className={classes.datePicker}
              disabled={disabled}
              format="dd/MM/yyyy"
              value={value}
              slots={{
                openPickerIcon: IconCalendarSmall,
              }}
              slotProps={{
                textField: {
                  helperText: helperText,
                  label: label,
                  error: error,
                  onBlur: onBlur,
                  id: dateName,
                },
                inputAdornment: {
                  position: 'start',
                },
                popper: {
                  className: classes.datePicker,
                },
              }}
              onChange={onChange}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  );
};
