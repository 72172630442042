import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { sendEmailAPI } from 'api';

const SuppliersPromotionPopup = ({ profile }) => {
  const { t } = useTranslation();

  const handleSendEmail = async () => {
    try {
      const res = await sendEmailAPI({
        to: profile.email,
        subject: `${profile.company.name} - signage&print`,
        body: `
            <p>Hey ${profile.firstName} 👋</p>
            <p>Thanks for your interest in working with us.</p>
            <p>
              We've copied Dan on this email. He leads our partnerships and will shortly send over
              some additional info.
            </p>
            <p>
             If you have any questions in the meantime, just reply to this email and we'll be happy to help!
            </p>
           <p>
             All the best <br> Team signage&print
            </p>`,
      });

      toast.success(res.message);
    } catch (e) {
      return e;
    }
  };

  return (
    <>
      <Typography variant="subtitle1">{t('directory.thanksForInteresting')}</Typography>
      <br />
      <Typography variant="subtitle1" style={{ whiteSpace: 'break-spaces' }}>
        {t('directory.supplierForbidden')}
      </Typography>
      <Box textAlign="right">
        <Button
          onClick={handleSendEmail}
          color="primary"
          variant="contained"
          style={{ width: '180px' }}
        >
          {t('action.sendInfo')}
        </Button>
      </Box>
    </>
  );
};

export { SuppliersPromotionPopup };
