import { commonAPI } from '../utils';
import axios from 'axios';

const UsersEndpoints = {
  users: '/companies/users',
  inviteUser: '/invitations/send',
  resendInvite: (id: number) => `/invitations/resend/${id}`,
  inviteDetails: (token: string) => `/invitations/users/${token}`,
  updateUser: (id: number) => `/companies/users/${id}`,
  confirmUser: (token: string) => `/invitations/confirm/${token}`,
};

export const getUsersAPI = () => commonAPI(axios.get(UsersEndpoints.users));

export const inviteUserAPI = (values) => commonAPI(axios.post(UsersEndpoints.inviteUser, values));

export const resendInviteAPI = (id: number) =>
  commonAPI(axios.post(UsersEndpoints.resendInvite(id)));

export const updateUser = (id: number, values) =>
  commonAPI(axios.put(UsersEndpoints.updateUser(id), values));

export const getInvitedUserAPI = (token: string) =>
  commonAPI(axios.get(UsersEndpoints.inviteDetails(token)));

export const confirmUserAPI = (token: string, values) =>
  commonAPI(axios.post(UsersEndpoints.confirmUser(token), values));
