export const buildPasswordRegex = (pwdLength: number): RegExp =>
  new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{${pwdLength},}$`);

export const commonAPI = async (request) => {
  try {
    const response = await request;
    const { data } = response;
    return data;
  } catch (e: any) {
    throw new Error(e.response?.data?.message ?? e);
  }
};

export const calculateDistance = (from, to) => {
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const distance = google.maps.geometry.spherical.computeDistanceBetween(from, to);

  return Math.round(distance / 1000);
};
