import { applyMiddleware, createStore, Store, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../redux/reducers';
import thunk from 'redux-thunk';

const middlewares = applyMiddleware(compose(thunk));

const middlewaresByEnv =
  process.env.NODE_ENV !== 'production' ? composeWithDevTools(middlewares) : middlewares;

const store: Store = createStore(rootReducer, middlewaresByEnv);

export { store };
