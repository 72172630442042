import React from 'react';

export function IconDownload() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
      <path
        d="M26.5 22C26.5 20.8954 25.6046 20 24.5 20C23.3954 20 22.5 20.8954 22.5 22V31.1716L17.9142 26.5858C17.1332 25.8047 15.8668 25.8047 15.0858 26.5858C14.3047 27.3668 14.3047 28.6332 15.0858 29.4142L23.0858 37.4142C23.8668 38.1953 25.1332 38.1953 25.9142 37.4142L33.9142 29.4142C34.6953 28.6332 34.6953 27.3668 33.9142 26.5858C33.1332 25.8047 31.8668 25.8047 31.0858 26.5858L26.5 31.1716V22Z"
        fill="#6B7EFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 2C7.39543 2 6.5 2.89543 6.5 4V44C6.5 45.1046 7.39543 46 8.5 46H40.5C41.6046 46 42.5 45.1046 42.5 44V16C42.5 15.4696 42.2893 14.9609 41.9142 14.5858L29.9142 2.58579C29.5391 2.21071 29.0304 2 28.5 2H8.5ZM10.5 42V6H26.5V16C26.5 17.1046 27.3954 18 28.5 18H38.5V42H10.5ZM35.6716 14L30.5 8.82843V14H35.6716Z"
        fill="#6B7EFF"
      />
    </svg>
  );
}
