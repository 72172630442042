import axios from 'axios';
import { ICompany } from 'interfaces';
import { commonAPI } from 'utils';

const CompaniesEndpoints = {
  company: (id: number | string) => `companies/${id}`,
  companies: '/companies',
  companyOptions: '/company-options',
  uploadLogo: (id: number | string) => `/companies/${id}/logo-upload`,
  sendContactEmailAPI: (id: number | string) => `/companies/send-email/${id}`,
  sendEmail: '/emails/send',
};

export const getCompaniesAPI = () => commonAPI(axios.get<ICompany>(CompaniesEndpoints.companies));

export const getCompanyAPI = (id: number | string) =>
  commonAPI(axios.get<ICompany>(CompaniesEndpoints.company(id)));

export const createCompanyAPI = (values) =>
  commonAPI(axios.post<ICompany>(CompaniesEndpoints.companies, values));

export const updateCompanyAPI = (id: number | string, values) =>
  commonAPI(axios.put<ICompany>(CompaniesEndpoints.company(id), values));

export const uploadLogoAPI = (id: number | string, values) =>
  commonAPI(axios.post(CompaniesEndpoints.uploadLogo(id), values));

export const getCompanyOptionsAPI = () =>
  commonAPI(axios.get<ICompany>(CompaniesEndpoints.companyOptions));

export const sendContactEmailAPI = (id: number | string, values) =>
  commonAPI(axios.post(CompaniesEndpoints.sendContactEmailAPI(id), values));

export const sendEmailAPI = (values) => commonAPI(axios.post(CompaniesEndpoints.sendEmail, values));
