import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  CompanyDetailsPopup,
  CompanyLogo,
  CreateCompanyProfilePopup,
  Dialog,
  GoogleAutocomplete,
  Header,
  IconLocation,
  IconSearch,
  Loading,
  PricingModalPopup,
  SuppliersPromotionPopup,
  VerifyAccountPopup,
} from 'components';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';

import { Filters, getFilterOptions } from './Filters';
import { makeStyles } from '@mui/styles';
import { IAddress, ICompanyState, IModalsState, IProfile } from 'interfaces';
import {
  clearCompanyState,
  getCompaniesList,
  getCompanyOptions,
  setCompaniesList,
  showPricingPopup,
  showUsersPopup,
} from 'redux/actions';
import { RootState, useTypedDispatch } from 'redux/reducers';
import { useFiltering, useIsMobile, useProfileInfo } from 'hooks';
import {
  calculateDistance,
  INSTALLATIONS_FREELANCER_SERVICE_ID,
  INSTALLATIONS_SERVICE_ID,
  INSTALLER_DIRECTORY,
  KILOMETERS,
  MILES,
  PRINT_SERVICE_ID,
} from 'utils';

import installerDirectory from './images/InstallerDirectory.jpg';
import fullDirectory from './images/fullDirectory.jpg';

const useStyles = makeStyles({
  locationInput: {
    background: '#fff',
    borderRadius: '8px',

    '& .MuiAutocomplete-inputRoot': {
      padding: '0 10px !important',

      '&:hover': {
        boxShadow: '20px 10px 20px rgba(0, 0, 0, 0.08)',

        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #E6E8EC',
        },
      },

      '& > svg': {
        marginLeft: '10px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E6E8EC !important',
      },
    },
    '& input': {
      padding: '20px 16px !important',
    },
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
    },
  },
  accordion: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',

    '&.MuiChip-root': {
      marginRight: '5px',
    },
  },
  dialogHeader: {
    '& .MuiDialogTitle-root span': {
      fontSize: '2.8rem',
    },
  },
  noResults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  searchIcon: {
    width: '60px',
    height: '60px',
    backgroundColor: '#E5ECFF',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  companyCard: {
    border: '1px solid #E6E8EC',
    borderRadius: '8px',
    width: 'calc(100% / 2 - 20px)',
    height: '125px',
    padding: '24px',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.2s ease-out',

    '&:hover': {
      background: 'rgba(0, 70, 255, 0.1)',
    },
  },
  companyLogo: {
    width: '75px',
    height: '75px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  popup: {
    '& h2': {
      padding: '0',
    },
  },
});

export const Directory = ({ type }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const isMobile = useIsMobile();
  const routeLocation = useLocation();
  const history = useHistory();
  const [location, setLocation] = useState<null | IAddress>(null);
  const [distanceUnit, setDistanceUnit] = useState<string>(MILES);
  const [isDetailsOpened, setIsDetailsOpened] = useState<boolean>(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isCompaniesLoading, setIsCompaniesLoading] = useState<boolean>(false);
  const [showSuccessPayment, setShowSuccessPayment] = useState(
    queryString.parse(routeLocation.search)?.paymentSuccess === 'true'
  );

  const token = useSelector<RootState>((state) => state.auth.token) as string;
  const profile = useSelector<RootState>((state) => state.auth.profile) as IProfile;
  const { tradeTypes, certifications, categories, services, companiesList, loading } =
    useSelector<RootState>((state) => state.company) as ICompanyState;
  const { pricingPopup } = useSelector<RootState>((state) => state.modals) as IModalsState;

  const { isSupplierCompany } = useProfileInfo();

  const fetchCompanyOptions = async () => {
    await dispatch(getCompanyOptions());
  };

  const fetchCompanies = async () => {
    setIsCompaniesLoading(true);
    await dispatch(getCompaniesList());
    setIsCompaniesLoading(false);
  };

  useEffect(() => {
    fetchCompanyOptions();

    return () => {
      dispatch(clearCompanyState());
    };
  }, []);

  const companies = useMemo(() => {
    return type === INSTALLER_DIRECTORY
      ? companiesList.filter((company) => {
          const isInstallation = company.services?.some(
            (el) =>
              el.id === INSTALLATIONS_SERVICE_ID || el.id === INSTALLATIONS_FREELANCER_SERVICE_ID
          );

          const isPrint = company.services?.some((el) => el.id === PRINT_SERVICE_ID);

          return isInstallation && !isPrint;
        })
      : companiesList;
  }, [companiesList]);

  const { filteredData, setFilter, setManyFilters, filtering } = useFiltering(
    companies,
    getFilterOptions()
  );

  const sortedData = useMemo(() => {
    return filteredData
      .map((company) => {
        return {
          ...company,
          address: {
            ...company.address,
            distance: calculateDistance(
              {
                lat: parseFloat(location?.lat ?? ''),
                lng: parseFloat(location?.lng ?? ''),
              },
              {
                lat: parseFloat(company.address?.lat),
                lng: parseFloat(company.address?.lng),
              }
            ),
          },
        };
      })
      .sort((a, b) => a.address?.distance - b.address?.distance);
  }, [filteredData, location, distanceUnit]);

  const onSubmitSearch = (values) => {
    setLocation(values.address);
    values.locationInput.length === 0 ? dispatch(setCompaniesList([])) : fetchCompanies();
  };

  return (
    <>
      <Header />

      {loading || tradeTypes.length === 0 ? (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
          <Loading fullHeight size={64} />
        </Box>
      ) : (
        <>
          <Box>
            <img
              src={type === INSTALLER_DIRECTORY ? installerDirectory : fullDirectory}
              alt="hero"
              width="100%"
            />
          </Box>

          <Box maxWidth="729px" margin="0 auto" marginTop="-43px" padding="0 10px">
            <Formik
              enableReinitialize={true}
              initialValues={{ locationInput: '', address: null }}
              onSubmit={onSubmitSearch}
            >
              <Form>
                <Field
                  component={GoogleAutocomplete}
                  name="locationInput"
                  placeholder={t('placeholders.address')}
                  rootClasses={classes.locationInput}
                  type="text"
                  onChange={(e, value, reason) => {
                    if (reason === 'selectOption') {
                      onSubmitSearch(value);
                    }
                  }}
                  InputProps={{
                    startAdornment: <IconLocation />,
                  }}
                />
              </Form>
            </Formik>
          </Box>

          {isMobile ? (
            <Dialog
              className={classes.dialogHeader}
              heading={t('directory.switchToComputer')}
              hideCloseButton
              isOpen={isMobile}
              maxWidth="sm"
            >
              <Typography variant="subtitle1">{t('directory.noMobile')}</Typography>
            </Dialog>
          ) : (
            <>
              <Grid container px="5%" pb={2} mt={3}>
                <Grid item xs={3}>
                  <Filters
                    certifications={certifications}
                    categories={categories}
                    distanceUnit={distanceUnit}
                    filtering={filtering}
                    services={services}
                    setDistanceUnit={setDistanceUnit}
                    setFilter={setFilter}
                    setManyFilters={setManyFilters}
                    // tradeTypes={tradeTypes}
                  />
                </Grid>
                <Grid
                  display="flex"
                  flexWrap="wrap"
                  gap="20px"
                  height="fit-content"
                  item
                  pl={6}
                  xs={9}
                >
                  {filteredData.length === 0 ? (
                    <Box className={classes.noResults} mt={7}>
                      {isCompaniesLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <Box className={classes.searchIcon}>
                            <IconSearch />
                          </Box>
                          <Typography
                            variant="subtitle1"
                            style={{ whiteSpace: 'break-spaces', textAlign: 'center' }}
                          >
                            {companiesList.length === 0
                              ? t('directory.startByEntering')
                              : t('directory.noMatches')}
                          </Typography>
                        </>
                      )}
                    </Box>
                  ) : (
                    sortedData?.map((company) => {
                      const distance = Math.round(
                        distanceUnit === KILOMETERS
                          ? company.address?.distance
                          : company.address?.distance * 0.621371
                      );

                      return (
                        <Box
                          className={classes.companyCard}
                          key={company.id}
                          onClick={() => {
                            setCompanyDetails(company);
                            setIsDetailsOpened(true);
                          }}
                        >
                          <Box className={classes.companyLogo}>
                            <CompanyLogo src={company.thumbLogo} />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            height="100%"
                            justifyContent="space-between"
                          >
                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                              {company.name}
                            </Typography>
                            <Typography variant="body2" style={{ fontWeight: 500 }}>
                              {company.address?.city || 'N/A'}
                            </Typography>
                            <Typography variant="body1" style={{ fontSize: '1.4rem' }}>
                              {distance < 1 ? 'less than 1' : distance}
                              {` ${distanceUnit} away`}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })
                  )}
                </Grid>
              </Grid>

              <Dialog
                customWidth="500px"
                heading={t('auth.verifyAccount')}
                hideCloseButton
                isOpen={token?.length > 0 && !profile?.isVerified}
              >
                <VerifyAccountPopup email={profile?.email} />
              </Dialog>

              <Dialog
                heading={t('company.createCompanyProfile')}
                hideCloseButton
                isOpen={
                  !!token &&
                  profile?.isVerified &&
                  (!profile.company?.services?.length || !profile.company)
                }
                maxWidth="lg"
              >
                <CreateCompanyProfilePopup profile={profile} />
              </Dialog>

              <Dialog
                className={classes.popup}
                isOpen={isDetailsOpened}
                maxWidth="md"
                onClose={() => {
                  setIsDetailsOpened(false);
                  setTimeout(() => setCompanyDetails(null), 0);
                }}
              >
                <CompanyDetailsPopup company={companyDetails} />
              </Dialog>

              <Dialog
                heading={t('directory.letsWorkTogether')}
                hideCloseButton
                isOpen={isSupplierCompany}
                maxWidth="sm"
              >
                <SuppliersPromotionPopup profile={profile} />
              </Dialog>

              <Dialog
                isOpen={!isMobile && pricingPopup}
                className={classes.popup}
                maxWidth="md"
                hideCloseButton
                onClose={() => dispatch(showPricingPopup(false))}
              >
                <PricingModalPopup />
              </Dialog>

              <Dialog
                isOpen={showSuccessPayment}
                heading={t('successModal.title')}
                maxWidth="xs"
                onClose={() => {
                  history.push(routeLocation.pathname);
                  setShowSuccessPayment(false);
                }}
              >
                <>
                  <Typography variant="subtitle1">{t('successModal.description')}</Typography>
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ width: '190px' }}
                      onClick={() => {
                        history.push(routeLocation.pathname);
                        setShowSuccessPayment(false);
                        dispatch(showUsersPopup(true));
                      }}
                    >
                      {t('action.inviteColleagues')}
                    </Button>
                  </Box>
                </>
              </Dialog>
            </>
          )}
        </>
      )}
    </>
  );
};
