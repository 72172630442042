import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';

import ReactPhoneInput from 'react-phone-input-material-ui';
import { Box, FormHelperText, TextField } from '@mui/material';

import 'react-phone-input-material-ui/lib/style.css';

const useStyles = makeStyles(() => ({
  containerClass: {
    '&.react-tel-input': {
      '& .MuiInputBase-input': {
        padding: '0 0 0 52px',
      },
      '& .MuiFormControl-root': {
        height: 'auto',
        margin: '0',
      },

      '& .MuiInput-root': {
        paddingBottom: '7px',
      },

      '& .flag-dropdown': {
        height: '15px',
        zIndex: 2,
        top: '16px',
        left: '16px',
      },

      '& .selected-flag': {
        transform: 'scale(1.2)',
      },

      '& .country-list': {
        top: '-86px',
        left: '48px',
        boxShadow: '1px 2px 10px rgba(0,0,0,0.2)',
        fontSize: '1.4rem',
      },

      '& .country': {
        outline: 'none',
      },
    },
  },
  error: {
    position: 'absolute',
    bottom: '-20px',
  },
}));

export const PhoneInput = (props) => {
  const {
    form: { setFieldValue, errors, touched, values },
    field: { name, onBlur },
    disabled,
  } = props;

  const classes = useStyles(props);
  const onChange = useCallback(
    (value, details, event, formattedNumber) => {
      setFieldValue(name, formattedNumber);
    },
    [setFieldValue, name]
  );

  return (
    <Box>
      <ReactPhoneInput
        containerClass={`${classes.containerClass}`}
        inputProps={{
          name,
          onBlur,
          label: '',
          error: !!errors[name] && !!touched[name],
        }}
        enableLongNumbers
        excludeCountries={['ru']}
        country="gb"
        placeholder="Phone number"
        value={values[name]}
        onChange={onChange}
        component={TextField}
        disabled={disabled}
      />
      {!!errors[name] && !!touched[name] && (
        <FormHelperText className={classes.error} error>
          {errors[name]}
        </FormHelperText>
      )}
    </Box>
  );
};
