import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import {
  GoogleAutocomplete,
  IconDownload,
  IconDropzoneDownload,
  Loading,
  MuiAutocomplete,
  PhoneInput,
  Tooltip,
} from 'components';

import { uploadLogoAPI } from 'api';
import { RootState, useTypedDispatch } from 'redux/reducers';
import { createCompany, updateCompany } from 'redux/actions';
import { useSelector } from 'react-redux';
import { ICompanyState, IOption } from 'interfaces';
import {
  INSTALLATIONS_FREELANCER_SERVICE_ID,
  INSTALLATIONS_SERVICE_ID,
  PRINT_SERVICE_ID,
  SIGNAGE_SERVICE_ID,
  SUPPLIER_SERVICE_ID,
  testPhoneNumber,
} from 'utils';

const useStyles = makeStyles<any>(() => ({
  uploadFileLabel: {
    color: '#667085',
    fontSize: '1.6rem',
    fontWeight: 500,
    marginTop: '12px',
    textAlign: 'center',
  },
  uploadFileLabelError: {
    fontWeight: 'bold',
    color: '#FF7077',
    fontSize: '1.6rem',
    marginBottom: 6,
  },
  uploadFileHint: {
    color: '#667085',
    fontSize: '1.6rem',
    textAlign: 'center',
  },
  error: {
    fontSize: '1.6rem',
    color: '#667085',
    marginBottom: 4,
  },
  dropzoneOverlay: {
    background: 'rgba(228, 234, 253, 0.95)',
    border: '1px dashed #0046FF',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    outline: 'none',
    zIndex: 2,
    cursor: ({ uploadInProcess }: any) => (uploadInProcess ? 'initial' : 'pointer'),
    pointerEvents: ({ uploadInProcess }: any) => (uploadInProcess ? 'none' : 'unset'),
  },
  dropzoneSmallWrapper: {
    border: '1px dashed #6B7EFF',
    borderRadius: 8,
    padding: 12,
    minHeight: 190,
    height: 190,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    outline: 'none',
    cursor: ({ uploadInProcess }: any) => (uploadInProcess ? 'initial' : 'pointer'),
    pointerEvents: ({ uploadInProcess }: any) => (uploadInProcess ? 'none' : 'unset'),
  },
  uploadFileLabelOverlay: {
    fontWeight: 'bold',
    color: '#6B7EFF',
    fontSize: '1.8rem',
    marginTop: 10,
  },
  companyLogo: {
    backgroundColor: 'rgba(89, 89, 89, 0.2)',
    borderRadius: '20px',
    height: '64px',
    objectFit: 'cover',
    width: '64px',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
  },
}));

const CreateCompanyProfilePopup = ({ profile }) => {
  const { t } = useTranslation();
  const [filesInWindow, setFilesInWindow] = useState(false);
  const [uploadInProcess, setUploadInProcess] = useState(false);
  const [dropErrors, setDropErrors] = useState<any>([]);
  const [logoPreview, setLogoPreview] = useState<string>('');
  const [logoFile, setLogoFile] = useState<any>(null);
  const companyState = useSelector<RootState>((state) => state.company) as ICompanyState;

  const classes = useStyles({ uploadInProcess });
  const dispatch = useTypedDispatch();

  const initialValues = {
    name: '',
    website: '',
    locationInput: '',
    address: null,
    phoneNumber: '',
    sectors: [],
    certifications: [],
    tradeType: null,
    services: [],
    installations: [],
    print: [],
    signage: [],
    supplier: [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('formErrors.required')),
    website: Yup.string(),
    locationInput: Yup.string().required(t('formErrors.required')),
    phoneNumber: Yup.string()
      .test('phoneNumber', t('formErrors.phoneNumberInvalid'), (value) => testPhoneNumber(value))
      .required(t('formErrors.required'))
      .nullable(),
    sectors: Yup.array().when('services', {
      is: (services) => services.some((option) => option.id === PRINT_SERVICE_ID),
      then: Yup.array().min(1, t('formErrors.required')),
    }),
    certifications: Yup.array().when('services', {
      is: (services) =>
        services.some(
          (option) =>
            option.id === INSTALLATIONS_SERVICE_ID ||
            option.id === INSTALLATIONS_FREELANCER_SERVICE_ID
        ),
      then: Yup.array().min(1, t('formErrors.required')),
    }),
    tradeType: Yup.object()
      .nullable()
      .when('services', {
        is: (services) => !services.find((option) => option.id === SUPPLIER_SERVICE_ID),
        then: Yup.object().nullable().required(t('formErrors.required')),
      }),
    services: Yup.array().min(1, t('formErrors.required')),
    installations: Yup.array().when('services', {
      is: (services) =>
        services.some(
          (option) =>
            option.id === INSTALLATIONS_SERVICE_ID ||
            option.id === INSTALLATIONS_FREELANCER_SERVICE_ID
        ),
      then: Yup.array().min(1, t('formErrors.required')),
    }),
    print: Yup.array().when('services', {
      is: (services) => services.some((option) => option.id === PRINT_SERVICE_ID),
      then: Yup.array().min(1, t('formErrors.required')),
    }),
    signage: Yup.array().when('services', {
      is: (services) => services.some((option) => option.id === SIGNAGE_SERVICE_ID),
      then: Yup.array().min(1, t('formErrors.required')),
    }),
    supplier: Yup.array().when('services', {
      is: (services) => services.some((option) => option.id === SUPPLIER_SERVICE_ID),
      then: Yup.array().min(1, t('formErrors.required')),
    }),
  });

  const handleDrop = async (acceptedFile, rejectedFiles) => {
    setDropErrors([]);

    if (rejectedFiles.length) {
      setDropErrors(rejectedFiles);
      return;
    }

    setUploadInProcess(true);
    const formData = new FormData();
    formData.append('logo', acceptedFile[0]);

    setLogoFile(formData);
    setLogoPreview(URL.createObjectURL(acceptedFile[0]));

    setUploadInProcess(false);
  };

  const handleChangeService = (e, value, reason, details, setFieldValue) => {
    setFieldValue('services', value);

    if (reason === 'clear') {
      setFieldValue('installations', []);
      setFieldValue('certifications', []);
      setFieldValue('print', []);
      setFieldValue('sectors', []);
      setFieldValue('supplier', []);
      setFieldValue('signage', []);
      return;
    }

    switch (details.option?.id) {
      case INSTALLATIONS_SERVICE_ID:
      case INSTALLATIONS_FREELANCER_SERVICE_ID: {
        setFieldValue('installations', []);
        setFieldValue('certifications', []);
        return;
      }
      case PRINT_SERVICE_ID: {
        setFieldValue('print', []);
        setFieldValue('sectors', []);
        return;
      }
      case SUPPLIER_SERVICE_ID: {
        setFieldValue('supplier', []);
        setFieldValue('tradeType', null);
        return;
      }
      case SIGNAGE_SERVICE_ID: {
        setFieldValue('signage', []);
        return;
      }
    }
  };

  useEffect(() => {
    let timer: any;
    document.body.ondragover = () => {
      timer && clearTimeout(timer);
      setFilesInWindow(true);

      timer = setTimeout(() => {
        setFilesInWindow(false);
      }, 100);
    };
  }, []);

  const dropOverlayRenderer = () => (
    <Dropzone
      onDrop={handleDrop}
      accept=".jpeg,.jpg,.png"
      maxFiles={1}
      multiple={false}
      maxSize={2000000}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          style={{ borderWidth: isDragActive ? 2 : 1 }}
          {...getRootProps({
            className: classes.dropzoneOverlay,
          })}
        >
          <input {...getInputProps()} />

          {uploadInProcess ? (
            <Loading fullHeight size={64} />
          ) : (
            <>
              <IconDownload />
              <div className={classes.uploadFileLabelOverlay}>{t('formLabel.dropFilesInArea')}</div>
            </>
          )}
        </div>
      )}
    </Dropzone>
  );

  const onSubmit = async (values) => {
    const { name, website, phoneNumber, tradeType, certifications, address, sectors, services } =
      values;
    const mappedServices = services.map((service) => service.id);
    services.forEach((service) => service.children.forEach((item) => mappedServices.push(item.id)));
    const link = /^(http|https):\/\//.test(values.website) ? website : `https://${website}`;

    try {
      const body = {
        name,
        link: website ? link : '',
        categories: sectors.map((item) => item.id),
        tradeTypeId: tradeType?.id,
        phoneNumber,
        address,
        certifications: certifications.map((item) => item.id),
        services: mappedServices,
      };

      const res = profile.company
        ? await dispatch(updateCompany(profile.company?.id, body))
        : await dispatch(createCompany(body));

      await uploadLogoAPI(res.id, logoFile);
    } catch (e) {
      console.log(e);
    }
  };

  if (companyState.loading) {
    return <Loading size={64} />;
  }

  return (
    <Box>
      <Typography component="p" variant="subtitle2">
        {t('company.completeProfile')}
      </Typography>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { values, touched, errors, setFieldValue, isSubmitting } = props;
          const findService = (arr, id) => arr.services.find((option) => option['id'] === id);

          return (
            <Form noValidate autoComplete="off">
              {(filesInWindow || uploadInProcess) && dropOverlayRenderer()}

              <Box mt="25px" />

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl>
                    <InputLabel style={{ marginBottom: '10px' }} id="servicesLabel">
                      {t('formLabel.uploadLogo')}
                    </InputLabel>
                    <Dropzone
                      onDrop={handleDrop}
                      accept=".jpeg,.jpg,.png"
                      maxFiles={1}
                      multiple={false}
                      maxSize={2000000}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          style={{ background: isDragActive ? '#F5F7FF' : '' }}
                          {...getRootProps({
                            className: classes.dropzoneSmallWrapper,
                          })}
                        >
                          <input {...getInputProps()} />
                          {(!dropErrors.length && (
                            <>
                              {logoPreview ? (
                                <img
                                  className={classes.imagePreview}
                                  src={logoPreview}
                                  alt="logo"
                                />
                              ) : (
                                <IconDropzoneDownload />
                              )}
                              <div className={classes.uploadFileLabel}>
                                <span style={{ color: '#0046FF' }}>
                                  {t(
                                    profile.company?.thumbLogo
                                      ? 'formLabel.clickToUploadAnother'
                                      : 'formLabel.clickToUpload'
                                  )}
                                </span>
                                <span>{t('formLabel.orDragAndDrop')}</span>
                              </div>
                              <div className={classes.uploadFileHint}>
                                {t('formLabel.uploadFileHint')}
                              </div>
                            </>
                          )) || (
                            <>
                              <div className={classes.uploadFileLabelError}>
                                {t('formErrors.dropFileError')}
                              </div>
                              {dropErrors.map((error) => (
                                <div key={error.file.name} className={classes.error}>
                                  {error.file.name}: {error.errors[0].message}
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </FormControl>

                  <Field
                    component={TextField}
                    label={t('formLabel.companyName')}
                    name="name"
                    placeholder={t('placeholders.companyName')}
                    type="text"
                  />

                  <Field
                    component={TextField}
                    label={t('formLabel.website')}
                    name="website"
                    placeholder={t('placeholders.website')}
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{t('formLabel.https')}</InputAdornment>
                      ),
                    }}
                  />

                  <Field
                    component={GoogleAutocomplete}
                    name="locationInput"
                    type="text"
                    label={
                      <Box display="flex" alignItems="center">
                        <Box>{t('formLabel.address') + '*'}</Box>
                        <Tooltip
                          placement="top-start"
                          title={t('formLabel.addressHint') as string}
                        />
                      </Box>
                    }
                    placeholder={t('placeholders.location')}
                  />

                  <FormControl>
                    <InputLabel
                      htmlFor="phoneNumber"
                      error={!!errors['phoneNumber'] && !!touched['phoneNumber']}
                    >
                      {t('formLabel.companyPhoneNumber') + '*'}
                    </InputLabel>
                    <Field component={PhoneInput} name="phoneNumber" type="text" />
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MuiAutocomplete
                        disableCloseOnSelect
                        getOptionDisabled={(option) =>
                          (findService(values, INSTALLATIONS_SERVICE_ID) &&
                            option?.id === INSTALLATIONS_FREELANCER_SERVICE_ID) ||
                          (findService(values, INSTALLATIONS_FREELANCER_SERVICE_ID) &&
                            option?.id === INSTALLATIONS_SERVICE_ID) ||
                          (findService(values, SUPPLIER_SERVICE_ID) &&
                            option?.id !== SUPPLIER_SERVICE_ID) ||
                          (values.services.length > 0 &&
                            option?.id === SUPPLIER_SERVICE_ID &&
                            !findService(values, SUPPLIER_SERVICE_ID))
                        }
                        error={!!touched.services && !!errors.services}
                        getOptionLabel={(option) => option.name}
                        helperText={!!touched.services && errors.services}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        label={t('formLabel.services') + '*'}
                        limitTags={1}
                        multiple
                        name="services"
                        onChange={(e, value, reason, details) =>
                          handleChangeService(e, value, reason, details, setFieldValue)
                        }
                        options={companyState.services.map((option) => ({
                          name: option.name,
                          id: option.id,
                          children: null,
                        }))}
                        placeholder={t('placeholders.multiSelect')}
                        value={values.services}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      {!findService(values, SUPPLIER_SERVICE_ID) && (
                        <MuiAutocomplete
                          error={!!touched.tradeType && !!errors.tradeType}
                          getOptionLabel={(option) => option.name}
                          helperText={!!touched.tradeType && errors.tradeType}
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          label={
                            <Box display="flex" alignItems="center">
                              <Box>{t('formLabel.tradeType') + '*'}</Box>
                              <Tooltip
                                placement="top-start"
                                title={t('formLabel.tradeTypeHint') as string}
                              />
                            </Box>
                          }
                          name="tradeType"
                          onChange={(e, value) => setFieldValue('tradeType', value)}
                          options={companyState.tradeTypes}
                          placeholder={t('placeholders.singleSelect')}
                          value={values.tradeType}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {findService(values, SUPPLIER_SERVICE_ID) && (
                    <MuiAutocomplete
                      disableCloseOnSelect
                      error={!!touched.supplier && !!errors.supplier}
                      getOptionLabel={(option) => option.name}
                      helperText={!!touched.supplier && errors.supplier}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      label={t('formLabel.supplierServices') + '*'}
                      limitTags={3}
                      multiple
                      name="supplierServices"
                      onChange={(e, value) => {
                        const index = values.services.findIndex(
                          (service: IOption) => service.id === SUPPLIER_SERVICE_ID
                        );

                        setFieldValue('supplier', value);
                        setFieldValue(`services[${index}][children]`, value);
                      }}
                      options={findService(companyState, SUPPLIER_SERVICE_ID)?.children}
                      placeholder={t('placeholders.multiSelect')}
                      value={values.supplier}
                    />
                  )}

                  {(findService(values, INSTALLATIONS_SERVICE_ID) ||
                    findService(values, INSTALLATIONS_FREELANCER_SERVICE_ID)) && (
                    <>
                      <MuiAutocomplete
                        disableCloseOnSelect
                        error={!!touched.certifications && !!errors.certifications}
                        getOptionLabel={(option) => option.name}
                        helperText={!!touched.certifications && errors.certifications}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        label={t('formLabel.certificationsAccreditations') + '*'}
                        limitTags={3}
                        multiple
                        name="certifications"
                        onChange={(e, value) => setFieldValue('certifications', value)}
                        options={companyState.certifications}
                        placeholder={t('placeholders.multiSelect')}
                        value={values.certifications}
                      />

                      <MuiAutocomplete
                        disableCloseOnSelect
                        error={!!touched.installations && !!errors.installations}
                        getOptionLabel={(option) => option.name}
                        helperText={!!touched.installations && errors.installations}
                        label={t('formLabel.installationsServices') + '*'}
                        limitTags={3}
                        multiple
                        onChange={(e, value) => {
                          const index = values.services.findIndex(
                            (service: IOption) =>
                              service.id === INSTALLATIONS_SERVICE_ID ||
                              service.id === INSTALLATIONS_FREELANCER_SERVICE_ID
                          );

                          setFieldValue('installations', value);
                          setFieldValue(`services[${index}][children]`, value);
                        }}
                        options={
                          findService(values, INSTALLATIONS_SERVICE_ID)
                            ? findService(companyState, INSTALLATIONS_SERVICE_ID)?.children
                            : findService(companyState, INSTALLATIONS_FREELANCER_SERVICE_ID)
                                ?.children
                        }
                        placeholder={t('placeholders.multiSelect')}
                        value={values.installations}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        name="installationsServices"
                      />
                    </>
                  )}

                  {findService(values, PRINT_SERVICE_ID) && (
                    <>
                      <MuiAutocomplete
                        disableCloseOnSelect
                        error={!!touched.sectors && !!errors.sectors}
                        getOptionLabel={(option) => option.name}
                        helperText={!!touched.sectors && errors.sectors}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        label={t('formLabel.sectors') + '*'}
                        limitTags={3}
                        multiple
                        name="sectors"
                        onChange={(e, value) => setFieldValue('sectors', value)}
                        options={companyState.categories}
                        placeholder={t('placeholders.multiSelect')}
                        value={values.sectors}
                      />

                      <MuiAutocomplete
                        disableCloseOnSelect
                        error={!!touched.print && !!errors.print}
                        getOptionLabel={(option) => option.name}
                        helperText={!!touched.print && errors.print}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        label={t('formLabel.printServices') + '*'}
                        limitTags={3}
                        multiple
                        name="printServices"
                        onChange={(e, value) => {
                          const index = values.services.findIndex(
                            (service: IOption) => service.id === PRINT_SERVICE_ID
                          );

                          setFieldValue('print', value);
                          setFieldValue(`services[${index}][children]`, value);
                        }}
                        options={findService(companyState, PRINT_SERVICE_ID)?.children}
                        placeholder={t('placeholders.multiSelect')}
                        value={values.print}
                      />
                    </>
                  )}

                  {findService(values, SIGNAGE_SERVICE_ID) && (
                    <MuiAutocomplete
                      disableCloseOnSelect
                      error={!!touched.signage && !!errors.signage}
                      getOptionLabel={(option) => option.name}
                      helperText={!!touched.signage && errors.signage}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      label={t('formLabel.signageServices') + '*'}
                      limitTags={3}
                      multiple
                      name="signageServices"
                      onChange={(e, value) => {
                        const index = values.services.findIndex(
                          (service: IOption) => service.id === SIGNAGE_SERVICE_ID
                        );

                        setFieldValue('signage', value);
                        setFieldValue(`services[${index}][children]`, value);
                      }}
                      options={findService(companyState, SIGNAGE_SERVICE_ID)?.children}
                      placeholder={t('placeholders.multiSelect')}
                      value={values.signage}
                    />
                  )}
                </Grid>
              </Grid>

              <Box position="absolute" top="24px" right="24px">
                <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                  {t('action.saveInfo')}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export { CreateCompanyProfilePopup };
