import React, { ReactElement } from 'react';
import { CSSProperties, makeStyles } from '@mui/styles';
import { Box, Tooltip as MuiTooltip } from '@mui/material';

type IProps = {
  title: string | number | ReactElement;
  triggerRenderer?: () => JSX.Element;
  disableMargin?: boolean;
  allowWrap?: boolean;
  classes?: CSSProperties;
  placement?:
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start';
};

const useStyles = makeStyles({
  defaultTriggerStyle: {
    width: 12,
    height: 12,
    transform: 'scale(1.3)',
    borderRadius: '50%',
    background: '#0046FF',
    color: '#fff',
    fontSize: '1rem',
  },
  flexWrap: {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
});

export const Tooltip: React.FC<IProps> = (props) => {
  const { defaultTriggerStyle, flexWrap, ...classes } = useStyles(props);
  const { title, placement = 'top', triggerRenderer, disableMargin, allowWrap } = props;

  const defaultTrigger = () => (
    <Box display="flex" justifyContent="center" alignItems="center" className={defaultTriggerStyle}>
      ?
    </Box>
  );

  const renderTrigger = () =>
    triggerRenderer ? (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={`${allowWrap ? flexWrap : ''}`}
      >
        {triggerRenderer()}
      </Box>
    ) : (
      defaultTrigger()
    );

  return (
    <Box mx={disableMargin ? 0 : 1} className="is-pointer">
      <MuiTooltip
        title={title}
        classes={classes}
        placement={placement}
        enterDelay={0}
        enterTouchDelay={0}
      >
        {renderTrigger()}
      </MuiTooltip>
    </Box>
  );
};
