import React from 'react';

export const IconFingerCrossed = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="150px"
      height="130px"
      style={{ transform: 'scale(1.25)' }}
      viewBox="0 0 1027.000000 980.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M5306 8065 c-80 -21 -188 -79 -252 -138 -122 -111 -185 -258 -186
        -432 0 -129 14 -187 113 -464 178 -498 220 -622 217 -635 -8 -36 -224 -465
    -234 -466 -7 0 -17 21 -24 48 -46 185 -223 359 -422 417 -77 22 -236 22 -314
    -1 -174 -50 -312 -166 -389 -329 -14 -30 -27 -55 -28 -55 -1 0 -27 9 -57 21
    -69 26 -205 36 -283 19 -239 -49 -427 -277 -427 -516 0 -81 174 -1209 193
    -1257 14 -32 14 -46 1 -124 -8 -48 -17 -160 -21 -248 -15 -428 106 -862 341
    -1224 284 -437 739 -765 1240 -895 202 -53 265 -60 531 -61 220 0 264 3 370
    23 788 151 1360 749 1529 1602 37 188 50 348 43 565 -12 362 -45 507 -242
    1050 -48 132 -106 294 -130 360 -23 66 -113 315 -199 552 -86 238 -156 438
    -156 444 0 7 82 181 182 388 240 497 265 551 285 619 20 70 23 215 4 292 -51
    211 -226 390 -437 445 -75 19 -219 19 -298 -1 -88 -22 -202 -87 -268 -151
    l-56 -56 -32 37 c-45 51 -166 129 -241 156 -83 29 -265 37 -353 15z m228 -290
    c32 -8 77 -28 100 -44 79 -55 73 -40 291 -646 47 -132 117 -325 155 -430 107
    -297 285 -792 357 -990 35 -99 114 -317 174 -484 60 -167 108 -306 105 -308
    -6 -7 -1609 139 -1618 146 -4 4 -19 87 -34 186 l-26 180 151 309 c83 170 153
    307 155 305 3 -2 54 -146 116 -318 61 -173 117 -329 126 -346 19 -40 80 -75
    130 -75 76 0 144 68 144 143 0 23 -65 220 -169 515 -93 262 -247 695 -341 961
    -94 267 -176 510 -182 540 -31 168 57 316 212 357 61 17 86 17 154 -1z m995
    -16 c59 -26 116 -81 149 -144 22 -42 27 -63 27 -130 0 -80 0 -81 -83 -255 -46
    -96 -120 -251 -166 -345 l-82 -170 -16 45 c-9 25 -73 202 -142 393 -69 192
    -126 355 -126 363 0 26 61 139 93 172 94 97 226 124 346 71z m-2032 -1675 c61
    -29 126 -98 147 -157 19 -49 149 -894 142 -914 -3 -7 -12 -13 -20 -13 -7 0
    -51 -19 -97 -42 -115 -57 -213 -155 -266 -267 -21 -44 -42 -81 -46 -81 -18 0
    -107 95 -127 136 -25 54 -170 973 -170 1076 1 220 235 361 437 262z m-841
    -345 c55 -26 110 -87 123 -136 24 -91 181 -1152 175 -1184 -11 -54 -40 -104
    -84 -142 -109 -93 -278 -67 -353 55 -31 49 -23 4 -161 909 -40 261 -43 322
    -20 377 20 49 70 100 119 123 55 26 146 25 201 -2z m2239 -1095 c517 -47 941
    -87 942 -88 1 -1 15 -49 32 -106 200 -695 40 -1474 -400 -1944 -229 -245 -549
    -415 -889 -473 -117 -20 -400 -22 -520 -4 -645 95 -1190 519 -1436 1116 -88
    215 -124 392 -131 644 -5 154 -3 183 8 178 119 -49 270 -56 381 -19 137 47
    255 150 316 275 17 37 32 73 32 81 0 25 15 26 69 7 52 -18 53 -20 76 -85 62
    -178 197 -310 378 -370 63 -22 137 -30 562 -66 269 -23 491 -43 493 -44 1 -2
    -15 -56 -38 -121 -63 -185 -83 -295 -84 -470 -1 -134 1 -154 19 -185 41 -69
    144 -97 203 -53 62 46 72 74 72 210 0 196 43 366 151 591 66 137 73 182 38
    239 -42 68 24 58 -944 138 -170 14 -331 30 -357 36 -95 21 -176 95 -214 194
    -21 57 -16 174 11 230 44 92 163 173 255 175 19 0 458 -39 975 -86z"
        />
      </g>
    </svg>
  );
};
