import React from 'react';

export function IconMark() {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5294 1.53039L5.99908 15.0607L0.46875 9.53039L1.52941 8.46973L5.99908 12.9394L18.4688 0.469727L19.5294 1.53039Z"
        fill="#344054"
      />
    </svg>
  );
}
