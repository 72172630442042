import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isExpired } from 'react-jwt';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';

import { BaseLayout } from 'layouts';
import { ProtectedRoute, UnauthorizedRoute } from 'components';
import {
  Directory,
  ForgotPassword,
  Home,
  NotFound,
  ResetPassword,
  SignIn,
  SignUp,
  VerifyEmail,
} from 'views';

import { routes } from 'config';
import {
  catchUnauthorized,
  getBillingPortal,
  getProfile,
  setAuthToken,
  setProfile,
  signOut,
} from './redux/actions';
import { RootState, useTypedDispatch } from './redux/reducers';
import { IProfile } from './interfaces';
import { useProfileInfo } from './hooks';
import { FULL_DIRECTORY, INSTALLER_DIRECTORY } from './utils';

import './scss/app.scss';

const App: React.FunctionComponent = () => {
  const token = useSelector<RootState>((state) => state.auth.token) as string;
  const profile = useSelector<RootState>((state) => state.auth.profile) as IProfile;
  const dispatch = useTypedDispatch();
  const { isPrimaryContact } = useProfileInfo();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userProfile = localStorage.getItem('profile');

    if (token) {
      dispatch(setAuthToken(token as string));
    }
    if (token && !!userProfile) {
      const parsedProfile = JSON.parse(userProfile);

      dispatch(setProfile(parsedProfile));
      dispatch(getProfile());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!!profile && isPrimaryContact) {
      dispatch(getBillingPortal(profile.company?.id, { returnUrl: window.location.href }));
    }
  }, [isPrimaryContact]);

  useEffect(() => {
    const interval = setInterval(() => {
      const authToken = localStorage.getItem('token');

      if (authToken) {
        const isTokenExpired = isExpired(authToken);

        isTokenExpired && dispatch(signOut());
      }
    }, 600000);

    return () => clearInterval(interval);
  }, [dispatch]);

  axios.interceptors.request.use((config) => {
    const storageToken = localStorage.getItem('token');

    if (config.headers) {
      config.headers.Authorization = storageToken ?? '';
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      if (response.data.code === '401') {
        dispatch(catchUnauthorized());
      }
      return response;
    },
    async (error) => {
      if (error.response?.status === 403 || error.response?.status === 401) {
        dispatch(catchUnauthorized());
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (location.pathname === INSTALLER_DIRECTORY || location.pathname === FULL_DIRECTORY) {
      localStorage.setItem('pathType', location.pathname);
    }
  }, []);

  return (
    <BrowserRouter>
      <Toaster
        position="top-left"
        containerStyle={{ fontSize: '1.8rem' }}
        toastOptions={{
          duration: 4000,
        }}
      />
      <BaseLayout>
        <Switch>
          <Route exact path="/">
            <Redirect to={token?.length > 0 ? routes.home : routes.signIn} />
          </Route>

          <UnauthorizedRoute path={routes.signIn}>
            <SignIn />
          </UnauthorizedRoute>

          <UnauthorizedRoute path={routes.getStarted}>
            <SignUp />
          </UnauthorizedRoute>

          <UnauthorizedRoute path={routes.forgotPassword}>
            <ForgotPassword />
          </UnauthorizedRoute>

          <UnauthorizedRoute path={routes.resetPassword}>
            <ResetPassword />
          </UnauthorizedRoute>

          <Route path={routes.verifyEmail}>
            <VerifyEmail />
          </Route>

          <ProtectedRoute exact path={routes.home}>
            <Home />
          </ProtectedRoute>

          <ProtectedRoute exact path={routes.installerDirectory} key={routes.installerDirectory}>
            <Directory type={INSTALLER_DIRECTORY} />
          </ProtectedRoute>

          <ProtectedRoute exact path={routes.fullDirectory} key={routes.fullDirectory}>
            <Directory type={FULL_DIRECTORY} />
          </ProtectedRoute>

          {/*<ProtectedRoute exact path={routes.tickets}>*/}
          {/*  <Tickets />*/}
          {/*</ProtectedRoute>*/}

          {/*<ProtectedRoute path={routes.entry}>*/}
          {/*  <Entry />*/}
          {/*</ProtectedRoute>*/}

          <Route exact path={routes.notFound}>
            <NotFound />
          </Route>

          <Route path="*">
            <Redirect to={routes.notFound} />
          </Route>
        </Switch>
      </BaseLayout>
    </BrowserRouter>
  );
};

export default App;
