import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { routes } from 'config';

type IProps = RouteProps;

/*
  Prevent authorized user visit login, reset, restore, etc
*/
const UnauthorizedRoute: React.FunctionComponent<IProps> = ({ children, ...rest }) => {
  return localStorage.getItem('token') ? (
    <Redirect from="" to={routes.home} />
  ) : (
    <Route {...rest}>{children}</Route>
  );
};

export { UnauthorizedRoute };
