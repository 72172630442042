import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import queryString from 'query-string';

import Container from './Container';
import { Box, Button, FormControl, InputLabel, Typography } from '@mui/material';
import { Loading, PhoneInput } from 'components';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';

import { buildPasswordRegex, testPhoneNumber } from 'utils';
import { routes } from 'config';
import { confirmUser, signUp } from 'redux/actions';
import { useTypedDispatch } from 'redux/reducers';
import { checkEmailAPI, getInvitedUserAPI } from 'api';
import { IUser } from 'interfaces';

import styles from './auth.module.scss';

const SignUp: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { token } = queryString.parse(location.search);
  const dispatch = useTypedDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [prevEmail, setPrevEmail] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [invitedUser, setInvitedUser] = useState<IUser | null>(null);

  const getUserInfo = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getInvitedUserAPI(token as string);
      setInvitedUser(res);
      setCurrentPage(1);
    } catch (e) {
      history.push(routes.signIn);
    } finally {
      setLoading(false);
    }
  }, [history, t, token]);

  useEffect(() => {
    if (token) {
      getUserInfo();
    }
  }, [token]);

  const initialValues = {
    email: invitedUser?.email ?? '',
    password: '',
    passwordConfirmation: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('formErrors.wrongEmailFormat'))
      .test('email', t('formErrors.forbiddenDomain'), (value) => {
        const restrictedDomains = [
          '@mersongroup.com',
          '@merson-signs.com',
          '@fespauk.com',
          '@bannerbox.co.uk',
          'rmclimited.co.uk',
        ];

        if (value && restrictedDomains.some((item) => value.endsWith(item))) {
          return false;
        }
        return true;
      })
      .required(t('formErrors.required')),
    password: Yup.string()
      .required(t('formErrors.required'))
      .matches(buildPasswordRegex(8), t('formErrors.passwordTooWeak')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], t('formErrors.passwordNotMatch'))
      .required(t('formErrors.required')),
    firstName: Yup.string().required(t('formErrors.required')),
    lastName: Yup.string().required(t('formErrors.required')),
    phoneNumber: Yup.string()
      .test('phoneNumber', t('formErrors.phoneNumberInvalid'), (value) => testPhoneNumber(value))
      .required(t('formErrors.required'))
      .nullable(),
  });

  const onSubmit = async (values) => {
    try {
      invitedUser
        ? await dispatch(confirmUser(token as string, values))
        : await dispatch(signUp(values));
    } catch (e) {
      console.log(e);
    }
  };

  const pages = (errors, touched) => [
    <Field
      component={TextField}
      key="email"
      label={t('formLabel.email')}
      name="email"
      placeholder={t('placeholders.email')}
      type="email"
    />,
    <>
      {!!invitedUser && (
        <Box mt={1} mb="10px">
          <InputLabel>{t('formLabel.email')}</InputLabel>
          <Typography variant="subtitle1">{invitedUser.email}</Typography>
        </Box>
      )}
      <Field
        autoComplete="off"
        component={TextField}
        label={t('formLabel.password')}
        name="password"
        placeholder={t('placeholders.password')}
        type="password"
      />
      <Field
        autoComplete="off"
        component={TextField}
        label={t('formLabel.passwordConfirm')}
        name="passwordConfirmation"
        placeholder={t('placeholders.passwordConfirm')}
        type="password"
      />
    </>,
    <>
      <Field
        component={TextField}
        label={t('formLabel.firstName')}
        name="firstName"
        placeholder={t('placeholders.firstName')}
        type="text"
      />
      <Field
        component={TextField}
        label={t('formLabel.lastName')}
        name="lastName"
        placeholder={t('placeholders.lastName')}
        type="text"
      />
      <FormControl>
        <InputLabel
          htmlFor="phoneNumber"
          error={!!errors['phoneNumber'] && !!touched['phoneNumber']}
        >
          {t('formLabel.phoneNumber')}
        </InputLabel>
        <Field component={PhoneInput} name="phoneNumber" type="text" />
      </FormControl>
    </>,
  ];

  const FormContext = () => {
    const { values, errors, submitForm } = useFormikContext();

    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();

          if (isStepValid(values, errors)) {
            currentPage === 2 ? submitForm() : handleNextPage(values);
          }
        }
      };

      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }, [errors, values]);

    return null;
  };

  const handleNextPage = useCallback(
    async (values) => {
      if (currentPage === 0) {
        try {
          const { isUserExists } = await checkEmailAPI({ email: values.email });

          if (isUserExists) {
            setPrevEmail(values.email);
            toast.error(t('toast.emailInUse'), { duration: 6000 });
          } else {
            toast.dismiss();
            setPrevEmail('');
            setCurrentPage(currentPage + 1);
          }
        } catch (e: any) {
          console.log(e);
        }
      } else {
        setCurrentPage(currentPage + 1);
      }
    },
    [currentPage]
  );

  const handlePrevPage = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  const isStepValid = useCallback(
    (values, errors) => {
      return currentPage === 0
        ? !!values.email && !errors.email && values.email !== prevEmail
        : currentPage === 1
        ? !!values.password &&
          !errors.password &&
          !!values.passwordConfirmation &&
          !errors.passwordConfirmation
        : currentPage === 2
        ? !!values.firstName && !errors.lastName && !!values.phoneNumber
        : false;
    },
    [currentPage, prevEmail]
  );

  if (loading) return <Loading fullHeight />;

  return (
    <Container>
      <>
        <Box pb={3}>
          <Typography component="h1" variant="h1">
            {t('auth.signUp')}
          </Typography>

          <Box pt={1}>
            <Typography variant="body1">
              {t('auth.alreadyRegistered')}
              <Link to={routes.signIn} className="is-link">
                {t('auth.login')}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => {
            const { values, errors, touched, isSubmitting } = props;

            return (
              <Form className={styles.form} noValidate autoComplete="none">
                {pages(errors, touched)[currentPage]}
                <Box
                  alignItems="center"
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  mt={currentPage !== 2 ? 'auto' : 2}
                >
                  <Box display="flex" ml="auto">
                    {(invitedUser ? currentPage === 2 : currentPage !== 0) && (
                      <Button
                        className={styles.prevBtn}
                        onClick={handlePrevPage}
                        variant="outlined"
                        size="small"
                      >
                        {t('action.back')}
                      </Button>
                    )}
                    {currentPage !== 2 && (
                      <Button
                        color="primary"
                        disabled={!isStepValid(values, errors)}
                        onClick={() => handleNextPage(values)}
                        variant="contained"
                      >
                        {t('action.next')}
                      </Button>
                    )}
                    {currentPage === 2 && (
                      <Button
                        color="primary"
                        disabled={isSubmitting || !isStepValid(values, errors)}
                        type="submit"
                        variant="contained"
                      >
                        {t('action.createProfile')}
                      </Button>
                    )}
                  </Box>
                </Box>
                {currentPage === 2 && (
                  <Box mt={4}>
                    <Typography variant="body2">
                      {t('auth.byCreatingProfile')}
                      <a
                        href="https://www.signageandprint.com/legal/terms-of-service"
                        rel="noreferrer"
                        target="_blank"
                        className="is-link"
                      >
                        {t('auth.termsOfService')}
                      </a>
                      {', '}
                      <a
                        href="https://www.signageandprint.com/legal/privacy-policy"
                        rel="noreferrer"
                        target="_blank"
                        className="is-link"
                      >
                        {t('auth.privacyPolicy')}
                      </a>
                      {' and '}
                      <a
                        href="https://www.signageandprint.com/legal/end-user-license-agreement"
                        rel="noreferrer"
                        target="_blank"
                        className="is-link"
                      >
                        {t('auth.endUserLicense')}
                      </a>
                      .
                    </Typography>
                  </Box>
                )}
                <FormContext />
              </Form>
            );
          }}
        </Formik>
      </>
    </Container>
  );
};

export { SignUp };
