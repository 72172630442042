import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, MenuItem, TextField } from '@mui/material';
import { IconChevron } from 'components/Icons';

type IProps = {
  name?: string;
  placeholder?: string | number;
  onChange: (id: number | string) => void;
  onBlur?: ((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
  items: Array<{ id: string | number; name: string }>;
  value: string | number | undefined | Array<string | number>;
  listHeight?: number;
  label?: string;
  multiple?: boolean;
  styles?: string;
};

const useStyles = makeStyles({
  placeholder: {
    color: '#374A63',
    opacity: 0.25,
  },
  list: {
    maxHeight: ({ listHeight }: any) => listHeight || '',
  },
  hidden: {
    display: 'none',
  },

  menuItemRoot: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export const MuiSelect: React.FC<IProps> = memo((props) => {
  const {
    styles,
    name,
    items,
    onChange,
    onBlur,
    value,
    placeholder,
    label,
    multiple = false,
  } = props;
  const classes = useStyles(props);

  const handleChange = ({ target: { value } }) => {
    const val = value === 'none' ? '' : value;

    onChange && onChange(val);
  };

  const defaultSingleOption = (item) => (
    <MenuItem className={item.id === 'none' ? classes.hidden : ''} key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  );

  const defaultMultiOption = (item) => (
    <MenuItem
      classes={{
        root: classes.menuItemRoot,
      }}
      color="primary"
      key={item.id}
      value={item.id}
    >
      <span>{item.name}</span>
    </MenuItem>
  );

  const optionRenderer = multiple ? defaultMultiOption : defaultSingleOption;

  return (
    <TextField
      label={label}
      name={name}
      select
      className={styles}
      SelectProps={{
        multiple,
        displayEmpty: true,
        renderValue: (value): any => {
          const isEmpty = Array.isArray(value) ? !!value.length : !!value;

          if (!isEmpty)
            return <span className={classes.placeholder}>{placeholder || 'Select...'}</span>;

          return multiple
            ? (value as Array<string | number>)
                .map((selectedItem) => items.find((el) => el.id === selectedItem)?.name)
                .join(', ')
            : items.find((el) => el.id === value)?.name;
        },
        MenuProps: {
          classes: { paper: classes.list },
          keepMounted: true,
          disablePortal: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
        IconComponent: (props) => (
          <Box display="flex" className={props.className}>
            <IconChevron />
          </Box>
        ),
      }}
      value={value || ''}
      onChange={handleChange}
      onBlur={onBlur}
    >
      {items.map(optionRenderer)}
    </TextField>
  );
});
