import { commonAPI } from '../utils';
import axios from 'axios';

const SubscriptionEndpoints = {
  plans: 'billing-plans',
  subscribe: 'subscription',
  billingPortal: (id: string | number) => `subscription/billing-portal/${id}`,
};

export const getPlansAPI = () => commonAPI(axios.get(SubscriptionEndpoints.plans));

export const subscribeAPI = (values) =>
  commonAPI(axios.post(SubscriptionEndpoints.subscribe, values));

export const billingPortalAPI = (id: string | number, values) =>
  commonAPI(axios.post(SubscriptionEndpoints.billingPortal(id), values));
