import React from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Menu, MenuItem } from '@mui/material';
import { UsersPopup } from 'components';
import { routes } from 'config';
import { showUsersPopup, signOut } from 'redux/actions';
import { useProfileInfo } from 'hooks';
import { RootState, useTypedDispatch } from 'redux/reducers';
import { IModalsState } from 'interfaces';

import styles from './header.module.scss';

const Settings = ({ anchorEl, setAnchorEl }) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { usersPopup } = useSelector<RootState>((state) => state.modals) as IModalsState;
  const history = useHistory();
  const open = Boolean(anchorEl);
  const { billingPortal } = useProfileInfo();

  const handleClose = () => setAnchorEl(null);
  const onSignOut = async () => {
    dispatch(signOut());
    toast.success(t('toast.logout'));
    history.push(routes.signIn);
  };

  const menuOptions = [
    {
      isLink: true,
      href: billingPortal,
      name: t('settings.billing'),
      hidden: !billingPortal,
    },
    {
      isLink: false,
      name: t('settings.users'),
      onClick: () => {
        handleClose();
        dispatch(showUsersPopup(true));
      },
    },
    {
      isLink: false,
      name: t('action.logout'),
      onClick: onSignOut,
    },
  ];

  return (
    <>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
      >
        {menuOptions.map((option, index) => {
          if (option.hidden) return null;

          return option.isLink ? (
            <a
              href={option.href}
              rel="noreferrer"
              target="_blank"
              style={{ color: 'unset' }}
              key={index}
            >
              <MenuItem className={styles.item}>{option.name}</MenuItem>
            </a>
          ) : (
            <MenuItem className={styles.item} onClick={option.onClick} key={index}>
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>

      {usersPopup && (
        <UsersPopup isOpen={usersPopup} onClose={() => dispatch(showUsersPopup(false))} />
      )}
    </>
  );
};

export { Settings };
