import { parsePhoneNumber } from 'libphonenumber-js';

export const constructPhoneNumber = (
  countryCode: string | null | undefined,
  phoneNumber: string | undefined
) => {
  let finalPhone = '';

  if (countryCode && phoneNumber) {
    finalPhone = countryCode + phoneNumber;
  } else if (!countryCode && phoneNumber) {
    finalPhone = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
  }

  return finalPhone;
};

export const testPhoneNumber = (value) => {
  if (!value) return true;
  try {
    const phone = value.includes('+') ? value : `+${value}`;
    const parsed = parsePhoneNumber(phone ?? '');
    if (parsed?.nationalNumber) {
      const { nationalNumber } = parsed;
      return nationalNumber.length >= 4 && nationalNumber.length <= 15;
    }
    return false;
  } catch (e) {
    return false;
  }
};
