import React from 'react';
import NoLogoIcon from './NoLogoIcon.png';

const CompanyLogo = ({ src }) => {
  return (
    <>
      <img
        src={src ?? NoLogoIcon}
        alt="logo"
        style={{ maxWidth: '100%', objectFit: 'contain', maxHeight: '100%', borderRadius: '8px' }}
      />
    </>
  );
};

export { CompanyLogo };
