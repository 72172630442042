import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button, IconButton, Typography } from '@mui/material';
import { CompanyLogo, IconClose, IconMark, Loading, MuiSelect } from 'components';

import {
  CURRENCY,
  EUR,
  GBP,
  INSTALLATIONS_FREELANCER_SERVICE_ID,
  INSTALLATIONS_SERVICE_ID,
  USD,
} from 'utils';
import { getCompaniesAPI, subscribeAPI } from 'api';
import { makeStyles } from '@mui/styles';
import { RootState, useTypedDispatch } from 'redux/reducers';
import { getPlans, showPricingPopup } from 'redux/actions';
import { ICompanyState } from 'interfaces';
import LianaImg from './LianaImg.jpeg';

const useStyles = makeStyles({
  select: {
    '& .MuiInputBase-root': {
      width: '100px',
    },
    '& .MuiSelect-select': {
      fontWeight: 500,
    },
  },
  oldPrice: {
    fontWeight: 300,
    color: '#666666',
    position: 'relative',

    '&::after': {
      content: "''",
      display: 'block',
      width: '100%',
      height: '50%',
      position: 'absolute',
      top: '2px',
      left: 0,
      borderBottom: '1px solid',
    },
  },
});

const currencyOptions = [
  { id: USD, name: `${CURRENCY[USD]} ${USD}` },
  { id: EUR, name: `${CURRENCY[EUR]} ${EUR}` },
  { id: GBP, name: `${CURRENCY[GBP]} ${GBP}` },
];

const prices = {
  annually: 29,
  monthly: 49,
};

const ANNUALLY = 'annually';
const YEARLY = 'yearly';
const MONTHLY = 'monthly';

const PricingModalPopup = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currency, setCurrency] = useState<string>(GBP);
  const [period, setPeriod] = useState<string>(ANNUALLY);
  const [loading, setLoading] = useState<boolean>(false);
  const [installerCompanies, setInstallerCompanies] = useState([]);

  const dispatch = useTypedDispatch();
  const { subscription } = useSelector<RootState>((state) => state.company) as ICompanyState;

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const { companies } = await getCompaniesAPI();

      setInstallerCompanies(
        companies.filter((company) =>
          company.services.some(
            (service) =>
              service.id === INSTALLATIONS_SERVICE_ID ||
              service.id === INSTALLATIONS_FREELANCER_SERVICE_ID
          )
        ).length
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
    dispatch(getPlans());
  }, []);

  const onPlanUpgrade = async (period, currency) => {
    const url = `${window.location?.origin}/installer-directory`;
    const planPeriod = period === ANNUALLY ? YEARLY : MONTHLY;

    const priceItem = subscription?.prices.find(
      (price) => price.period === planPeriod && price.currencyCode === currency
    );

    const res = await subscribeAPI({
      priceId: priceItem?.id,
      successUrl: `${url}?paymentSuccess=true`,
      cancelUrl: url,
    });

    window.open(res.link, '_self');
  };

  if (loading) return <Loading />;

  return (
    <Box mt="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" style={{ color: '#1D1D1B', fontSize: '30px' }}>
          {`Search ${installerCompanies} installer profiles`}
        </Typography>
        <Box display="flex" alignItems="center" gap="20px">
          <MuiSelect
            onChange={(value) => setCurrency(value as string)}
            items={currencyOptions}
            value={currency}
            styles={classes.select}
          />

          <IconButton color="primary" onClick={() => dispatch(showPricingPopup(false))}>
            <IconClose />
          </IconButton>
        </Box>
      </Box>

      <Box display="flex" gap="28px" mt="15px" alignItems="center">
        <Box
          width="calc(100% / 2 - 14px)"
          minHeight="270px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box mt="15px">
            <Box display="flex" gap="10px" mb="20px">
              <Box width="60px">
                <CompanyLogo src={LianaImg} />
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 600, fontSize: '16px', marginBottom: '5px' }}
                >
                  {t('pricingModal.title')}
                </Typography>
                <Typography variant="body1">{t('pricingModal.subtitle')}</Typography>
              </Box>
            </Box>

            <Typography variant="subtitle1" style={{ fontSize: '14px' }}>
              {t('pricingModal.description')}
            </Typography>
          </Box>

          <Box
            bgcolor="rgba(0, 70, 255, 0.05)"
            padding="16px 24px"
            borderRadius="8px"
            display="flex"
            alignItems="center"
            gap="16px"
            height="60px"
          >
            <Typography style={{ fontSize: '22px' }}>{t('pricingModal.celebrateEmoji')}</Typography>
            <Typography variant="subtitle1" style={{ fontSize: '14px' }}>
              {'Use code '}
              <span style={{ color: '#0046FF', fontWeight: 500 }}>{t('pricingModal.promo')}</span>
              {` to get your first year for just ${CURRENCY[currency]}99`}
            </Typography>
          </Box>
        </Box>
        <Box
          width="calc(100% / 2 - 14px)"
          minHeight="270px"
          bgcolor="#F8F8F8"
          borderRadius="8px"
          padding="24px"
        >
          <Typography variant="h2" component="p" style={{ color: 'black', fontSize: '30px' }}>
            {period === ANNUALLY && (
              <span className={classes.oldPrice}>{`${CURRENCY[currency]}${prices[MONTHLY]}`}</span>
            )}
            {` ${CURRENCY[currency]}${prices[period]} / month`}
          </Typography>
          <Typography variant="body1">{`paid ${period}`}</Typography>
          <Box mt="20px" display="flex" flexDirection="column" gap="20px">
            <Box display="flex" alignItems="center" gap="20px">
              <IconMark />
              <Typography variant="body1">{t('pricingModal.feature1')}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="20px">
              <IconMark />
              <Typography variant="body1">{t('pricingModal.feature2')}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="20px">
              <IconMark />
              <Typography variant="body1">{t('pricingModal.feature3')}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt="30px" display="flex" alignItems="center" justifyContent="space-between">
        <Box className="is-pointer">
          <Typography
            variant="body1"
            style={{ color: 'black', fontWeight: 600 }}
            onClick={() => setPeriod(period === ANNUALLY ? MONTHLY : ANNUALLY)}
          >
            {`Pay ${period === ANNUALLY ? MONTHLY : ANNUALLY}`}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" textAlign="right">
          <Typography style={{ whiteSpace: 'pre-wrap', marginRight: '15px' }} variant="body2">
            {t('directory.gotCoupon')}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            style={{ width: '180px' }}
            onClick={() => onPlanUpgrade(period, currency)}
          >
            {t('action.subscribeNow')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { PricingModalPopup };
