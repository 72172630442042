import axios from 'axios';
import {
  IEmail,
  IForgotPassword,
  IResetPassword,
  ISignIn,
  ISignInResponse,
  ISignUp,
} from 'interfaces';
import { commonAPI } from 'utils';

const AuthEndpoints = {
  signIn: '/auth/login',
  signUp: '/sign-up',
  forgotPassword: '/auth/forgot-password',
  resetPassword: (token: string) => `/auth/renew-password/${token}`,
  sendVerificationEmail: '/user/send-verification-link',
  changeEmail: '/user/change-non-verified-email',
  verifyEmail: (token: string) => `/sign-up/verify-email/${token}`,
  checkEmail: '/auth/user-exists',
  profile: '/me',
};

export const signInAPI = (values: ISignIn) =>
  commonAPI(axios.post<ISignInResponse>(AuthEndpoints.signIn, values));

export const signUpAPI = async (values: ISignUp) =>
  commonAPI(axios.post<ISignInResponse>(AuthEndpoints.signUp, values));

export const forgotPasswordAPI = async (values: IForgotPassword) =>
  commonAPI(axios.post(AuthEndpoints.forgotPassword, values));

export const resetPasswordAPI = async (token: string, values: IResetPassword) =>
  commonAPI(axios.post<ISignInResponse>(AuthEndpoints.resetPassword(token), values));

export const sendVerificationEmailAPI = async () =>
  commonAPI(axios.post(AuthEndpoints.sendVerificationEmail));

export const changeEmailAPI = async (values: IEmail) =>
  commonAPI(axios.patch(AuthEndpoints.changeEmail, values));

export const verifyEmailAPI = async (token: string) =>
  commonAPI(axios.post(AuthEndpoints.verifyEmail(token)));

export const checkEmailAPI = async (values: IEmail) =>
  commonAPI(axios.post(AuthEndpoints.checkEmail, values));

export const getProfileAPI = async () => commonAPI(axios.get(AuthEndpoints.profile));
