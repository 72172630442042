import {
  CLEAR_COMPANY_STATE,
  SET_COMPANIES_LIST,
  SET_COMPANY_OPTIONS,
  SET_LOADING,
  SET_SUBSCRIPTION,
  SET_USERS,
} from '../types';
import { ICompanyState } from 'interfaces';

const initialState: ICompanyState = {
  categories: [],
  certifications: [],
  companiesList: [],
  loading: false,
  services: [],
  tradeTypes: [],
  subscription: null,
  users: [],
};

const companyReducer = (state: ICompanyState = { ...initialState }, action): ICompanyState => {
  switch (action.type) {
    case SET_COMPANY_OPTIONS:
      return { ...state, ...action.payload };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_COMPANIES_LIST:
      return { ...state, companiesList: action.payload };
    case SET_SUBSCRIPTION:
      return { ...state, subscription: action.payload };
    case SET_USERS:
      return { ...state, users: action.payload };
    case CLEAR_COMPANY_STATE:
      return initialState;
    default:
      return { ...state };
  }
};

export default companyReducer;
