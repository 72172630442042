import { RESET_AUTH_TOKEN, SET_AUTH_TOKEN, SET_PROFILE } from '../types';
import { IAuthState } from 'interfaces';

const initialState: IAuthState = {
  token: null,
  profile: null,
};

const authReducer = (state: IAuthState = { ...initialState }, action): IAuthState => {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return { ...state, token: action.token };
    case SET_PROFILE:
      return { ...state, profile: action.profile };
    case RESET_AUTH_TOKEN:
      return { ...state, token: null };
    default:
      return { ...state };
  }
};

export default authReducer;
