import React from 'react';

export function IconUKGALogo({ width = '200px' }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 590.27"
      width={width}
    >
      <path
        className="cls-1"
        d="M139.29,406.53c-14.44,0-27.15-1.92-37.93-5.94s-19.63-9.38-26.75-16.46a64.74,64.74,0,0,1-16-24.7,88.39,88.39,0,0,1-5.2-30.64V217H115.8V322.09q0,13.21,6.35,20.68c4.24,5,10.4,7.47,18.29,7.47s13.86-2.49,18.29-7.47q6.35-7.47,6.35-20.68V217h62V329a84.82,84.82,0,0,1-5.58,30.63,71.8,71.8,0,0,1-16.55,24.7,78.89,78.89,0,0,1-27.34,16.46C166.43,404.61,153.72,406.53,139.29,406.53Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M304.25,328h-.58v73.52H243.23V217h60.25v68h.58l49.47-68h73.15L357,301.42,430,401.36H353.53Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M155.46,606.41a120.55,120.55,0,0,1-40.62-6.7,97.09,97.09,0,0,1-32.53-19.34,87.62,87.62,0,0,1-21.56-30.63,99.62,99.62,0,0,1-7.89-40c0-14.74,2.69-28.14,7.89-40a87.75,87.75,0,0,1,21.56-30.64,95.49,95.49,0,0,1,32-19.72,110.05,110.05,0,0,1,39.27-6.89,117.76,117.76,0,0,1,41.19,7.09c13.09,4.59,23.68,11.1,31.77,19.33L190.1,482.34a43.57,43.57,0,0,0-13.66-10.72c-5.2-2.68-11.74-4-19.44-4a36.71,36.71,0,0,0-14.83,3.07,35.54,35.54,0,0,0-12.7,8.61,46.46,46.46,0,0,0-8.86,13.4,42.71,42.71,0,0,0-3.46,17.43c0,12.82,3.27,23.35,10,31.4s17.52,12.06,32.34,12.06a56.3,56.3,0,0,0,8.08-.58,22.36,22.36,0,0,0,6.55-1.91v-14H144.48V488.47h85.09V589.18a90.63,90.63,0,0,1-15,7.08,154.9,154.9,0,0,1-18.09,5.56c-6.36,1.53-13.09,2.87-20,3.82C169.51,605.84,162.39,606.41,155.46,606.41Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M245,417.25h79.89A145.16,145.16,0,0,1,352,419.93a70.33,70.33,0,0,1,24.06,9.57,51.57,51.57,0,0,1,17.13,18.57c4.43,7.66,6.55,17.43,6.55,29.11,0,11.29-2.7,21.25-8.28,29.86S377.78,522.36,367.39,527l47,74.67H342.75l-35.42-65.86h-1.92v65.86H245ZM305,495.17h12.13a46.34,46.34,0,0,0,6.93-.57,21.29,21.29,0,0,0,6.93-2.3,15.7,15.7,0,0,0,5.39-4.59c1.54-1.92,2.12-4.6,2.12-7.85a15.25,15.25,0,0,0-1.74-7.85,10.8,10.8,0,0,0-4.62-4.22,23.08,23.08,0,0,0-6.16-1.91c-2.31-.19-4.23-.38-6.16-.38H305v29.67Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M494.05,417.25h66.22l72.18,184.37H564.7l-9.63-27H496l-9.24,27H420.9Zm32.15,64.33L511,527.15h30Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M702.33,542.27v59.35h-62V417.25h79.5a122,122,0,0,1,27.72,3.06,68.56,68.56,0,0,1,24.26,10.15,55,55,0,0,1,17.32,19c4.43,7.85,6.55,17.81,6.55,29.87,0,11.49-2.12,21.25-6.36,29.1a55.83,55.83,0,0,1-16.94,19.53,73.22,73.22,0,0,1-24.25,10.91,115.14,115.14,0,0,1-28.1,3.45Zm0-76.77v29.67h13.09a25.06,25.06,0,0,0,12.89-3.44c3.85-2.3,6-6.32,6-11.87a14.9,14.9,0,0,0-1.54-7.09,10,10,0,0,0-4.23-4.21,29.55,29.55,0,0,0-6.16-2.3,42.1,42.1,0,0,0-6.74-.57H702.33Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M916.76,531.74H866v70.08H803.58V417.44h62v62.42h50.82V417.44h62.75V601.81H916.76Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M992.61,417.25h62.75V601.62H992.61Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M1066,509.53c0-14.74,2.69-28.14,7.89-40a87.66,87.66,0,0,1,21.56-30.64,96.25,96.25,0,0,1,32.14-19.72,111.87,111.87,0,0,1,39.47-6.89,119.12,119.12,0,0,1,40.61,6.89c12.71,4.6,23.29,10.72,32,18.57l-38.3,45.76a34.61,34.61,0,0,0-13.09-10.34,43.47,43.47,0,0,0-17.52-3.44,40.65,40.65,0,0,0-14.63,2.68,35.69,35.69,0,0,0-11.93,7.85,32.75,32.75,0,0,0-7.89,12.63,45.45,45.45,0,0,0-2.89,16.47,39.53,39.53,0,0,0,3.08,16.27,38.65,38.65,0,0,0,8.08,12.45,33.15,33.15,0,0,0,11.94,7.85,36.61,36.61,0,0,0,14.44,2.68,37.62,37.62,0,0,0,18.47-4.4,38.43,38.43,0,0,0,12.13-10.34l38.31,45.56c-8.47,8.43-18.67,14.94-30.8,19.92s-26.18,7.46-41.58,7.46a107.63,107.63,0,0,1-39.46-7.08,95.69,95.69,0,0,1-32.15-19.91,97.26,97.26,0,0,1-21.56-30.64C1068.64,537.29,1066,524.08,1066,509.53Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M112.72,617.71h66.22l72.18,184.37H183.56l-9.63-27H114.84l-9.24,27H39.77ZM145.06,682,129.85,727.6h30Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M325.81,801.89H263.06L212.63,617.51h65.83l19.83,103.58h1l21.56-103.58h65.25l23.29,103.58h1l20.21-103.58h63.91L442.85,801.89H380.09L353.92,697.74h-1Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M528.7,617.71h66.22L667.1,802.08H599.34l-9.62-27h-58.9l-9.24,27h-66ZM561,682,545.83,727.6h30Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M675.19,617.71h79.88a144.32,144.32,0,0,1,27.14,2.68A70.13,70.13,0,0,1,806.27,630a51.52,51.52,0,0,1,17.14,18.57c4.42,7.66,6.54,17.42,6.54,29.1,0,11.3-2.69,21.26-8.28,29.87S808,722.82,797.61,727.41l47,74.67H773l-35.42-65.86h-1.92v65.86H675.19Zm59.86,77.73h12.13a45.14,45.14,0,0,0,6.93-.58,21,21,0,0,0,6.93-2.29,15.62,15.62,0,0,0,5.39-4.6c1.54-1.91,2.12-4.59,2.12-7.85a15.3,15.3,0,0,0-1.74-7.85,10.84,10.84,0,0,0-4.62-4.21,22.72,22.72,0,0,0-6.16-1.91c-2.31-.2-4.23-.39-6.16-.39H735.05v29.68Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M851.51,801.89V617.51h69.1a198.15,198.15,0,0,1,42,4.22A98,98,0,0,1,998,636.66a76.83,76.83,0,0,1,24.44,28c6.16,11.68,9.24,26.23,9.24,44,0,16.08-2.88,30.06-8.85,41.93a81.61,81.61,0,0,1-23.49,29.1,101.05,101.05,0,0,1-33.68,16.85,144.05,144.05,0,0,1-39.85,5.55h-74.3Zm60.06-131.72v78.49h11.55c14.63,0,26-3.06,33.68-9.38s11.75-16.65,11.75-31c0-11.87-3.85-21.26-11.75-28s-18.28-9.95-31.37-9.95H911.57Z"
        transform="translate(-39.77 -216.98)"
      />
      <path
        className="cls-1"
        d="M1108.1,807.25a122,122,0,0,1-22.13-1.91,125.29,125.29,0,0,1-21-5.75,124,124,0,0,1-19-8.8,99.16,99.16,0,0,1-16-11.3l37.92-41A64.88,64.88,0,0,0,1085.2,751a49.32,49.32,0,0,0,22.32,5.55,22.77,22.77,0,0,0,9.63-1.91c2.89-1.15,4.23-3.45,4.23-6.7a7.94,7.94,0,0,0-1.34-4.4,18.13,18.13,0,0,0-5.2-3.83,79.67,79.67,0,0,0-10-4.22c-4.23-1.53-9.62-3.44-16.17-5.55a135.66,135.66,0,0,1-18.09-7.47,65.9,65.9,0,0,1-15.4-10.53A48.89,48.89,0,0,1,1044.58,697c-2.7-5.74-3.85-12.83-3.85-21.06,0-11.49,2.31-21.25,7.12-29.29a60,60,0,0,1,18.29-19.72,75.74,75.74,0,0,1,25-11.11,114,114,0,0,1,27.53-3.44,105.45,105.45,0,0,1,37.34,7.08,96.58,96.58,0,0,1,33.3,20.29L1150.64,679a42.1,42.1,0,0,0-15.4-11.87c-6.16-2.68-11.55-4-16.55-4-4.62,0-8.28.58-11,1.92-2.89,1.34-4.24,3.44-4.24,6.31s1.35,4.79,4.24,6.32a130.29,130.29,0,0,0,14,5.17c6.54,2.11,13.67,4.6,21,7.28A92.4,92.4,0,0,1,1163,700.8a56.51,56.51,0,0,1,15.4,16.27c4,6.51,6,14.36,6,23.94,0,11.1-2.31,20.87-6.74,29.1a62.63,62.63,0,0,1-17.51,20.48,75.07,75.07,0,0,1-24.45,12.26A83.85,83.85,0,0,1,1108.1,807.25Z"
        transform="translate(-39.77 -216.98)"
      />
    </svg>
  );
}
