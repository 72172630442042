import React from 'react';

export function IconLogoFullDirectory() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1366 163"
      width="300px"
      height="30px"
      style={{ transform: 'scale(1.2)' }}
    >
      <defs></defs>
      <path d="m35.51,78.9c-2.79-.65-4.91-1.33-6.37-2.04-1.46-.71-2.44-1.48-2.93-2.29-.49-.81-.74-1.7-.74-2.65,0-1.66.7-3.02,2.09-4.08,1.39-1.05,3.4-1.58,6.01-1.58,1.56,0,2.96.17,4.2.51,1.24.34,2.32.85,3.24,1.53l1.04,4.77c.31,1.44,1.59,2.46,3.06,2.46h7.77l-.15-15.08c-2.34-1.29-5.24-2.34-8.69-3.13-3.45-.8-7.24-1.2-11.39-1.2-4.96,0-9.03.76-12.2,2.29-3.18,1.53-5.54,3.55-7.08,6.06-1.55,2.51-2.32,5.3-2.32,8.36,0,4.45,1.55,8.1,4.64,10.96,3.09,2.85,7.9,4.96,14.42,6.32,4.31.88,7.19,1.91,8.64,3.08,1.44,1.17,2.17,2.52,2.17,4.05,0,1.63-.68,2.99-2.04,4.08-1.36,1.09-3.7,1.63-7.03,1.63-1.77,0-3.34-.17-4.71-.51-1.38-.34-2.64-.8-3.8-1.38l-1.39-5.58c-.35-1.39-1.6-2.37-3.04-2.37h-7.9l.15,15.59c3.26,1.46,6.67,2.58,10.22,3.36,3.55.78,7.38,1.17,11.49,1.17,7.4,0,12.97-1.58,16.69-4.74,3.72-3.16,5.58-7.3,5.58-12.43,0-4.69-1.56-8.33-4.69-10.93-3.13-2.6-8.1-4.68-14.93-6.24Z" />
      <path d="m84.32,102.72l-4.66-1v-44.69h-22.83v6.38c0,1.5,1.07,2.79,2.54,3.07l5.41,1.04v34.19l-4.71,1c-1.45.31-2.48,1.58-2.48,3.06v6.38h29.2v-6.39c0-1.48-1.03-2.75-2.47-3.06Z" />
      <rect x="64.27" y="32.68" width="15.39" height="12.69" />
      <path d="m129.23,57.04h-1.9c-1.54,0-2.86,1.13-3.09,2.65l-.66,4.28c-1.67-2.55-3.69-4.51-6.06-5.89-2.38-1.38-5.21-2.06-8.51-2.06-4.42,0-8.2,1.22-11.36,3.64-3.16,2.43-5.59,5.82-7.29,10.17-1.7,4.35-2.55,9.43-2.55,15.23v1.07c0,5.4.85,10.14,2.55,14.22,1.7,4.08,4.16,7.24,7.39,9.5,3.23,2.26,7.08,3.39,11.57,3.39,2.85,0,5.38-.54,7.59-1.63,2.21-1.09,4.13-2.67,5.76-4.74v3.62c0,3.94-.93,7.01-2.78,9.22-1.85,2.21-4.76,3.31-8.74,3.31-2.31,0-4.8-.3-7.46-.89-1.53-.34-3.02-.75-4.46-1.24-1.7-.57-3.53.45-3.98,2.19l-1.88,7.22c2.65,1.33,5.71,2.34,9.17,3.06,3.47.71,6.59,1.07,9.38,1.07,5.33,0,9.92-.99,13.76-2.96,3.84-1.97,6.79-4.74,8.84-8.31,2.05-3.57,3.08-7.78,3.08-12.64v-43.15l3.99-.85c1.44-.31,2.48-1.58,2.48-3.06v-6.43h-14.83Zm-6.57,39.74c-.95,1.6-2.19,2.81-3.72,3.64-1.53.83-3.35,1.25-5.45,1.25-3.87,0-6.64-1.38-8.31-4.15-1.67-2.77-2.5-6.56-2.5-11.39v-1.07c0-3.5.37-6.55,1.12-9.15.75-2.6,1.92-4.64,3.52-6.11,1.6-1.48,3.67-2.22,6.22-2.22,2.04,0,3.83.46,5.38,1.38,1.55.92,2.79,2.21,3.75,3.87v23.95Z" />
      <path d="m207.93,102.72l-4.66-1v-24c0-7.47-1.55-12.96-4.64-16.46-3.09-3.5-7.37-5.25-12.84-5.25-3.46,0-6.55.78-9.25,2.35-2.7,1.56-4.97,3.75-6.8,6.57l-.42-5.03c-.14-1.62-1.49-2.87-3.12-2.87h-18.88v6.38c0,1.5,1.06,2.79,2.54,3.07l5.36,1.04v34.19l-4.66,1c-1.44.31-2.47,1.59-2.47,3.06v6.39h28.03v-6.48c0-1.43-.97-2.68-2.36-3.03l-3.65-.93v-28.94c1.05-1.66,2.37-2.95,3.95-3.85,1.58-.9,3.44-1.35,5.58-1.35,2.89,0,5.06.77,6.52,2.32,1.46,1.55,2.19,4.17,2.19,7.87v23.95l-3.97.95c-1.41.34-2.4,1.6-2.4,3.05v6.45h28.43v-6.39c0-1.48-1.03-2.75-2.47-3.06Z" />
      <path d="m264.28,102.87l-3.07-.43c-.1-.68-.16-1.38-.18-2.11-.02-.73-.03-1.47-.03-2.22v-22.73c0-6.22-2.09-11-6.27-14.34-4.18-3.35-9.87-5.02-17.07-5.02-4.14,0-7.9.51-11.26,1.53-3.36,1.02-6.49,2.34-9.38,3.98l.2,14.32h8.03c1.54,0,2.85-1.12,3.09-2.63l.75-4.65c1.02-.71,2.17-1.27,3.44-1.66,1.27-.39,2.71-.59,4.31-.59,3.09,0,5.41.82,6.96,2.47,1.55,1.65,2.32,3.79,2.32,6.44v4.08h-7.29c-5.4,0-9.95.75-13.63,2.24-3.69,1.49-6.47,3.55-8.36,6.16-1.89,2.62-2.83,5.66-2.83,9.12,0,5.06,1.61,9.06,4.84,12,3.23,2.94,7.57,4.41,13.04,4.41,3.23,0,6.04-.67,8.43-2.01,2.39-1.34,4.47-3.13,6.24-5.38.17,1.09.44,2.17.82,3.26.11.33.22.65.34.96.45,1.25,1.62,2.09,2.95,2.09h16.27v-6.2c0-1.56-1.15-2.88-2.69-3.1Zm-18.15-5.73c-1.02,1.53-2.46,2.76-4.33,3.7-1.87.93-3.84,1.4-5.91,1.4-2.21,0-3.93-.54-5.17-1.61-1.24-1.07-1.86-2.59-1.86-4.56,0-2.1.84-3.91,2.52-5.4,1.68-1.49,4.15-2.24,7.41-2.24h7.34v8.71Z" />
      <path d="m310.35,57.04h-1.9c-1.54,0-2.86,1.13-3.09,2.65l-.66,4.28c-1.67-2.55-3.69-4.51-6.06-5.89-2.38-1.38-5.21-2.06-8.51-2.06-4.42,0-8.2,1.22-11.36,3.64-3.16,2.43-5.59,5.82-7.29,10.17-1.7,4.35-2.55,9.43-2.55,15.23v1.07c0,5.4.85,10.14,2.55,14.22,1.7,4.08,4.16,7.24,7.39,9.5,3.23,2.26,7.08,3.39,11.57,3.39,2.85,0,5.38-.54,7.59-1.63,2.21-1.09,4.13-2.67,5.76-4.74v3.62c0,3.94-.93,7.01-2.78,9.22-1.85,2.21-4.76,3.31-8.74,3.31-2.31,0-4.8-.3-7.46-.89-1.53-.34-3.02-.75-4.46-1.24-1.7-.57-3.53.45-3.98,2.19l-1.88,7.22c2.65,1.33,5.71,2.34,9.17,3.06,3.46.71,6.59,1.07,9.38,1.07,5.33,0,9.92-.99,13.76-2.96,3.84-1.97,6.79-4.74,8.84-8.31,2.05-3.57,3.08-7.78,3.08-12.64v-43.15l3.99-.85c1.44-.31,2.48-1.58,2.48-3.06v-6.43h-14.83Zm-6.57,39.74c-.95,1.6-2.19,2.81-3.72,3.64s-3.35,1.25-5.45,1.25c-3.87,0-6.64-1.38-8.31-4.15-1.67-2.77-2.5-6.56-2.5-11.39v-1.07c0-3.5.37-6.55,1.12-9.15.75-2.6,1.92-4.64,3.52-6.11,1.6-1.48,3.67-2.22,6.22-2.22,2.04,0,3.83.46,5.38,1.38,1.54.92,2.79,2.21,3.75,3.87v23.95Z" />
      <path d="m362.84,58.97c-3.4-1.97-7.59-2.96-12.59-2.96-5.06-.03-9.45,1.16-13.17,3.59-3.72,2.43-6.6,5.76-8.64,9.99-2.04,4.23-3.06,9.08-3.06,14.55v2.04c0,5.23,1.08,9.88,3.24,13.96,2.16,4.08,5.21,7.28,9.15,9.61,3.94,2.33,8.54,3.49,13.81,3.49,4.45,0,8.4-.6,11.85-1.81,3.45-1.21,6.31-2.73,8.59-4.56l-2.66-6.07c-.7-1.59-2.57-2.34-4.14-1.6-1.35.64-2.73,1.17-4.12,1.58-2.36.7-4.9,1.04-7.62,1.04s-4.84-.54-6.68-1.6c-1.83-1.07-3.25-2.55-4.25-4.43-1-1.89-1.61-4.03-1.81-6.44l.1-.31h29.28c1.73,0,3.13-1.4,3.13-3.13v-4.97c0-5.27-.88-9.75-2.65-13.45-1.77-3.7-4.35-6.54-7.75-8.51Zm-3.52,20.23h-18.39l-.15-.26c.27-2.28.79-4.31,1.55-6.11.76-1.8,1.83-3.22,3.18-4.26,1.36-1.04,3.02-1.55,4.99-1.55,2.07,0,3.76.44,5.07,1.32,1.31.88,2.26,2.13,2.85,3.75.59,1.61.89,3.54.89,5.78v1.33Z" />
      <path d="m435.37,88.98c1.15-3.94,1.73-8.25,1.73-12.94h-8.13c-1.59,0-2.96,1.2-3.11,2.78-.16,1.64-.45,3.23-.89,4.76-.68,2.38-1.63,4.59-2.85,6.62l-14.06-16.41,4.74-3.36c2.89-2.07,5.16-4.48,6.83-7.24,1.66-2.75,2.5-5.79,2.5-9.12s-.77-6.08-2.32-8.66c-1.55-2.58-3.69-4.65-6.42-6.19-2.74-1.55-5.9-2.32-9.5-2.32-4.08,0-7.64.8-10.68,2.4-3.04,1.6-5.39,3.84-7.06,6.73-1.67,2.89-2.5,6.3-2.5,10.24,0,2.68.57,5.32,1.71,7.9,1.14,2.58,2.86,5.43,5.17,8.56-.17.1-.35.22-.53.36-.19.14-.37.27-.53.41-4.14,2.96-7.24,5.85-9.27,8.69-2.04,2.84-3.06,6.17-3.06,10.01,0,4.18.99,7.85,2.98,11.01,1.99,3.16,4.8,5.62,8.43,7.39,3.63,1.77,7.91,2.65,12.84,2.65,3.5,0,6.78-.47,9.83-1.4,3.06-.93,6.03-2.34,8.92-4.2l3.92,4.54h16.51l.1-.26-10.6-12.43c2.38-3.06,4.14-6.56,5.3-10.5Zm-36.64-38.39c1.19-1.48,2.89-2.22,5.1-2.22,2,0,3.63.66,4.87,1.99,1.24,1.33,1.86,2.84,1.86,4.54,0,1.53-.33,2.94-.99,4.23-.66,1.29-1.95,2.55-3.85,3.77l-4.38,2.85c-1.46-1.87-2.56-3.58-3.29-5.12-.73-1.55-1.1-3.05-1.1-4.51,0-2.21.59-4.05,1.78-5.53Zm8.82,50.55c-1.8.46-3.6.69-5.4.69-3.02,0-5.47-.98-7.34-2.93-1.87-1.95-2.8-4.44-2.8-7.46,0-1.56.31-3.12.94-4.66.63-1.55,1.81-3.13,3.54-4.76l.97-.66,15.29,17.73c-1.67.92-3.4,1.61-5.2,2.06Z" />
      <path d="m475.81,56.02c-5.74,0-10.39,2.14-13.96,6.42l-.24-2.57c-.15-1.61-1.5-2.84-3.12-2.84h-10.81v76.33h14.73v-25.99c3.43,3.87,7.93,5.81,13.5,5.81,6.56,0,11.76-2.56,15.62-7.67,3.86-5.11,5.78-11.91,5.78-20.41v-.76c0-8.83-1.89-15.76-5.66-20.79-3.77-5.03-9.05-7.54-15.85-7.54Zm-3.77,45.3c-4.62,0-7.83-1.82-9.63-5.45v-22.57c1.73-3.53,4.91-5.3,9.53-5.3,3.46,0,6.11,1.39,7.92,4.18,1.82,2.78,2.73,6.74,2.73,11.87,0,11.51-3.52,17.27-10.55,17.27Z" />
      <path d="m517.49,63.61l-.23-3.64c-.1-1.65-1.47-2.94-3.12-2.94h-10.97v55.13h14.73v-36.08c1.73-3.77,5.38-5.66,10.96-5.66.62,0,1.28.02,1.98.07,1.8.12,3.35-1.27,3.37-3.08l.15-10.75c-1.43-.44-2.96-.66-4.59-.66-5.23,0-9.32,2.53-12.28,7.59Z" />
      <rect x="539.92" y="32.68" width="15.39" height="12.69" />
      <rect x="540.23" y="57.04" width="14.78" height="55.13" />
      <path d="m594.04,56.02c-6.62,0-11.91,2.46-15.85,7.39l-.25-3.46c-.12-1.64-1.48-2.91-3.12-2.91h-10.95v55.13h14.73v-38.88c2.07-3.53,5.18-5.3,9.32-5.3,3.16,0,5.42.71,6.78,2.11,1.36,1.41,2.04,3.68,2.04,6.8v35.26h14.73v-35.62c-.07-6.83-1.53-11.96-4.38-15.39-2.85-3.43-7.2-5.15-13.04-5.15Z" />
      <path d="m643.54,101.06c-2.21,0-3.7-.44-4.48-1.32-.78-.88-1.17-2.34-1.17-4.38v-27.52h9.43v-10.8h-9.43v-13.55h-11.6c-1.73,0-3.13,1.4-3.13,3.13v10.42h-8.05v7.67c0,1.73,1.4,3.13,3.13,3.13h4.92v29.71c.2,10.43,5.47,15.64,15.8,15.64,3.06,0,6.03-.44,8.92-1.32v-11.16c-1.26.24-2.7.36-4.33.36Z" />
      <path d="m703.48,117.37h-8.02l30.66-79.72h7.97l-30.61,79.72Z" />
      <path d="m778.62,84.14c0-8.45,2-15.24,6-20.38,4-5.14,9.24-7.71,15.72-7.71s11.55,2.21,15.31,6.61v-28.7h9.41v78.15h-8.65l-.46-5.9c-3.77,4.61-9.01,6.92-15.72,6.92s-11.57-2.61-15.6-7.84c-4.02-5.22-6.03-12.04-6.03-20.45v-.71Zm9.41,1.07c0,6.24,1.29,11.13,3.87,14.65,2.58,3.53,6.14,5.29,10.68,5.29,5.97,0,10.33-2.68,13.08-8.04v-25.29c-2.82-5.19-7.14-7.78-12.97-7.78-4.61,0-8.21,1.78-10.79,5.34-2.58,3.56-3.87,8.84-3.87,15.82Z" />
      <path d="m839.72,42.47c0-1.53.47-2.81,1.4-3.87.93-1.05,2.31-1.58,4.15-1.58s3.22.53,4.17,1.58c.95,1.05,1.42,2.34,1.42,3.87s-.48,2.8-1.42,3.82c-.95,1.02-2.34,1.53-4.17,1.53s-3.21-.51-4.15-1.53c-.93-1.02-1.4-2.29-1.4-3.82Zm10.18,69.65h-9.41v-55.05h9.41v55.05Z" />
      <path d="m891.57,65.52c-1.42-.24-2.97-.36-4.63-.36-6.17,0-10.36,2.63-12.57,7.89v39.08h-9.41v-55.05h9.16l.15,6.36c3.09-4.92,7.46-7.38,13.13-7.38,1.83,0,3.22.24,4.17.71v8.75Z" />
      <path d="m922.1,113.14c-7.46,0-13.53-2.45-18.21-7.35-4.68-4.9-7.02-11.46-7.02-19.66v-1.73c0-5.46,1.04-10.34,3.13-14.63,2.08-4.29,5-7.65,8.75-10.07,3.75-2.42,7.81-3.64,12.18-3.64,7.16,0,12.72,2.36,16.69,7.07,3.97,4.72,5.95,11.47,5.95,20.25v3.92h-37.29c.13,5.43,1.72,9.81,4.76,13.15,3.04,3.34,6.89,5.01,11.58,5.01,3.32,0,6.14-.68,8.45-2.04,2.31-1.36,4.32-3.15,6.05-5.39l5.75,4.48c-4.61,7.09-11.53,10.63-20.76,10.63Zm-1.17-49.35c-3.8,0-6.99,1.38-9.57,4.15-2.58,2.76-4.17,6.64-4.78,11.63h27.58v-.71c-.27-4.78-1.56-8.49-3.87-11.12-2.31-2.63-5.43-3.94-9.36-3.94Z" />
      <path d="m976.54,105.46c3.36,0,6.29-1.02,8.8-3.05,2.51-2.04,3.9-4.58,4.17-7.63h8.9c-.17,3.15-1.26,6.16-3.26,9.01-2,2.85-4.67,5.12-8.01,6.82-3.34,1.7-6.88,2.54-10.61,2.54-7.5,0-13.46-2.5-17.88-7.5-4.43-5-6.64-11.85-6.64-20.53v-1.58c0-5.36.98-10.12,2.95-14.3,1.97-4.17,4.79-7.41,8.47-9.72,3.68-2.31,8.03-3.46,13.05-3.46,6.17,0,11.3,1.85,15.39,5.55,4.09,3.7,6.27,8.5,6.54,14.4h-8.9c-.27-3.56-1.62-6.49-4.05-8.78-2.42-2.29-5.42-3.43-8.98-3.43-4.78,0-8.49,1.72-11.12,5.16-2.63,3.44-3.94,8.42-3.94,14.93v1.78c0,6.34,1.3,11.23,3.92,14.65,2.61,3.43,6.34,5.14,11.19,5.14Z" />
      <path d="m1021.77,43.75v13.33h10.28v7.28h-10.28v34.18c0,2.21.46,3.86,1.37,4.97.92,1.1,2.48,1.66,4.68,1.66,1.08,0,2.58-.2,4.48-.61v7.59c-2.48.67-4.88,1.01-7.22,1.01-4.21,0-7.38-1.27-9.51-3.82s-3.21-6.16-3.21-10.84v-34.14h-10.02v-7.28h10.02v-13.33h9.41Z" />
      <path d="m1039.53,84.09c0-5.39,1.06-10.24,3.18-14.55,2.12-4.31,5.07-7.63,8.85-9.97,3.78-2.34,8.1-3.51,12.95-3.51,7.5,0,13.56,2.59,18.19,7.78,4.63,5.19,6.95,12.09,6.95,20.71v.66c0,5.36-1.03,10.17-3.08,14.42-2.05,4.26-4.99,7.57-8.8,9.95-3.82,2.37-8.2,3.56-13.15,3.56-7.46,0-13.51-2.59-18.14-7.78-4.63-5.19-6.94-12.06-6.94-20.61v-.66Zm9.46,1.12c0,6.11,1.42,11.01,4.25,14.7,2.83,3.7,6.62,5.55,11.37,5.55s8.58-1.87,11.4-5.62c2.81-3.75,4.22-9,4.22-15.75,0-6.04-1.43-10.93-4.3-14.68-2.87-3.75-6.68-5.62-11.42-5.62s-8.4,1.85-11.24,5.55c-2.85,3.7-4.27,8.99-4.27,15.87Z" />
      <path d="m1128.06,65.52c-1.42-.24-2.97-.36-4.63-.36-6.17,0-10.36,2.63-12.57,7.89v39.08h-9.41v-55.05h9.16l.15,6.36c3.09-4.92,7.46-7.38,13.13-7.38,1.83,0,3.22.24,4.17.71v8.75Z" />
      <path d="m1155.63,98.34l12.82-41.26h10.07l-22.13,63.55c-3.43,9.16-8.87,13.74-16.33,13.74l-1.78-.15-3.51-.66v-7.63l2.54.2c3.19,0,5.67-.64,7.45-1.93,1.78-1.29,3.25-3.65,4.4-7.07l2.09-5.6-19.64-54.44h10.28l13.74,41.26Z" />
    </svg>
  );
}
