import { SHOW_PRICING_POPUP, SHOW_USERS_POPUP } from '../types';
import { IModalsState } from 'interfaces';

const initialState: IModalsState = {
  usersPopup: false,
  pricingPopup: false,
};

const modalsReducer = (state: IModalsState = { ...initialState }, action): IModalsState => {
  switch (action.type) {
    case SHOW_USERS_POPUP:
      return { ...state, usersPopup: action.payload };
    case SHOW_PRICING_POPUP:
      return { ...state, pricingPopup: action.payload };
    default:
      return { ...state };
  }
};

export default modalsReducer;
