import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import throttle from 'lodash/throttle';
import GeocodeParser from 'google-geocode-parser';

const autocompleteService = { current: null };
const googlePlacesService = { current: null };

const useStyles = makeStyles({
  selected: {
    color: '#3851ff',
  },
  popupIndicatorOpen: {
    transform: 'rotate(0)',
  },
});

interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

export const GoogleAutocomplete = (props) => {
  const classes = useStyles(props);

  const {
    form: { errors, touched },
    field: { name, onBlur },
    label,
    initialValue,
    disabled,
  } = props;
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState(props.form.values.locationInput);
  const [options, setOptions] = useState<PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if ((!autocompleteService.current || !googlePlacesService.current) && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
      googlePlacesService.current = new (window as any).google.maps.places.PlacesService(
        document.createElement('div')
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      classes={classes}
      open={inputValue.length > 0 && !value && initialValue?.formattedAddress !== inputValue}
      id={name}
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      selectOnFocus={false}
      value={value}
      inputValue={inputValue}
      clearIcon={props.clearIcon}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(event: any, newValue: PlaceType | null, reason) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if (newValue) {
          (googlePlacesService.current as any).getDetails(
            {
              fields: [
                'name',
                'address_component',
                'formatted_address',
                'geometry',
                'place_id',
                'types',
              ],
              placeId: newValue.place_id,
            },
            (result) => {
              const parsed = new GeocodeParser({
                results: [result],
                status: 'OK',
              });

              const newValues = {
                ...props.form.values,
                address: {
                  formattedAddress: result.formatted_address,
                  placeId: result.place_id,
                  country: parsed.getCountry(true),
                  city:
                    parsed.getCity() ||
                    result.address_components.find((el) => el.types.includes('postal_town'))
                      ?.long_name ||
                    '',
                  state:
                    parsed.getCountry(true) === 'GB'
                      ? result.address_components.find((el) =>
                          el.types.includes('administrative_area_level_2')
                        )?.long_name
                      : parsed.getState(true),
                  lat: `${parsed.getLat()}`,
                  lng: `${parsed.getLng()}`,
                },
                locationInput: result.formatted_address,
              };

              props.form.setValues(newValues);
              props.onChange && props.onChange(event, newValues, reason);
            }
          );
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (
          props.form.values?.locationInput &&
          props.form.values?.locationInput !== newInputValue &&
          reason !== 'reset'
        ) {
          props.form.setValues({
            ...props.form.values,
            address: null,
            locationInput: '',
          });

          setValue(null);
          setOptions([]);
        }
        event && setInputValue(newInputValue);
      }}
      forcePopupIcon={false}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            className={props.rootClasses}
            fullWidth
            label={label}
            placeholder={props.placeholder}
            onBlur={onBlur}
            error={!!touched[name] && !!errors[name]}
            helperText={!!touched[name] && errors[name]}
            InputProps={{
              ...params.InputProps,
              ...props.InputProps,
            }}
          />
        );
      }}
    />
  );
};
