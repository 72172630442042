import React from 'react';
import classnames from 'classnames';
import { CircularProgress, CircularProgressProps } from '@mui/material';

import styles from './loading.module.scss';

interface IProps {
  fullHeight?: boolean;
  overflow?: boolean;
  centered?: boolean;
}

const Loading: React.FunctionComponent<IProps & CircularProgressProps> = ({
  fullHeight,
  overflow,
  centered,
  ...rest
}) => {
  return (
    <div
      className={classnames(styles.loadingWrapper, {
        [styles.fullHeight]: fullHeight === true,
        [styles.overflow]: overflow === true,
        [styles.centered]: centered === true,
      })}
    >
      <CircularProgress {...rest} color="primary" />
    </div>
  );
};

export { Loading };
