import { useMemo } from 'react';
import { isAfter } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { IProfile } from 'interfaces';
import { SUPPLIER_SERVICE_ID } from '../utils';

export const useProfileInfo = () => {
  const profile = useSelector<RootState>((state) => state.auth.profile) as IProfile;

  const isPrimaryContact = useMemo(
    () => profile && profile?.id === profile?.company?.owner?.id,
    [profile]
  );

  const isCompanyProfileCompleted = useMemo(
    () => !!profile?.company?.services && profile?.company?.services?.length > 0,
    [profile]
  );

  const isPlanExpired = useMemo(
    () =>
      isAfter(new Date(), new Date(profile?.company?.plan?.expiresAt)) || !profile?.company?.plan,
    [profile]
  );

  const isSupplierCompany = useMemo(
    () => !!profile?.company?.services?.find((service) => service.id === SUPPLIER_SERVICE_ID),
    [profile]
  );

  const billingPortal = useMemo(() => profile?.company?.billingPortal, [profile]);

  return {
    billingPortal,
    isPrimaryContact,
    isCompanyProfileCompleted,
    isPlanExpired,
    isSupplierCompany,
  };
};
