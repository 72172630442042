import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { Dialog, Loading } from 'components';
import { Table } from './Table';
import { InviteUserPopup } from './InviteUserPopup';
import { Button } from '@mui/material';

import { getUsers, setUsers } from 'redux/actions';
import { RootState, useTypedDispatch } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { ICompanyState, IProfile, IUser } from 'interfaces';
import { inviteUserAPI, resendInviteAPI, updateUser } from 'api';
import { INACTIVE_STATUS, STATUSES_PRIORITY } from 'utils';

const UsersPopup = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showInvitePopup, setShowInvitePopup] = useState<boolean>(false);
  const [showChangeEmailPopup, setShowChangeEmailPopup] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  const { users } = useSelector<RootState>((state) => state.company) as ICompanyState;
  const { id: profileId, company } = useSelector<RootState>(
    (state) => state.auth.profile
  ) as IProfile;

  const handleClosePopup = useCallback(
    () => (showInvitePopup ? setShowInvitePopup(false) : setShowChangeEmailPopup(false)),
    [showInvitePopup, showChangeEmailPopup]
  );

  const fetchUsers = async () => {
    setLoading(true);
    try {
      await dispatch(getUsers());
    } catch (e: any) {
      toast.error(e?.message);
      onClose();
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleResend = async (id) => {
    try {
      const res = await resendInviteAPI(id);
      toast.success(res.message);
    } catch (e: any) {
      return e;
    }
  };

  const handleEditEmail = async (user) => {
    setSelectedUser(user);
    setShowChangeEmailPopup(true);
  };

  const handleInvite = async (values, type) => {
    try {
      if (type === 'invite') {
        const res = await inviteUserAPI(values);
        fetchUsers();
        toast.success(res.message);
      } else {
        const res = await updateUser(selectedUser?.id as number, values);
        const mapped = users.map((item) => (item.id === selectedUser?.id ? res : item));

        dispatch(setUsers(mapped));
        toast.success(res.message ?? t('toast.updateUser'));
      }
      handleClosePopup();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const handleChangeStatus = async (user, status) => {
    const answer = confirm(t(status === INACTIVE_STATUS ? 'confirm.archive' : 'confirm.restore'));

    if (answer) {
      try {
        const res = await updateUser(user.id, { email: user.email, status });
        const mapped = users.map((item) => (item.id === user.id ? res : item));

        dispatch(setUsers(mapped));
        toast.success(t('toast.updateUser'));
      } catch (e: any) {
        return e;
      }
    }
  };

  const sortedUsers = useMemo(
    () =>
      users
        .sort((a, b) => a.firstName?.localeCompare(b.firstName))
        .sort((a, b) => STATUSES_PRIORITY[a.status?.id] - STATUSES_PRIORITY[b.status?.id]),
    [users]
  );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        heading={t('settings.users')}
        maxWidth="md"
        onClose={onClose}
        actions={
          <Button color="primary" variant="contained" onClick={() => setShowInvitePopup(true)}>
            {t('action.inviteUsers')}
          </Button>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <Table
            data={sortedUsers}
            profileId={profileId}
            ownerId={company?.owner?.id}
            handleResend={handleResend}
            handleEditEmail={handleEditEmail}
            handleChangeStatus={handleChangeStatus}
          />
        )}
      </Dialog>

      <Dialog
        heading={t('directory.inviteUser')}
        isOpen={showInvitePopup}
        maxWidth="xs"
        onClose={handleClosePopup}
      >
        <InviteUserPopup
          type={'invite'}
          onClose={handleClosePopup}
          handleInvite={handleInvite}
          initialValues={{ email: '' }}
        />
      </Dialog>

      <Dialog
        heading={t('action.changeEmail')}
        isOpen={showChangeEmailPopup}
        maxWidth="xs"
        onClose={handleClosePopup}
      >
        <InviteUserPopup
          type={'change'}
          onClose={handleClosePopup}
          handleInvite={handleInvite}
          initialValues={{ email: selectedUser?.email ?? '' }}
        />
      </Dialog>
    </>
  );
};

export { UsersPopup };
