import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Box, Button } from '@mui/material';

const InviteUserPopup = ({ onClose, handleInvite, type, initialValues }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('formErrors.wrongEmailFormat')).required(t('formErrors.required')),
  });

  const onSubmit = async (values) => {
    handleInvite(values, type);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ isSubmitting }) => (
        <Form noValidate autoComplete="none">
          <Field
            component={TextField}
            label={t('formLabel.email')}
            name="email"
            placeholder={t('placeholders.email')}
            type="text"
          />
          <Box display="flex" mt={1} justifyContent="flex-end">
            <Button disabled={isSubmitting} onClick={onClose} type="button" variant="outlined">
              {t('action.cancel')}
            </Button>

            <Box mx={1} />

            <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
              {t('action.submit')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export { InviteUserPopup };
