import { createTheme, lighten, darken } from '@mui/material/styles';

const laptopL = 1441;
const laptop = 1024;
const breakpoints = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1920,
      laptopL,
      laptop,
    },
  },
};

const theme = createTheme({
  ...breakpoints,
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontFamily: 'Roboto',
    h1: {
      color: '#344054',
      fontSize: '4rem',
      fontWeight: 600,
    },
    h2: {
      color: '#344054',
      fontSize: '3rem',
      fontWeight: 600,
    },
    h3: {
      color: '#344054',
      fontSize: '2.5rem',
      fontWeight: 500,
      lineHeight: '130%',
    },
    subtitle1: {
      color: '#344054',
      fontSize: '1.8rem',
      fontWeight: 400,
    },
    subtitle2: {
      color: '#83879B',
      fontSize: '1.6rem',
      fontWeight: 500,
    },
    body1: {
      color: '#344054',
      fontSize: '1.6rem',
      fontWeight: 400,
    },
    body2: {
      color: '#83879B',
      fontSize: '1.4rem',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#0046FF',
    },
    secondary: { main: '#E8ECFE' },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          caption: {
            fontSize: '1.2rem',
            textTransform: 'uppercase',
            letterSpacing: '0.02em',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          cursor: 'pointer',
          textTransform: 'none',
          width: 'auto',
          height: '48px',
          fontSize: '1.6rem',
          fontWeight: 500,
          paddingBlockStart: '10px',
          paddingBlockEnd: '10px',
          paddingInlineStart: '24px',
          paddingInlineEnd: '24px',
        },
        containedSizeLarge: {
          width: '164px',
        },
        containedSizeSmall: {
          width: '104px',
          height: '40px',
        },
        containedPrimary: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: lighten('#1B45F5', 0.2),
          },
        },
        containedSecondary: {
          boxShadow: 'none',
          color: '#0046FF',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: darken('#E8ECFE', 0.2),
          },
        },
        containedError: {
          boxShadow: 'none',
          color: '#fff',
          background: '#FF7077',

          '&:hover': {
            boxShadow: 'none',
            backgroundColor: darken('#FF7077', 0.2),
          },
        },
        outlinedPrimary: {
          borderColor: '#E6E8EC',
          color: '#344054',
          '&:hover': {
            boxShadow: 'none',
            borderColor: '#344054',
            backgroundColor: 'inherit',
          },
        },
        outlinedSizeSmall: {
          width: '104px',
        },
        text: {
          paddingBlockStart: '10px',
          paddingBlockEnd: '10px',
          paddingInlineStart: '24px',
          paddingInlineEnd: '24px',
          color: '#1f1f1f',
        },
      },
      defaultProps: {
        size: 'large',
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: '170px',
          paddingBlockStart: '0px',
          paddingBlockEnd: '0px',
          marginTop: '8px',
          border: 'none',
          boxShadow: '0px 2px 12px rgba(42, 16, 69, 0.15)',
          borderRadius: 8,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '9px 16px',
          fontSize: '1.4rem',
          '&[aria-selected="true"]:hover': {
            color: '#3851ff',
          },
          '&:hover': {
            color: '#6B7EFF',
          },
          '&$selected': {
            color: '#3851ff',
            background: 'white',
            '&:hover': {
              background: 'white',
            },
          },
          fontWeight: 700,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#E6E8EC',
          opacity: '0.4',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: 'inherit',

          '&::before': {
            display: 'none',
          },

          '&.Mui-expanded': {
            margin: '0',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '15px 10px 15px 0',
          minHeight: '0',

          '&.Mui-expanded': {
            minHeight: '0',
            paddingBottom: '0',
          },
        },
        content: {
          margin: '0',

          '&.Mui-expanded': {
            margin: '0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '10px 0 15px 0',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginBottom: '10px',
        },
      },
      defaultProps: {
        margin: 'dense',
        fullWidth: true,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '0 10px',
        },
        paper: {
          boxShadow: '0px 2px 12px rgba(42, 16, 69, 0.15)',
          borderRadius: 8,
        },
        popper: {
          background: 'white',
          borderRadius: 8,
        },
        popupIndicator: {
          '&:hover': {
            background: 'transparent',
          },
        },
        clearIndicator: {
          marginRight: '2px',
        },
        option: {
          fontSize: '1.4rem',
          color: 'rgba(0, 0, 0, 0.87)',

          '&[aria-selected="true"]': {
            color: '#0046FF',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
            },
          },
        },
        tag: {
          borderRadius: '8px',
          background: 'rgba(230, 232, 236, 0.5)',
          marginRight: '5px',
        },
        endAdornment: {
          display: 'flex',
          alignItems: 'center',
          top: 'unset ',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before, &:hover:not(.Mui-disabled):before': {
            borderColor: '#E8EBF7',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          minHeight: '48px',
          height: 'auto',
          borderRadius: 8,

          '& .MuiInputAdornment-root': {
            marginRight: '2px',

            '& + input': {
              paddingLeft: '0',
            },
          },
        },
        input: {
          padding: '12px 16px',
          fontWeight: 400,
        },
        notchedOutline: {
          top: 0,
          borderWidth: '1px !important',
          borderColor: '#E6E8EC',
          borderRadius: 8,

          '& legend': {
            position: 'absolute',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          right: 9,
        },
        outlined: {
          paddingRight: 32,

          padding: '10px 13px',
        },
        select: {
          backgroundColor: '#fff',
          '&:focus': {
            backgroundColor: '#fff',
          },
        },
        icon: {
          display: 'flex',
          alignItems: 'center',
          top: 'unset ',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          color: '#83879B',
          fontWeight: 700,
          fontSize: '1.3rem',
          position: 'relative',
          marginBottom: '8px',
        },
        shrink: {
          transform: 'scale(1)',
        },
      },
      defaultProps: {
        shrink: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '10px 0',

          '& .MuiFormHelperText-root': {
            position: 'absolute',
            bottom: '-20px',
          },

          '& fieldset': {
            transition: '.3s ease',
          },

          '& textarea': {
            padding: '0',
          },

          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
          },

          '&:hover:not(.Mui-checked)': {
            color: '#1B45F5',
            borderRadius: '50%',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 24px 10px 24px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: '12px 16px',
          border: 'none',
          borderBottom: '1px solid #E6E8EC',
          color: '#818B98',
          fontSize: '1.3rem',
          lineHeight: '1.6rem',
          fontWeight: 700,
        },
        root: {
          padding: '14px 12px',
          fontSize: '1.6rem',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          position: 'sticky',
          top: 0,
          background: 'white',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.4rem',
          whiteSpace: 'break-spaces',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          borderColor: '#E8EBF7',
          fontSize: '1.5rem',
          fontWeight: 600,
        },
        outlinedPrimary: {
          color: 'white',
          background: '#0046FF !important',
        },
        outlinedSecondary: {
          color: '#344054',
          boxShadow: 'none !important',

          '&:hover': {
            color: 'white',
            background: '#0046FF !important',
          },
        },
        filledSecondary: {
          color: '#344054',
          background: '#EFEFF1 !important',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: '40px',
          border: '1px solid #E8EBF7',
          borderRadius: '8px',
          padding: '3px',
          gap: '3px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          textTransform: 'none',
          borderRadius: '8px !important',
          transition: '.2s ease-out',

          '&:hover': {
            background: 'none',
          },

          '&.Mui-selected': {
            color: 'white',
            background: '#0046FF',

            '&:hover': {
              background: '#0046FF',
            },
          },
        },
      },
    },
  },
});

export { theme };
