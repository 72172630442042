import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { IconChevron } from 'components';

export const MuiAutocomplete = (props) => {
  const { label, placeholder, name, listAction, error, helperText, ...rest } = props;

  return (
    <Autocomplete
      PaperComponent={
        listAction
          ? (wrapper) => {
              return (
                <div className={wrapper.className}>
                  <Box
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {listAction()}
                  </Box>
                  {wrapper.children}
                </div>
              );
            }
          : undefined
      }
      ChipProps={{
        deleteIcon: (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4L4 12"
              stroke="#344054"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 4L12 12"
              stroke="#344054"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
        style: { fontWeight: 500 },
      }}
      popupIcon={<IconChevron />}
      getOptionLabel={(option: any) => option?.title}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          name={name}
          error={error}
          helperText={helperText}
        />
      )}
      {...rest}
    />
  );
};
