import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  MenuItem,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { IconStar, StatusChip, TableAction, Tooltip } from 'components';
import { ACTIVE_STATUS, INACTIVE_STATUS, PENDING_STATUS, STATUSES } from 'utils';

export const Table = ({
  data,
  profileId,
  ownerId,
  handleResend,
  handleEditEmail,
  handleChangeStatus,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TableContainer style={{ minHeight: '300px', overflowX: 'initial' }}>
        <MuiTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('tableHeader.name')}</TableCell>
              <TableCell>{t('tableHeader.email')}</TableCell>
              <TableCell>{t('tableHeader.status')}</TableCell>
              <TableCell className="table-action" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <Box display="flex" alignItems="center" gap="5px">
                    <span style={{ fontWeight: 500 }}>
                      {user.firstName && user.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : 'N/A'}
                    </span>
                    {ownerId === user.id && (
                      <Tooltip
                        disableMargin
                        title={t('directory.primaryContact')}
                        triggerRenderer={() => <IconStar />}
                      />
                    )}
                    {profileId === user.id && <span>{`(me)`}</span>}
                  </Box>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <StatusChip status={STATUSES[user.status.id]} />
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {profileId !== user.id && ownerId !== user.id && (
                    <TableAction>
                      {() => {
                        const actionsList = [
                          <MenuItem
                            key={`update-${user.id}`}
                            onClick={() => [handleEditEmail(user)]}
                          >
                            {t('action.changeEmail')}
                          </MenuItem>,
                          user.status.id === PENDING_STATUS && (
                            <MenuItem
                              key={`resend-${user.id}`}
                              onClick={() => [handleResend(user.id)]}
                            >
                              {t('action.resendEmail')}
                            </MenuItem>
                          ),
                          user.status.id === ACTIVE_STATUS && (
                            <MenuItem
                              key={`archive-${user.id}`}
                              onClick={() => [handleChangeStatus(user, INACTIVE_STATUS)]}
                            >
                              {t('action.archive')}
                            </MenuItem>
                          ),
                          user.status.id === INACTIVE_STATUS && (
                            <MenuItem
                              key={`restore-${user.id}`}
                              onClick={() => [handleChangeStatus(user, ACTIVE_STATUS)]}
                            >
                              {t('action.restore')}
                            </MenuItem>
                          ),
                        ];

                        return actionsList.filter(Boolean);
                      }}
                    </TableAction>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
};
