import React from 'react';

export function IconDots() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00101 11.9628C9.81921 11.9628 10.4825 12.6261 10.4825 13.4443C10.4825 14.2625 9.81921 14.9258 9.00101 14.9258C8.18281 14.9258 7.51953 14.2625 7.51953 13.4443C7.51953 12.6261 8.18281 11.9628 9.00101 11.9628Z"
        fill="#6F767E"
      />
      <path
        d="M9.00101 7.51799C9.81921 7.51799 10.4825 8.18128 10.4825 8.99948C10.4825 9.81767 9.81921 10.481 9.00101 10.481C8.18281 10.481 7.51953 9.81767 7.51953 8.99948C7.51953 8.18128 8.18281 7.51799 9.00101 7.51799Z"
        fill="#6F767E"
      />
      <path
        d="M9.00101 3.07366C9.81921 3.07366 10.4825 3.73694 10.4825 4.55514C10.4825 5.37334 9.81921 6.03662 9.00101 6.03662C8.18281 6.03662 7.51953 5.37334 7.51953 4.55514C7.51953 3.73694 8.18281 3.07366 9.00101 3.07366Z"
        fill="#6F767E"
      />
    </svg>
  );
}
