import React from 'react';

export function IconDirectoryMultiline() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 901.09 245.26"
      width="313"
      height="150px"
    >
      <defs>
        <clipPath id="clip-path" transform="translate(-213 -388.93)">
          <rect className="cls-1" x="213" y="388.93" width="901.09" height="245.26" />
        </clipPath>
      </defs>
      <g className="cls-2">
        <path
          className="cls-3"
          d="M280.67,618.44H274v-39h6.66Zm-7.21-49.33a4,4,0,0,1,1-2.74,3.74,3.74,0,0,1,2.94-1.11,3.8,3.8,0,0,1,3,1.11,4,4,0,0,1,1,2.74,3.59,3.59,0,0,1-4,3.79,3.79,3.79,0,0,1-2.94-1.08,3.89,3.89,0,0,1-1-2.71"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M297.64,579.45l.22,4.91a14.21,14.21,0,0,1,11.67-5.63q12.36,0,12.47,13.95v25.76h-6.66v-25.8q0-4.21-1.93-6.23c-1.26-1.35-3.23-2-5.89-2a9.57,9.57,0,0,0-5.7,1.73,11.84,11.84,0,0,0-3.82,4.54v27.78h-6.66v-39Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M354.76,608.1a4.91,4.91,0,0,0-2-4.2c-1.36-1-3.72-1.85-7.1-2.57a33.46,33.46,0,0,1-8-2.6,11.5,11.5,0,0,1-4.4-3.6,8.61,8.61,0,0,1-1.42-5,10.11,10.11,0,0,1,4.05-8.1,15.84,15.84,0,0,1,10.36-3.32q6.63,0,10.76,3.43a10.86,10.86,0,0,1,4.12,8.75h-6.7A6,6,0,0,0,352,586.2a8.67,8.67,0,0,0-5.86-2,9.1,9.1,0,0,0-5.69,1.59,5,5,0,0,0-2.05,4.14,4,4,0,0,0,1.9,3.64,23.42,23.42,0,0,0,6.9,2.34,35.71,35.71,0,0,1,8.09,2.67,11.73,11.73,0,0,1,4.6,3.73,9.12,9.12,0,0,1,1.49,5.31,10,10,0,0,1-4.17,8.38q-4.19,3.15-10.85,3.15a19.72,19.72,0,0,1-8.29-1.65,13.56,13.56,0,0,1-5.64-4.63,11.24,11.24,0,0,1-2-6.44h6.66a6.9,6.9,0,0,0,2.68,5.32,10.45,10.45,0,0,0,6.62,2,10.77,10.77,0,0,0,6.07-1.53,4.71,4.71,0,0,0,2.29-4.09"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M379.15,570v9.44h7.28v5.16h-7.28v24.2a5.5,5.5,0,0,0,1,3.53,4.11,4.11,0,0,0,3.32,1.17,16.69,16.69,0,0,0,3.17-.43v5.37a19.71,19.71,0,0,1-5.11.71q-4.47,0-6.74-2.7c-1.52-1.8-2.27-4.36-2.27-7.67V584.61h-7.1v-5.16h7.1V570Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M407.22,613.36a11.73,11.73,0,0,0,5.95-1.62,9.8,9.8,0,0,0,4.07-4.21v-8H412q-12.25,0-12.26,7.18a6,6,0,0,0,2.1,4.89,8,8,0,0,0,5.36,1.77m11.07,5.08a13.82,13.82,0,0,1-.94-4.11,14.84,14.84,0,0,1-11.1,4.83,13.74,13.74,0,0,1-9.46-3.26,10.54,10.54,0,0,1-3.69-8.26,11,11,0,0,1,4.63-9.46c3.08-2.25,7.43-3.37,13-3.37h6.49v-3.07a7.48,7.48,0,0,0-2.09-5.56c-1.39-1.39-3.45-2.08-6.16-2.08a9.72,9.72,0,0,0-6,1.81,5.3,5.3,0,0,0-2.42,4.35h-6.7a9.23,9.23,0,0,1,2.07-5.64,14.29,14.29,0,0,1,5.62-4.3,18.9,18.9,0,0,1,7.81-1.59q6.74,0,10.56,3.37a12.2,12.2,0,0,1,4,9.28v18a21.82,21.82,0,0,0,1.37,8.54v.57Z"
          transform="translate(-213 -388.93)"
        />
      </g>
      <rect className="cls-3" x="221.93" y="174.16" width="6.66" height="55.35" />
      <rect className="cls-3" x="239.84" y="174.16" width="6.66" height="55.35" />
      <g className="cls-2">
        <path
          className="cls-3"
          d="M485.53,584.21a8.89,8.89,0,0,0-6.78,2.94,14.36,14.36,0,0,0-3.39,8.23H494.9v-.5c-.2-3.39-1.11-6-2.74-7.88a8.39,8.39,0,0,0-6.63-2.79m.83,34.95a17.06,17.06,0,0,1-12.91-5.2q-5-5.21-5-13.93v-1.22a23.4,23.4,0,0,1,2.22-10.37,17.33,17.33,0,0,1,6.2-7.13,15.55,15.55,0,0,1,8.63-2.58c5.07,0,9,1.67,11.82,5s4.21,8.12,4.21,14.35v2.77H475.15a13.91,13.91,0,0,0,3.37,9.32,10.59,10.59,0,0,0,8.19,3.54,11.65,11.65,0,0,0,6-1.44,14.9,14.9,0,0,0,4.28-3.82l4.08,3.17q-4.9,7.54-14.7,7.53"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M528.12,585.44a19.74,19.74,0,0,0-3.28-.26q-6.55,0-8.9,5.59v27.67h-6.66v-39h6.48l.11,4.51a10.38,10.38,0,0,1,9.29-5.23,6.75,6.75,0,0,1,3,.51Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M557.56,599.38q0,6.63,2.74,10.38a8.85,8.85,0,0,0,7.57,3.75,9.73,9.73,0,0,0,9.26-5.7v-17.9a9.81,9.81,0,0,0-9.19-5.52,8.87,8.87,0,0,0-7.64,3.79q-2.74,3.78-2.74,11.2m-6.67-.75q0-9,4.25-14.44a14.49,14.49,0,0,1,22-.77V563.1h6.66v55.35h-6.13l-.32-4.19c-2.66,3.27-6.38,4.9-11.13,4.9a13.33,13.33,0,0,1-11.05-5.55q-4.26-5.54-4.27-14.48Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M601.38,618.44h-6.67v-39h6.67Zm-7.21-49.33a4,4,0,0,1,1-2.74,4.46,4.46,0,0,1,5.89,0,3.92,3.92,0,0,1,1,2.74,4.13,4.13,0,0,1-6.9,2.71,3.89,3.89,0,0,1-1-2.71"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M630.89,585.44a19.74,19.74,0,0,0-3.28-.26q-6.55,0-8.9,5.59v27.67H612v-39h6.49l.11,4.51a10.38,10.38,0,0,1,9.29-5.23,6.75,6.75,0,0,1,3,.51Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M651.68,584.21a8.87,8.87,0,0,0-6.77,2.94,14.23,14.23,0,0,0-3.39,8.23h19.53v-.5a12.74,12.74,0,0,0-2.74-7.88,8.37,8.37,0,0,0-6.63-2.79m.83,34.95a17,17,0,0,1-12.9-5.2q-5-5.21-5-13.93v-1.22a23.54,23.54,0,0,1,2.21-10.37,17.4,17.4,0,0,1,6.2-7.13,15.55,15.55,0,0,1,8.63-2.58q7.61,0,11.82,5t4.22,14.35v2.77H641.3a13.91,13.91,0,0,0,3.37,9.32,10.6,10.6,0,0,0,8.2,3.54,11.61,11.61,0,0,0,6-1.44,15.07,15.07,0,0,0,4.29-3.82l4.07,3.17q-4.9,7.54-14.7,7.53"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M691.07,613.72a9.6,9.6,0,0,0,6.23-2.16,7.58,7.58,0,0,0,3-5.4h6.31a12.1,12.1,0,0,1-2.31,6.38,15.13,15.13,0,0,1-5.67,4.82,16.39,16.39,0,0,1-7.51,1.8q-8,0-12.67-5.31t-4.7-14.54v-1.12a23.47,23.47,0,0,1,2.09-10.12,15.82,15.82,0,0,1,6-6.88,17,17,0,0,1,9.24-2.45,15.62,15.62,0,0,1,10.9,3.92,14.05,14.05,0,0,1,4.63,10.2h-6.31a9.19,9.19,0,0,0-2.86-6.22,8.92,8.92,0,0,0-6.36-2.43,9.32,9.32,0,0,0-7.87,3.66q-2.79,3.66-2.8,10.57v1.27q0,6.74,2.78,10.37a9.36,9.36,0,0,0,7.93,3.64"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M723.1,570v9.44h7.28v5.16H723.1v24.2a5.5,5.5,0,0,0,1,3.53,4.11,4.11,0,0,0,3.32,1.17,16.79,16.79,0,0,0,3.17-.43v5.37a19.73,19.73,0,0,1-5.12.71c-3,0-5.22-.9-6.74-2.7s-2.26-4.36-2.26-7.67V584.61h-7.1v-5.16h7.1V570Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M742.38,599.38q0,6.48,3,10.42a10.21,10.21,0,0,0,16.13-.06q3-4,3-11.15,0-6.42-3-10.4a9.69,9.69,0,0,0-8.09-4,9.56,9.56,0,0,0-8,3.93q-3,3.93-3,11.24m-6.7-.79a23.06,23.06,0,0,1,2.25-10.31,16.79,16.79,0,0,1,6.27-7.06,17,17,0,0,1,9.17-2.48,16.42,16.42,0,0,1,12.88,5.51q4.92,5.5,4.92,14.66v.47A23.22,23.22,0,0,1,769,609.59a16.51,16.51,0,0,1-6.23,7,17.31,17.31,0,0,1-9.32,2.52,16.37,16.37,0,0,1-12.84-5.51q-4.92-5.5-4.92-14.59Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M798.38,585.44a19.83,19.83,0,0,0-3.28-.26q-6.55,0-8.9,5.59v27.67h-6.67v-39H786l.11,4.51a10.38,10.38,0,0,1,9.29-5.23,6.78,6.78,0,0,1,3,.51Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M817.91,608.68,827,579.46h7.13l-15.67,45q-3.65,9.74-11.57,9.73l-1.26-.11-2.49-.46v-5.41l1.81.14a8.86,8.86,0,0,0,5.28-1.37,9.91,9.91,0,0,0,3.11-5l1.48-4L800.9,579.46h7.28Z"
          transform="translate(-213 -388.93)"
        />
      </g>
      <polygon
        className="cls-3"
        points="21.69 176.82 0 233.21 5.67 233.21 27.33 176.82 21.69 176.82"
      />
      <g className="cls-2">
        <path
          className="cls-3"
          d="M247.68,454.33a44.47,44.47,0,0,1-9-2.89,9.68,9.68,0,0,1-4.15-3.24,7.21,7.21,0,0,1-1-3.75,6.91,6.91,0,0,1,3-5.77q3-2.24,8.51-2.23a23.06,23.06,0,0,1,5.95.71,14,14,0,0,1,4.58,2.17l1.47,6.75a4.42,4.42,0,0,0,4.33,3.49h11l-.21-21.34a48,48,0,0,0-12.3-4.44,72,72,0,0,0-16.11-1.69q-10.51,0-17.27,3.24a23.23,23.23,0,0,0-10,8.58,22.21,22.21,0,0,0-3.28,11.83,20.14,20.14,0,0,0,6.56,15.49q6.57,6.06,20.41,8.95,9.15,1.86,12.22,4.36a7.24,7.24,0,0,1,3.06,5.73,7.11,7.11,0,0,1-2.88,5.77q-2.88,2.31-9.95,2.31a28,28,0,0,1-6.67-.73,25.44,25.44,0,0,1-5.37-1.95l-2-7.89a4.44,4.44,0,0,0-4.3-3.35H213l.22,22.06a75.06,75.06,0,0,0,14.45,4.76,76,76,0,0,0,16.26,1.66q15.7,0,23.61-6.71t7.9-17.59q0-9.94-6.64-15.46t-21.12-8.83"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M316.74,488l-6.59-1.4V423.4h-32.3v9a4.43,4.43,0,0,0,3.59,4.35l7.66,1.47v48.38L282.44,488a4.43,4.43,0,0,0-3.51,4.33v9h41.31v-9a4.44,4.44,0,0,0-3.5-4.34"
          transform="translate(-213 -388.93)"
        />
      </g>
      <rect className="cls-3" x="75.38" width="21.78" height="17.95" />
      <g className="cls-2">
        <path
          className="cls-3"
          d="M371,479.63a13.7,13.7,0,0,1-5.27,5.15,15.85,15.85,0,0,1-7.71,1.77q-8.22,0-11.75-5.88t-3.53-16.11v-1.51a47.33,47.33,0,0,1,1.58-12.94,18.4,18.4,0,0,1,5-8.65,12.44,12.44,0,0,1,8.79-3.14A14.22,14.22,0,0,1,371,445.75Zm9.3-56.23h-2.69a4.42,4.42,0,0,0-4.38,3.75l-.93,6.05a25.59,25.59,0,0,0-8.58-8.33,23.73,23.73,0,0,0-12-2.92,25.59,25.59,0,0,0-16.08,5.16,32.56,32.56,0,0,0-10.31,14.38,59.17,59.17,0,0,0-3.6,21.56v1.51a52,52,0,0,0,3.6,20.11,30,30,0,0,0,10.45,13.45,27.8,27.8,0,0,0,16.37,4.8,24,24,0,0,0,10.74-2.31A23.57,23.57,0,0,0,371,493.9V499q0,8.37-3.93,13.05t-12.36,4.69a48.87,48.87,0,0,1-10.57-1.26q-3.25-.74-6.31-1.75a4.4,4.4,0,0,0-5.63,3.1l-2.65,10.22a52.79,52.79,0,0,0,13,4.32,66.34,66.34,0,0,0,13.26,1.52q11.33,0,19.47-4.18A29.8,29.8,0,0,0,387.74,517a35.2,35.2,0,0,0,4.36-17.88V438l5.66-1.21a4.42,4.42,0,0,0,3.5-4.33v-9.1h-21Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M491.62,488l-6.59-1.4v-34q0-15.87-6.56-23.29T460.3,422a25.64,25.64,0,0,0-13.08,3.32,28.46,28.46,0,0,0-9.63,9.3l-.59-7.12a4.44,4.44,0,0,0-4.42-4.05H405.87v9a4.43,4.43,0,0,0,3.59,4.35l7.59,1.47v48.38l-6.6,1.4a4.44,4.44,0,0,0-3.5,4.34v9h39.66v-9.17a4.43,4.43,0,0,0-3.34-4.29l-5.17-1.31V445.68a15.87,15.87,0,0,1,5.58-5.45,15.64,15.64,0,0,1,7.9-1.91q6.14,0,9.23,3.28t3.1,11.14v33.89L458.29,488a4.43,4.43,0,0,0-3.39,4.31v9.12h40.23v-9a4.45,4.45,0,0,0-3.51-4.34"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M545.67,480.13a15.4,15.4,0,0,1-6.12,5.23,18.53,18.53,0,0,1-8.36,2q-4.7,0-7.32-2.28a8,8,0,0,1-2.63-6.45A9.85,9.85,0,0,1,524.8,471q3.57-3.18,10.49-3.17h10.38Zm25.68,8.12-4.33-.62a23.92,23.92,0,0,1-.26-3c0-1,0-2.08,0-3.13V449.35q0-13.2-8.86-20.29T533.71,422a54.46,54.46,0,0,0-15.93,2.17,65.11,65.11,0,0,0-13.27,5.62L504.8,450h11.36a4.44,4.44,0,0,0,4.38-3.73l1.05-6.58a17.29,17.29,0,0,1,4.87-2.34,20.81,20.81,0,0,1,6.09-.83q6.57,0,9.84,3.49a12.79,12.79,0,0,1,3.28,9.12v5.77h-10.3q-11.48,0-19.29,3.18a26.25,26.25,0,0,0-11.82,8.72,21.46,21.46,0,0,0-4,12.9q0,10.76,6.85,17t18.45,6.23a24,24,0,0,0,11.93-2.84,29.64,29.64,0,0,0,8.83-7.61,28.88,28.88,0,0,0,1.15,4.62c.17.46.33.91.49,1.36a4.44,4.44,0,0,0,4.18,3h23v-8.78a4.42,4.42,0,0,0-3.81-4.38"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M627.24,479.63a13.7,13.7,0,0,1-5.27,5.15,15.85,15.85,0,0,1-7.71,1.77q-8.22,0-11.75-5.88T599,464.56v-1.51a46.94,46.94,0,0,1,1.58-12.94,18.4,18.4,0,0,1,5-8.65,12.44,12.44,0,0,1,8.79-3.14,14.22,14.22,0,0,1,12.91,7.43Zm9.3-56.23h-2.69a4.42,4.42,0,0,0-4.38,3.75l-.94,6.05a25.47,25.47,0,0,0-8.57-8.33,23.73,23.73,0,0,0-12-2.92,25.59,25.59,0,0,0-16.08,5.16,32.56,32.56,0,0,0-10.31,14.38,59,59,0,0,0-3.6,21.56v1.51a51.83,51.83,0,0,0,3.6,20.11A29.93,29.93,0,0,0,592,498.12a27.8,27.8,0,0,0,16.37,4.8,24,24,0,0,0,10.74-2.31,23.57,23.57,0,0,0,8.15-6.71V499q0,8.37-3.93,13.05t-12.37,4.69a48.79,48.79,0,0,1-10.56-1.26q-3.25-.74-6.31-1.75a4.4,4.4,0,0,0-5.63,3.1l-2.66,10.22a53,53,0,0,0,13,4.32A66.34,66.34,0,0,0,612,532.91q11.33,0,19.47-4.18A29.8,29.8,0,0,0,644,517a35.2,35.2,0,0,0,4.36-17.88V438l5.65-1.21a4.42,4.42,0,0,0,3.51-4.33v-9.1h-21Z"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M705.83,454.76h-26l-.22-.36a31.49,31.49,0,0,1,2.2-8.65,14.53,14.53,0,0,1,4.5-6,11.35,11.35,0,0,1,7.07-2.19,12.56,12.56,0,0,1,7.17,1.87,10.63,10.63,0,0,1,4,5.3,23.72,23.72,0,0,1,1.26,8.18Zm5-28.62Q703.6,422,693,422A33,33,0,0,0,674.36,427a34.12,34.12,0,0,0-12.22,14.13,46.87,46.87,0,0,0-4.32,20.58v2.88a41.62,41.62,0,0,0,4.57,19.76A33.87,33.87,0,0,0,675.33,498a37.75,37.75,0,0,0,19.54,4.94,50.75,50.75,0,0,0,16.76-2.56,40.49,40.49,0,0,0,12.15-6.46L720,485.32a4.39,4.39,0,0,0-5.86-2.27,39.94,39.94,0,0,1-5.83,2.24,38.21,38.21,0,0,1-10.78,1.48,18.46,18.46,0,0,1-9.44-2.27,15.55,15.55,0,0,1-6-6.28,23.44,23.44,0,0,1-2.56-9.11l.14-.44h41.42a4.43,4.43,0,0,0,4.43-4.43v-7q0-11.18-3.75-19a26.92,26.92,0,0,0-11-12"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M774.06,485.79a30.84,30.84,0,0,1-7.64,1A13.67,13.67,0,0,1,756,482.62a14.62,14.62,0,0,1-4-10.56,17.59,17.59,0,0,1,1.33-6.6,20.07,20.07,0,0,1,5-6.74l1.37-.93,21.63,25.09a30.85,30.85,0,0,1-7.35,2.91m-12.48-71.51a8.72,8.72,0,0,1,7.22-3.14,9,9,0,0,1,6.88,2.81,9.11,9.11,0,0,1,2.63,6.42,13,13,0,0,1-1.4,6,15,15,0,0,1-5.45,5.34l-6.2,4a40,40,0,0,1-4.65-7.24,14.93,14.93,0,0,1-1.55-6.38,12.09,12.09,0,0,1,2.52-7.82m51.84,54.32a64.72,64.72,0,0,0,2.45-18.31h-11.5a4.4,4.4,0,0,0-4.4,3.94,37.92,37.92,0,0,1-1.25,6.73,39.42,39.42,0,0,1-4,9.37l-19.9-23.21,6.7-4.76a34.83,34.83,0,0,0,9.67-10.24,24.48,24.48,0,0,0,3.53-12.91A23.46,23.46,0,0,0,791.4,407a24.37,24.37,0,0,0-9.09-8.76,26.82,26.82,0,0,0-13.44-3.28,32,32,0,0,0-15.11,3.39,24.25,24.25,0,0,0-10,9.52,28.43,28.43,0,0,0-3.53,14.48,27.45,27.45,0,0,0,2.41,11.18A65.06,65.06,0,0,0,750,445.6a8.42,8.42,0,0,0-.76.51c-.26.19-.52.38-.75.57Q739.67,453,735.35,459A23.62,23.62,0,0,0,731,473.14a28.61,28.61,0,0,0,4.22,15.58,28.26,28.26,0,0,0,11.93,10.44,41,41,0,0,0,18.17,3.76,47.1,47.1,0,0,0,13.91-2A50.24,50.24,0,0,0,791.87,495l5.55,6.41h23.35l.15-.36-15-17.59a44.07,44.07,0,0,0,7.5-14.85"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M865.3,486.05q-9.79,0-13.62-7.72V446.4q3.68-7.5,13.48-7.5a12.56,12.56,0,0,1,11.21,5.91q3.86,5.91,3.86,16.8,0,24.43-14.93,24.44m5.34-64.1A24.49,24.49,0,0,0,850.88,431l-.34-3.63a4.42,4.42,0,0,0-4.41-4H830.84v108h20.84V494.62q7.27,8.24,19.1,8.22,13.92,0,22.1-10.85t8.18-28.87V462q0-18.74-8-29.42T870.64,422"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M929.62,432.7l-.32-5.15a4.43,4.43,0,0,0-4.42-4.15H909.36v78H930.2v-51q3.67-8,15.5-8c.88,0,1.81,0,2.8.09a4.46,4.46,0,0,0,4.77-4.35l.22-15.21A22.06,22.06,0,0,0,947,422q-11.09,0-17.37,10.75"
          transform="translate(-213 -388.93)"
        />
      </g>
      <rect className="cls-3" x="748.35" width="21.77" height="17.95" />
      <rect className="cls-3" x="748.79" y="34.46" width="20.91" height="78.01" />
      <g className="cls-2">
        <path
          className="cls-3"
          d="M1037.92,422q-14.06,0-22.43,10.46l-.35-4.9a4.42,4.42,0,0,0-4.42-4.11H995.24v78h20.83v-55a14.33,14.33,0,0,1,13.19-7.5q6.71,0,9.59,3t2.89,9.62V501.4h20.83V451q-.13-14.49-6.2-21.77T1037.92,422"
          transform="translate(-213 -388.93)"
        />
        <path
          className="cls-3"
          d="M1108,485.68c-3.13,0-5.24-.62-6.35-1.87s-1.65-3.31-1.65-6.2V438.68h13.33V423.4H1100V404.22h-16.41a4.43,4.43,0,0,0-4.43,4.43V423.4h-11.39v10.85a4.43,4.43,0,0,0,4.43,4.43h7v42q.44,22.14,22.35,22.14a42.91,42.91,0,0,0,12.62-1.88V485.18a33,33,0,0,1-6.13.5"
          transform="translate(-213 -388.93)"
        />
      </g>
    </svg>
  );
}
