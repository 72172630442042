import React, { MutableRefObject, ReactElement } from 'react';

import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SxProps,
  Typography,
} from '@mui/material';
import { IconClose } from 'components';

import styles from './dialog.module.scss';

interface IProps {
  actions?: ReactElement;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
  heading?: string | ReactElement;
  ref?: MutableRefObject<HTMLDivElement | null>;
  sx?: SxProps;
  isOpen: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
  className?: string;
  children?: ReactElement;
  customWidth?: string;
}

const Dialog: React.FunctionComponent<IProps> = ({
  actions,
  maxWidth = 'xs',
  heading = '',
  ref,
  isOpen = false,
  onClose = () => null,
  children,
  hideCloseButton,
  className,
  sx = {},
  customWidth,
}) => {
  return (
    <MuiDialog
      maxWidth={maxWidth}
      open={isOpen}
      className={`${styles.background} ${className}`}
      onClose={onClose}
      ref={ref}
      PaperProps={{
        className: styles.paper,
        style: { maxWidth: customWidth },
      }}
      sx={sx}
    >
      <DialogTitle className={styles.header}>
        <IconButton
          className={styles.close}
          color="primary"
          onClick={onClose}
          style={{ display: hideCloseButton ? 'none' : 'inline-flex', position: 'absolute' }}
        >
          <IconClose />
        </IconButton>
        <Typography component="span" variant="h2">
          {heading}
        </Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
};

export { Dialog };
