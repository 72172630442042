import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Container from './Container';
import { Box, Button, Typography } from '@mui/material';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';

import { routes } from 'config';
import { ISignIn } from 'interfaces';
import { signIn } from 'redux/actions';
import { useTypedDispatch } from 'redux/reducers';

import styles from './auth.module.scss';

const SignIn: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('formErrors.wrongEmailFormat'))
      .test('email', t('formErrors.forbiddenDomain'), (value) => {
        const restrictedDomains = [
          '@mersongroup.com',
          '@merson-signs.com',
          '@fespauk.com',
          '@bannerbox.co.uk',
          'rmclimited.co.uk',
        ];

        if (value && restrictedDomains.some((item) => value.endsWith(item))) {
          return false;
        }
        return true;
      })
      .required(t('formErrors.required'))
      .nullable(),
    password: Yup.string().required(t('formErrors.required')),
  });

  const onSubmit = async (values: ISignIn) => {
    try {
      await dispatch(signIn(values));
    } catch (e) {
      console.log(e);
    }
  };

  const FormContext = () => {
    const { submitForm } = useFormikContext();

    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          submitForm();
        }
      };

      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }, []);

    return null;
  };

  return (
    <Container>
      <Box pb={3}>
        <Typography component="h1" variant="h1">
          {t('auth.login')}
        </Typography>

        <Box pt={1}>
          <Typography variant="body1">
            {t('auth.dontHaveAccount')}
            <Link to={routes.getStarted} className="is-link">
              {t('action.signUp')}
            </Link>
          </Typography>
          <Box mt={1} />
          <Typography variant="body2">{t('auth.hadAccount')}</Typography>
        </Box>
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { isSubmitting } = props;

          return (
            <>
              <Form className={styles.form} noValidate autoComplete="off">
                <Field
                  autoComplete="none"
                  component={TextField}
                  label={t('formLabel.email')}
                  name="email"
                  placeholder={t('placeholders.email')}
                  type="email"
                />
                <Field
                  autoComplete="none"
                  component={TextField}
                  label={t('formLabel.password')}
                  name="password"
                  placeholder={t('placeholders.password')}
                  type="password"
                />

                <Box ml="auto">
                  <Typography variant="body2">
                    <Link to={routes.forgotPassword} className="is-link">
                      {t('auth.forgotPassword')}
                    </Link>
                  </Typography>
                </Box>

                <Box mt={4} ml="auto">
                  <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                    {t('action.login')}
                  </Button>
                </Box>
                <FormContext />
              </Form>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export { SignIn };
