import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import * as R from 'ramda';

import { routes } from 'config';

type IProps = RouteProps;

const ProtectedRoute: React.FunctionComponent<IProps> = ({ children, ...rest }) => {
  const location = useLocation();

  const getProperPath = () => {
    const isIncludes = R.includes(location.pathname, [routes.signIn]);
    return isIncludes ? location.pathname : '/';
  };

  const hasToken = localStorage.getItem('token');

  if (!hasToken) {
    localStorage.setItem('pathType', location.pathname);
  }

  return hasToken ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect
      from=""
      to={{
        pathname: getProperPath(),
      }}
    />
  );
};

export { ProtectedRoute };
