import React from 'react';
import classnames from 'classnames';

import { Box } from '@mui/material';
import { IconLogoCom } from 'components';
import HeroImg from './images/hero_image.png';

import styles from './auth.module.scss';

type IProps = {
  children?: JSX.Element | JSX.Element[];
};

function Container({ children }: IProps) {
  return (
    <section className={styles.container}>
      <aside className={classnames(styles.aside, styles.left)}>
        <Box display="flex" flexDirection="column" borderRadius={16} className={styles.paper}>
          {children}
        </Box>
      </aside>
      <aside className={classnames(styles.aside, styles.right)}>
        <Box className={styles.box}>
          <img className={styles.heroImg} src={HeroImg} alt="hero img" />
          <Box className={styles.heroText}>
            <IconLogoCom />
          </Box>
        </Box>
      </aside>
    </section>
  );
}

export default Container;
