import { setProfile } from './auth';
import {
  createCompanyAPI,
  getCompaniesAPI,
  getCompanyAPI,
  getCompanyOptionsAPI,
  getPlansAPI,
  getUsersAPI,
  updateCompanyAPI,
} from 'api';
import { ICompany, ICompanyRequest, ICompanyState } from 'interfaces';
import {
  CLEAR_COMPANY_STATE,
  SET_COMPANIES_LIST,
  SET_COMPANY_OPTIONS,
  SET_LOADING,
  SET_SUBSCRIPTION,
  SET_USERS,
} from '../types';

export const setCompanyOptions = (payload: ICompanyState) => ({
  type: SET_COMPANY_OPTIONS,
  payload,
});

export const setCompaniesList = (payload: ICompany[]) => ({
  type: SET_COMPANIES_LIST,
  payload,
});

export const setLoading = (payload: boolean) => ({
  type: SET_LOADING,
  payload,
});

export const clearCompanyState = () => ({
  type: CLEAR_COMPANY_STATE,
});

export const setSubscription = (payload) => ({
  type: SET_SUBSCRIPTION,
  payload,
});

export const setUsers = (payload) => ({
  type: SET_USERS,
  payload,
});

export const getCompany = (id: number | string) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await getCompanyAPI(id);

      const profile = JSON.parse(localStorage.getItem('profile') as string);
      profile.company = res;
      localStorage.setItem('profile', JSON.stringify(profile));
      dispatch(setProfile(profile));
    } catch (e: any) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const createCompany = (values: ICompanyRequest) => {
  return async (dispatch) => {
    try {
      const res = await createCompanyAPI(values);

      const profile = JSON.parse(localStorage.getItem('profile') as string);
      profile.company = res;
      localStorage.setItem('profile', JSON.stringify(profile));
      dispatch(setProfile(profile));

      return res;
    } catch (e: any) {
      console.log(e);
    }
  };
};

export const updateCompany = (id: number | string, values: ICompanyRequest) => {
  return async (dispatch) => {
    try {
      const res = await updateCompanyAPI(id, values);

      const profile = JSON.parse(localStorage.getItem('profile') as string);
      profile.company = res;
      localStorage.setItem('profile', JSON.stringify(profile));
      dispatch(setProfile(profile));

      return res;
    } catch (e: any) {
      console.log(e);
    }
  };
};

export const getCompanyOptions = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await getCompanyOptionsAPI();
      dispatch(setCompanyOptions(res));
    } catch (e: any) {
      throw new Error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getCompaniesList = () => {
  return async (dispatch) => {
    try {
      const res = await getCompaniesAPI();
      dispatch(setCompaniesList(res.companies));
    } catch (e: any) {
      throw new Error(e);
    }
  };
};

export const getPlans = () => {
  return async (dispatch) => {
    try {
      const res = await getPlansAPI();
      dispatch(setSubscription(res.plans[0]));
    } catch (e: any) {
      throw new Error(e);
    }
  };
};

export const getUsers = () => {
  return async (dispatch) => {
    try {
      const res = await getUsersAPI();
      dispatch(setUsers(res.users));
    } catch (e: any) {
      throw new Error(e);
    }
  };
};
