export const routes = {
  home: '/home',
  signIn: '/login',
  getStarted: '/get-started',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  notFound: '/not-found',
  verifyEmail: '/verify-email',
  installerDirectory: '/installer-directory',
  fullDirectory: '/full-directory',
  tickets: '/tickets',
  entry: '/enter',
  application: (categoryId = ':id') => `/enter/${categoryId}`,
};
