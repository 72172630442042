import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import './i18n';

import { Loading } from 'components';
import { font, store, theme } from 'config';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Suspense fallback={<Loading fullHeight={true} size={64} />}>
    <WebfontLoader config={font}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <App />
          </StylesProvider>
        </ThemeProvider>
      </Provider>
    </WebfontLoader>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
