import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  chip: {
    padding: '4px 10px',
    borderRadius: '8px',
    fontWeight: 700,
    width: 'fit-content',
  },
});

export const StatusChip = (props) => {
  const classes = useStyles();
  const { status } = props;

  const statusToClassName = (status ?? 'NA').split(' ').join('_').toLowerCase();

  return <Box className={`${classes.chip} ${statusToClassName}-chip`}>{status}</Box>;
};
