import React from 'react';
import { Header, SmoothImage } from 'components';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import standardRoom from './images/standardRoom.jpg';
import clubRoom from './images/clubRoom.jpg';
import table10 from './images/table10.jpg';
import table5 from './images/table5.jpg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    overflow: 'hidden',
    position: 'relative',
    marginBottom: '8px',
    display: 'block',
  },
  img: {
    width: '300px',
    height: '200px',
    transition: 'all 0.4s ease',

    '&.hotel': {
      filter: 'brightness(0.8)',
    },

    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  imgText: {
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '&&&': {
      color: 'white',
      fontWeight: 'bold',
      fontSize: '30px',
    },
  },
});

const tickets = [
  {
    name: 'Table of 10 - £1,650',
    link: 'https://book.stripe.com/cN215TaNfcHA7M414A',
    img: table10,
  },
  {
    name: 'Half table of 5 - £875',
    link: 'https://book.stripe.com/6oE7uhdZrePI6I014B',
    img: table5,
  },
];

const hotels = [
  {
    name: 'Standard room',
    title: 'Standard room - £159 bed & breakfast',
    link: 'https://buy.stripe.com/fZe3e1dZr370few00z',
    img: standardRoom,
  },
  {
    name: 'Club room (premium)',
    title: 'Club room - £169 bed & breakfast',
    link: 'https://buy.stripe.com/6oE8yl5sV6jcaYg4gO',
    img: clubRoom,
  },
];

export const Tickets = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box height="100vh">
      <Header />

      <Box ml="5%" mr="5%">
        <Box mt={3}>
          <Box display="flex" flexWrap="wrap" gap="5px" alignItems="baseline">
            <Typography variant="h2">{t('tickets.tickets')}</Typography>
          </Box>

          <Box mt={2} mb={3} display="flex" gap="20px" flexWrap="wrap">
            {tickets.map((ticket, index) => (
              <Box className={classes.box} key={index}>
                <a href={ticket.link} rel="noreferrer" target="_blank" className={classes.link}>
                  <SmoothImage src={ticket.img} alt="ticket" className={classes.img} />
                </a>
                <a
                  href={ticket.link}
                  rel="noreferrer"
                  target="_blank"
                  style={{ width: 'fit-content' }}
                >
                  <Typography className="is-link is-underlined" variant="body1">
                    {ticket.name}
                  </Typography>
                </a>
              </Box>
            ))}
          </Box>

          <Typography variant="h2">{t('tickets.accommodation')}</Typography>
          <Box mt={2} mb={3} display="flex" gap="20px" flexWrap="wrap">
            {hotels.map((hotel, index) => (
              <Box className={classes.box} key={index}>
                <a href={hotel.link} rel="noreferrer" target="_blank" className={classes.link}>
                  <SmoothImage src={hotel.img} alt="ticket" className={`${classes.img} hotel`} />
                  <Typography className={classes.imgText}>{hotel.name}</Typography>
                </a>
                <a
                  href={hotel.link}
                  rel="noreferrer"
                  target="_blank"
                  style={{ width: 'fit-content' }}
                >
                  <Typography className="is-link is-underlined" variant="body1">
                    {hotel.title}
                  </Typography>
                </a>
              </Box>
            ))}
          </Box>
          <Typography
            variant="body2"
            style={{
              whiteSpace: 'break-spaces',
              fontSize: '1.6rem',
              color: '#344054',
              padding: '20px 0',
            }}
          >
            {t('tickets.terms')}
            <a
              className="is-underlined"
              style={{ color: 'inherit' }}
              rel="noreferrer"
              target="_blank"
              href="https://www.signageandprint.com/legal/ticket-purchase-terms-and-conditions"
            >
              terms of sale.
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
