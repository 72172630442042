import React from 'react';

export function IconLogoDirectory() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1110 72.26"
      width="340px"
      height="30px"
    >
      <defs>
        <clipPath id="clip-path" transform="translate(-71 -475.43)">
          <rect className="cls-1" x="71" y="475.43" width="1110" height="72.26" />
        </clipPath>
      </defs>
      <g className="cls-2">
        <path
          className="cls-3"
          d="M88.4,508.25a22.07,22.07,0,0,1-4.52-1.45,4.83,4.83,0,0,1-2.08-1.62,3.58,3.58,0,0,1-.52-1.88,3.47,3.47,0,0,1,1.48-2.9A7,7,0,0,1,87,499.28a11.32,11.32,0,0,1,3,.36,6.71,6.71,0,0,1,2.3,1.09l.74,3.39a2.23,2.23,0,0,0,2.17,1.74h5.52l-.11-10.71a24.45,24.45,0,0,0-6.16-2.22,35.75,35.75,0,0,0-8.09-.85,20,20,0,0,0-8.67,1.63,11.62,11.62,0,0,0-5,4.3A11.12,11.12,0,0,0,71,504a10.07,10.07,0,0,0,3.29,7.77q3.28,3,10.24,4.49c3.06.63,5.11,1.36,6.13,2.19a3.63,3.63,0,0,1,1.54,2.88,3.55,3.55,0,0,1-1.45,2.89c-1,.77-2.63,1.16-5,1.16a14.16,14.16,0,0,1-3.35-.36,13,13,0,0,1-2.69-1l-1-4a2.23,2.23,0,0,0-2.16-1.68H71l.11,11.07a36.55,36.55,0,0,0,15.41,3.22q7.89,0,11.85-3.37a11,11,0,0,0,4-8.82A9.52,9.52,0,0,0,99,512.69q-3.31-2.78-10.6-4.44"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M123.06,525.17l-3.3-.71V492.73H103.55v4.53a2.23,2.23,0,0,0,1.8,2.19l3.84.73v24.28l-3.34.71a2.23,2.23,0,0,0-1.76,2.18v4.53h20.73v-4.53a2.23,2.23,0,0,0-1.76-2.18"
          transform="translate(-71 -475.43)"
        />
      </g>
      <rect className="cls-3" x="37.83" width="10.93" height="9.01" />
      <g className="cls-2">
        <path
          className="cls-3"
          d="M150.28,521a6.77,6.77,0,0,1-2.64,2.59,8,8,0,0,1-3.87.89c-2.75,0-4.72-1-5.9-2.95a15.74,15.74,0,0,1-1.77-8.09v-.76a23.73,23.73,0,0,1,.8-6.5,9.28,9.28,0,0,1,2.49-4.34,6.26,6.26,0,0,1,4.42-1.57,7.11,7.11,0,0,1,6.47,3.73ZM155,492.73H153.6a2.23,2.23,0,0,0-2.2,1.88l-.46,3a12.9,12.9,0,0,0-4.31-4.18,11.83,11.83,0,0,0-6-1.46,12.86,12.86,0,0,0-8.07,2.58,16.43,16.43,0,0,0-5.18,7.22,29.81,29.81,0,0,0-1.8,10.82v.76a26.19,26.19,0,0,0,1.8,10.09,15.06,15.06,0,0,0,5.25,6.75,13.91,13.91,0,0,0,8.21,2.41,12,12,0,0,0,5.39-1.16,11.71,11.71,0,0,0,4.09-3.36v2.56a9.85,9.85,0,0,1-2,6.55q-2,2.35-6.2,2.36a24.58,24.58,0,0,1-5.3-.64,29,29,0,0,1-3.17-.88,2.22,2.22,0,0,0-2.83,1.56l-1.33,5.13a26.6,26.6,0,0,0,6.51,2.17,33.08,33.08,0,0,0,6.66.76,21.15,21.15,0,0,0,9.77-2.1,15,15,0,0,0,6.28-5.89,17.8,17.8,0,0,0,2.19-9V500.08l2.83-.61a2.21,2.21,0,0,0,1.76-2.17v-4.57H155Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M210.83,525.17l-3.31-.71v-17q0-8-3.29-11.69T195.11,492a12.82,12.82,0,0,0-6.56,1.66,14.25,14.25,0,0,0-4.83,4.67l-.3-3.57a2.23,2.23,0,0,0-2.22-2H167.8v4.53a2.22,2.22,0,0,0,1.8,2.19l3.8.73v24.28l-3.3.71a2.23,2.23,0,0,0-1.76,2.18v4.53h19.9v-4.6a2.23,2.23,0,0,0-1.68-2.16l-2.59-.66V503.91a8,8,0,0,1,2.8-2.73,7.91,7.91,0,0,1,4-1,6.09,6.09,0,0,1,4.63,1.65c1,1.09,1.55,3,1.55,5.59v17l-2.82.68a2.23,2.23,0,0,0-1.7,2.16v4.58h20.19v-4.53a2.23,2.23,0,0,0-1.76-2.18"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M238,521.21a7.75,7.75,0,0,1-3.08,2.62,9.27,9.27,0,0,1-4.19,1,5.46,5.46,0,0,1-3.68-1.15,4,4,0,0,1-1.32-3.23,5,5,0,0,1,1.8-3.84,7.68,7.68,0,0,1,5.26-1.59H238Zm12.89,4.07-2.18-.31a12,12,0,0,1-.13-1.5c0-.52,0-1.05,0-1.58V505.76q0-6.63-4.44-10.19T232,492a27.58,27.58,0,0,0-8,1.08,33.66,33.66,0,0,0-6.66,2.82l.14,10.17h5.71a2.21,2.21,0,0,0,2.19-1.87l.53-3.3a8.69,8.69,0,0,1,2.45-1.18,10.29,10.29,0,0,1,3.06-.42,5.92,5.92,0,0,1,6.58,6.34v2.89h-5.18a25.81,25.81,0,0,0-9.67,1.59,13.23,13.23,0,0,0-5.94,4.38,10.8,10.8,0,0,0-2,6.48,11,11,0,0,0,3.44,8.52q3.43,3.13,9.26,3.13a12.09,12.09,0,0,0,6-1.43,14.65,14.65,0,0,0,4.43-3.82,13.81,13.81,0,0,0,.58,2.32l.24.68a2.24,2.24,0,0,0,2.1,1.49h11.56v-4.4a2.22,2.22,0,0,0-1.91-2.2"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M278.89,521a6.77,6.77,0,0,1-2.64,2.59,8,8,0,0,1-3.87.89c-2.75,0-4.72-1-5.9-2.95a15.74,15.74,0,0,1-1.77-8.09v-.76a23.73,23.73,0,0,1,.8-6.5,9.28,9.28,0,0,1,2.49-4.34,6.26,6.26,0,0,1,4.42-1.57,7.14,7.14,0,0,1,6.47,3.73Zm4.67-28.22h-1.35a2.23,2.23,0,0,0-2.2,1.88l-.46,3a12.9,12.9,0,0,0-4.31-4.18,11.83,11.83,0,0,0-6-1.46,12.86,12.86,0,0,0-8.07,2.58,16.35,16.35,0,0,0-5.18,7.22,29.81,29.81,0,0,0-1.8,10.82v.76a26.19,26.19,0,0,0,1.8,10.09,15.06,15.06,0,0,0,5.25,6.75,13.91,13.91,0,0,0,8.21,2.41,12.07,12.07,0,0,0,5.39-1.16,11.71,11.71,0,0,0,4.09-3.36v2.56a9.91,9.91,0,0,1-2,6.55q-2,2.35-6.2,2.36a24.66,24.66,0,0,1-5.31-.64,29.41,29.41,0,0,1-3.16-.88,2.22,2.22,0,0,0-2.83,1.56l-1.33,5.13a26.6,26.6,0,0,0,6.51,2.17,33.08,33.08,0,0,0,6.66.76,21.18,21.18,0,0,0,9.77-2.1,15,15,0,0,0,6.28-5.89,17.8,17.8,0,0,0,2.18-9V500.08l2.84-.61a2.21,2.21,0,0,0,1.76-2.17v-4.57H283.56Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M318.34,508.47H305.27l-.1-.18a15.76,15.76,0,0,1,1.1-4.34,7.34,7.34,0,0,1,2.26-3,5.73,5.73,0,0,1,3.55-1.1,6.37,6.37,0,0,1,3.6.94,5.34,5.34,0,0,1,2,2.66,11.75,11.75,0,0,1,.64,4.11Zm2.49-14.36a17.54,17.54,0,0,0-8.93-2.1,16.43,16.43,0,0,0-9.36,2.55,17.18,17.18,0,0,0-6.13,7.09A23.48,23.48,0,0,0,294.24,512v1.45a20.76,20.76,0,0,0,2.3,9.91,16.92,16.92,0,0,0,6.49,6.82,19,19,0,0,0,9.81,2.48,25.58,25.58,0,0,0,8.41-1.28,20.52,20.52,0,0,0,6.1-3.24l-1.9-4.31a2.2,2.2,0,0,0-2.94-1.14,20.39,20.39,0,0,1-2.92,1.12,18.91,18.91,0,0,1-5.41.74,9.24,9.24,0,0,1-4.74-1.14,7.74,7.74,0,0,1-3-3.14,11.57,11.57,0,0,1-1.29-4.58l.07-.22H326a2.21,2.21,0,0,0,2.22-2.22V509.7a22,22,0,0,0-1.88-9.55,13.4,13.4,0,0,0-5.5-6"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M352.58,524.05a15.78,15.78,0,0,1-3.84.48,6.88,6.88,0,0,1-5.21-2.08,7.37,7.37,0,0,1-2-5.3,8.66,8.66,0,0,1,.67-3.31,10.18,10.18,0,0,1,2.52-3.38l.68-.47,10.86,12.59a15.79,15.79,0,0,1-3.69,1.47m-6.26-35.9a4.37,4.37,0,0,1,3.62-1.57,4.51,4.51,0,0,1,3.45,1.41,4.57,4.57,0,0,1,1.32,3.22,6.44,6.44,0,0,1-.7,3,7.46,7.46,0,0,1-2.74,2.68l-3.11,2a20.3,20.3,0,0,1-2.33-3.64,7.44,7.44,0,0,1-.78-3.2,6,6,0,0,1,1.27-3.93m26,27.27a32.63,32.63,0,0,0,1.23-9.19h-5.77a2.21,2.21,0,0,0-2.21,2,18.53,18.53,0,0,1-.63,3.38,19.19,19.19,0,0,1-2,4.7l-10-11.65,3.37-2.38a17.56,17.56,0,0,0,4.84-5.14,12.19,12.19,0,0,0,1.77-6.48,11.72,11.72,0,0,0-1.64-6.15,12.28,12.28,0,0,0-4.56-4.4,13.55,13.55,0,0,0-6.75-1.64,16.14,16.14,0,0,0-7.58,1.7,12.1,12.1,0,0,0-5,4.78,14.29,14.29,0,0,0-1.77,7.27,13.8,13.8,0,0,0,1.21,5.61,32.86,32.86,0,0,0,3.67,6.07c-.12.08-.25.16-.38.26l-.38.29a28.16,28.16,0,0,0-6.58,6.17A11.83,11.83,0,0,0,331,517.7a13.87,13.87,0,0,0,8.1,13.06,20.71,20.71,0,0,0,9.12,1.88,23.56,23.56,0,0,0,7-1,25.16,25.16,0,0,0,6.34-3l2.78,3.22H376l.08-.18-7.53-8.83a22.08,22.08,0,0,0,3.76-7.45"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M398.37,524.17q-4.92,0-6.84-3.87v-16q1.85-3.76,6.77-3.76a6.29,6.29,0,0,1,5.62,3c1.3,2,1.94,4.78,1.94,8.43q0,12.26-7.49,12.26M401.05,492a12.27,12.27,0,0,0-9.91,4.56l-.18-1.83a2.21,2.21,0,0,0-2.21-2h-7.67v54.2h10.45V528.48a12.21,12.21,0,0,0,9.59,4.12,13.16,13.16,0,0,0,11.09-5.44q4.11-5.45,4.11-14.49v-.55q0-9.4-4-14.76A13.26,13.26,0,0,0,401.05,492"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M430.65,497.4l-.16-2.58a2.22,2.22,0,0,0-2.22-2.09h-7.79v39.15h10.46V506.26c1.23-2.68,3.82-4,7.78-4,.44,0,.91,0,1.4,0a2.23,2.23,0,0,0,2.4-2.18l.11-7.63a10.8,10.8,0,0,0-3.26-.47q-5.56,0-8.72,5.39"
          transform="translate(-71 -475.43)"
        />
      </g>
      <rect className="cls-3" x="375.58" width="10.93" height="9.01" />
      <rect className="cls-3" x="375.79" y="17.3" width="10.49" height="39.15" />
      <g className="cls-2">
        <path
          className="cls-3"
          d="M485,492a13.67,13.67,0,0,0-11.25,5.24l-.18-2.46a2.22,2.22,0,0,0-2.22-2.06h-7.77v39.15H474V504.27a7.19,7.19,0,0,1,6.62-3.76c2.24,0,3.85.5,4.81,1.5s1.45,2.61,1.45,4.83v25h10.45V506.59q-.06-7.27-3.11-10.93T485,492"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M520.15,524c-1.57,0-2.63-.31-3.18-.94a4.63,4.63,0,0,1-.83-3.11V500.4h6.69v-7.67h-6.69V483.1H507.9a2.23,2.23,0,0,0-2.22,2.23v7.4H500v5.45a2.23,2.23,0,0,0,2.23,2.22h3.49v21.09q.21,11.12,11.22,11.11a21.42,21.42,0,0,0,6.33-.94v-7.92a16.69,16.69,0,0,1-3.08.25"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M625.48,531.86h-6.69V492.72h6.69Zm-7.24-49.52a4,4,0,0,1,1-2.75,3.72,3.72,0,0,1,3-1.12,3.77,3.77,0,0,1,3,1.12,3.93,3.93,0,0,1,1,2.75,4,4,0,1,1-7.93,0"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M642.51,492.72l.22,4.92A14.27,14.27,0,0,1,654.45,492q12.4,0,12.51,14v25.87h-6.69V506c0-2.82-.67-4.91-1.93-6.26s-3.24-2-5.92-2a9.62,9.62,0,0,0-5.71,1.73,11.76,11.76,0,0,0-3.83,4.56v27.89h-6.7V492.72Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M699.84,521.47a4.93,4.93,0,0,0-2-4.21q-2-1.5-7.13-2.59a33.6,33.6,0,0,1-8.06-2.6,11.52,11.52,0,0,1-4.42-3.62,8.66,8.66,0,0,1-1.42-5,10.17,10.17,0,0,1,4.06-8.14,16,16,0,0,1,10.4-3.32q6.66,0,10.8,3.43a10.91,10.91,0,0,1,4.14,8.79h-6.73a6,6,0,0,0-2.33-4.74,8.76,8.76,0,0,0-5.88-2,9.08,9.08,0,0,0-5.71,1.6,5,5,0,0,0-2.06,4.16,4.07,4.07,0,0,0,1.91,3.65,23.71,23.71,0,0,0,6.93,2.35,36,36,0,0,1,8.12,2.68,11.79,11.79,0,0,1,4.61,3.74,9.21,9.21,0,0,1,1.5,5.33,10,10,0,0,1-4.19,8.41q-4.2,3.17-10.89,3.17a19.71,19.71,0,0,1-8.32-1.67,13.43,13.43,0,0,1-5.66-4.64,11.22,11.22,0,0,1-2-6.46h6.69a6.93,6.93,0,0,0,2.69,5.33,10.44,10.44,0,0,0,6.64,2,10.81,10.81,0,0,0,6.09-1.54,4.71,4.71,0,0,0,2.3-4.11"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M724.33,483.24v9.48h7.3v5.17h-7.3v24.3a5.37,5.37,0,0,0,1,3.53,4.06,4.06,0,0,0,3.32,1.18,16.25,16.25,0,0,0,3.19-.43v5.39a19.59,19.59,0,0,1-5.14.72c-3,0-5.25-.91-6.77-2.71s-2.27-4.38-2.27-7.71V497.89h-7.13v-5.17h7.13v-9.48Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M752.5,526.76a11.81,11.81,0,0,0,6-1.63,9.89,9.89,0,0,0,4.09-4.23v-8h-5.25q-12.3,0-12.29,7.19a6.08,6.08,0,0,0,2.09,4.92,8,8,0,0,0,5.39,1.78m11.11,5.1a14,14,0,0,1-.94-4.13,15,15,0,0,1-11.14,4.85,13.83,13.83,0,0,1-9.5-3.27,10.61,10.61,0,0,1-3.7-8.31,11.07,11.07,0,0,1,4.64-9.49q4.65-3.37,13.08-3.38h6.51v-3.08a7.48,7.48,0,0,0-2.1-5.58q-2.1-2.09-6.18-2.08a9.81,9.81,0,0,0-6,1.8,5.35,5.35,0,0,0-2.42,4.38h-6.73a9.22,9.22,0,0,1,2.08-5.66,14.24,14.24,0,0,1,5.64-4.32,19,19,0,0,1,7.83-1.59q6.76,0,10.6,3.38a12.19,12.19,0,0,1,4,9.31v18a22.17,22.17,0,0,0,1.37,8.57v.58Z"
          transform="translate(-71 -475.43)"
        />
      </g>
      <rect className="cls-3" x="709.32" y="0.86" width="6.69" height="55.56" />
      <rect className="cls-3" x="727.29" y="0.86" width="6.69" height="55.56" />
      <g className="cls-2">
        <path
          className="cls-3"
          d="M831.1,497.5a8.93,8.93,0,0,0-6.8,2.94,14.33,14.33,0,0,0-3.4,8.27h19.6v-.51q-.29-5.1-2.75-7.9a8.37,8.37,0,0,0-6.65-2.8m.83,35.08A17.1,17.1,0,0,1,819,527.35q-5-5.22-5-14v-1.23a23.54,23.54,0,0,1,2.22-10.4,17.42,17.42,0,0,1,6.23-7.16A15.65,15.65,0,0,1,831.1,492q7.64,0,11.86,5t4.23,14.4v2.79H820.68a13.9,13.9,0,0,0,3.38,9.34,10.64,10.64,0,0,0,8.23,3.57,11.61,11.61,0,0,0,6-1.45,15,15,0,0,0,4.3-3.83l4.09,3.18q-4.92,7.56-14.76,7.56"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M873.85,498.72a19.91,19.91,0,0,0-3.29-.25q-6.59,0-8.93,5.61v27.77h-6.69V492.72h6.51l.1,4.52a10.44,10.44,0,0,1,9.34-5.24,6.92,6.92,0,0,1,3,.5Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M903.4,512.72q0,6.66,2.75,10.42a8.89,8.89,0,0,0,7.6,3.76,9.77,9.77,0,0,0,9.29-5.71v-18a9.82,9.82,0,0,0-9.22-5.53,8.93,8.93,0,0,0-7.67,3.79c-1.83,2.54-2.75,6.28-2.75,11.25m-6.69-.76q0-9,4.27-14.48A14.52,14.52,0,0,1,923,496.7V476.3h6.69v55.56h-6.15l-.32-4.2q-4,4.92-11.18,4.92A13.36,13.36,0,0,1,901,527q-4.29-5.57-4.29-14.54Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M947.38,531.86h-6.69V492.72h6.69Zm-7.23-49.52a4,4,0,0,1,1-2.75,3.74,3.74,0,0,1,3-1.12,3.8,3.8,0,0,1,3,1.12,4,4,0,0,1,1,2.75,4,4,0,1,1-7.92,0"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M977,498.72a20,20,0,0,0-3.3-.25q-6.57,0-8.93,5.61v27.77h-6.69V492.72h6.51l.11,4.52A10.42,10.42,0,0,1,974,492a7,7,0,0,1,3,.5Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M997.87,497.5a9,9,0,0,0-6.8,2.94,14.4,14.4,0,0,0-3.4,8.27h19.61v-.51q-.28-5.1-2.75-7.9a8.4,8.4,0,0,0-6.66-2.8m.84,35.08a17.08,17.08,0,0,1-13-5.23q-5-5.22-5-14v-1.23a23.54,23.54,0,0,1,2.22-10.4,17.47,17.47,0,0,1,6.22-7.16,15.67,15.67,0,0,1,8.66-2.58q7.64,0,11.87,5t4.23,14.4v2.79H987.46a13.85,13.85,0,0,0,3.38,9.34,10.62,10.62,0,0,0,8.23,3.57,11.59,11.59,0,0,0,6-1.45,15,15,0,0,0,4.31-3.83l4.09,3.18q-4.94,7.56-14.76,7.56"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M1037.41,527.12a9.71,9.71,0,0,0,6.26-2.17,7.65,7.65,0,0,0,3-5.43H1053a12.13,12.13,0,0,1-2.31,6.4,15.27,15.27,0,0,1-5.7,4.85,16.4,16.4,0,0,1-7.54,1.81q-8,0-12.72-5.34T1020,512.65v-1.12a23.58,23.58,0,0,1,2.1-10.17,16,16,0,0,1,6-6.9,17.12,17.12,0,0,1,9.28-2.46,15.67,15.67,0,0,1,10.94,3.94,14.07,14.07,0,0,1,4.65,10.24h-6.33a9.19,9.19,0,0,0-2.87-6.24,9,9,0,0,0-6.39-2.44,9.35,9.35,0,0,0-7.9,3.67q-2.81,3.66-2.8,10.61v1.27q0,6.76,2.78,10.42a9.43,9.43,0,0,0,8,3.65"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M1069.56,483.24v9.48h7.31v5.17h-7.31v24.3a5.43,5.43,0,0,0,1,3.53,4.09,4.09,0,0,0,3.33,1.18,16.08,16.08,0,0,0,3.18-.43v5.39a19.48,19.48,0,0,1-5.14.72c-3,0-5.24-.91-6.76-2.71s-2.28-4.38-2.28-7.71V497.89h-7.12v-5.17h7.12v-9.48Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M1088.91,512.72q0,6.51,3,10.46a10.24,10.24,0,0,0,16.19-.06q3-4,3-11.19c0-4.3-1-7.77-3-10.44a9.73,9.73,0,0,0-8.12-4,9.61,9.61,0,0,0-8,3.94c-2,2.62-3,6.39-3,11.28m-6.72-.79a23.07,23.07,0,0,1,2.26-10.35,16.85,16.85,0,0,1,6.29-7.09A17.18,17.18,0,0,1,1100,492a16.49,16.49,0,0,1,12.92,5.53q5,5.54,4.94,14.72v.47a23.34,23.34,0,0,1-2.19,10.26,16.6,16.6,0,0,1-6.25,7.07,17.42,17.42,0,0,1-9.35,2.53,16.49,16.49,0,0,1-12.9-5.53q-4.93-5.53-4.93-14.65Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M1145.12,498.72a19.91,19.91,0,0,0-3.29-.25q-6.58,0-8.93,5.61v27.77h-6.7V492.72h6.51l.11,4.52a10.44,10.44,0,0,1,9.33-5.24,7,7,0,0,1,3,.5Z"
          transform="translate(-71 -475.43)"
        />
        <path
          className="cls-3"
          d="M1164.72,522.05l9.12-29.33H1181l-15.73,45.17q-3.66,9.78-11.61,9.77l-1.27-.11-2.5-.47v-5.42l1.81.14a8.93,8.93,0,0,0,5.3-1.37,9.93,9.93,0,0,0,3.13-5l1.48-4-14-38.7H1155Z"
          transform="translate(-71 -475.43)"
        />
      </g>
      <polygon
        className="cls-3"
        points="491.71 60.14 486.02 60.14 507.79 3.53 513.45 3.53 491.71 60.14"
      />
    </svg>
  );
}
