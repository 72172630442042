import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { Box, Button, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { sendVerificationEmailAPI } from 'api';
import { useTypedDispatch } from 'redux/reducers';
import { changeEmail } from 'redux/actions';

const VerifyAccountPopup = ({ email }) => {
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('formErrors.wrongEmailFormat')).required(t('formErrors.required')),
  });

  const handleResendEmail = async () => {
    try {
      const res = await sendVerificationEmailAPI();
      toast.success(res.message);
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const handleChangeEmail = async (values) => {
    await dispatch(changeEmail(values));
    setIsChangeEmail(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const { isVerified } = JSON.parse(localStorage.getItem('profile') as string);

      if (isVerified) {
        window.location.reload();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      {isChangeEmail ? (
        <Formik
          initialValues={initialValues}
          onSubmit={handleChangeEmail}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting }) => (
            <Form noValidate autoComplete="none">
              <Field
                component={TextField}
                label={t('formLabel.email')}
                name="email"
                placeholder={t('placeholders.email')}
                type="text"
              />
              <Box display="flex" mt={1} justifyContent="flex-end">
                <Button
                  disabled={isSubmitting}
                  onClick={() => setIsChangeEmail(false)}
                  type="button"
                  variant="outlined"
                >
                  {t('action.cancel')}
                </Button>

                <Box mx={1} />

                <Button
                  color="primary"
                  disabled={isSubmitting || values.email === email}
                  type="submit"
                  variant="contained"
                >
                  {t('action.changeEmail')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <Box mt={2} height="76px">
            <Typography component="p" variant="body1">
              {t('auth.weSentLink')} <span className="is-bold">{email}</span>
            </Typography>
            <Typography component="p" variant="body1">
              {t('auth.followLink')}
            </Typography>
          </Box>

          <Box display="flex" mt={1} justifyContent="flex-end">
            <Button disableElevation onClick={() => setIsChangeEmail(true)} variant="outlined">
              {t('action.changeEmail')}
            </Button>

            <Box mx={1} />

            <Button color="primary" onClick={handleResendEmail} variant="contained">
              {t('action.resendLink')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export { VerifyAccountPopup };
