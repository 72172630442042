import { AnyAction, combineReducers } from 'redux';
import authReducer from './auth';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import companyReducer from './company';
import modalsReducer from './modals';

const rootReducer = combineReducers({
  auth: authReducer,
  company: companyReducer,
  modals: modalsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;

export default rootReducer;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
