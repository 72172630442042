import React, { useCallback, useEffect } from 'react';
import queryString from 'query-string';
import { verifyEmail } from 'redux/actions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { useTypedDispatch } from 'redux/reducers';
import { routes } from 'config';

const VerifyEmail: React.FunctionComponent = () => {
  const { token } = queryString.parse(location.search);
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const hasToken = localStorage.getItem('token');

  const handleVerifyEmail = useCallback(async () => {
    await dispatch(verifyEmail(token as string));
  }, [dispatch, hasToken, history, t, token]);

  useEffect(() => {
    if (token) {
      handleVerifyEmail()
        .then(() => {
          history.push(routes.home);
          window.location.reload();
        })
        .catch(() => {
          toast.error(hasToken ? t('toast.alreadyVerified') : t('toast.alreadyVerifiedSignIn'));
          history.push('/');
        });
    } else {
      history.push('/');
    }
  }, [dispatch, history, token]);

  return null;
};

export { VerifyEmail };
