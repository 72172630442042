import { SHOW_PRICING_POPUP, SHOW_USERS_POPUP } from '../types';

export const showUsersPopup = (payload: boolean) => ({
  type: SHOW_USERS_POPUP,
  payload,
});

export const showPricingPopup = (payload: boolean) => ({
  type: SHOW_PRICING_POPUP,
  payload,
});
