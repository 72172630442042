import React from 'react';

const BaseLayout = ({ children }) => {
  return (
    <main>
      <div>{children}</div>
    </main>
  );
};

export { BaseLayout };
