export const INSTALLATIONS_SERVICE_ID = 1;
export const INSTALLATIONS_FREELANCER_SERVICE_ID = 30;
export const SUPPLIER_SERVICE_ID = 2;
export const PRINT_SERVICE_ID = 3;
export const SIGNAGE_SERVICE_ID = 4;

export const TRADE_ONLY_ID = 1;
export const TRADE_AND_END_CLIENTS_ID = 2;
export const END_CLIENTS_ONLY_ID = 3;

export const KILOMETERS = 'km';
export const MILES = 'mi';

export const USD = 'USD';
export const EUR = 'EUR';
export const GBP = 'GBP';

export const CURRENCY = {
  [USD]: '$',
  [EUR]: '€',
  [GBP]: '£',
};

export const ACTIVE_STATUS = 1;
export const PENDING_STATUS = 2;
export const INACTIVE_STATUS = 3;

export const STATUSES = {
  [ACTIVE_STATUS]: 'Active',
  [PENDING_STATUS]: 'Invite sent',
  [INACTIVE_STATUS]: 'Archived',
};

export const STATUSES_PRIORITY = {
  [ACTIVE_STATUS]: 1,
  [PENDING_STATUS]: 2,
  [INACTIVE_STATUS]: 3,
};

export const INSTALLER_DIRECTORY = '/installer-directory';
export const FULL_DIRECTORY = '/full-directory';
