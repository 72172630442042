import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles({
  container: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    position: 'relative',

    '&::before': {
      content: '',
      display: 'block',
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0,
    transition: 'all 0.3s ease',

    '&.loaded': {
      opacity: 1,
    },
  },
});

export const SmoothImage = ({ src, alt, className = '' }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <div className={classes.container}>
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        className={classnames(classes.img, loaded && 'loaded', className)}
      />
    </div>
  );
};
