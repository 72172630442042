export const DRAFT = 1;
export const AWAITING_REVIEW = 2;
export const IN_REVIEW = 3;
export const ACCEPTED = 4;
export const CHANGE_REQUIRED = 5;
export const NOT_ELIGIBLE = 6;
export const NOT_SHORTLISTED = 7;
export const SHORTLISTED = 8;
export const DELETED = 9;

export const ENTRY_STATUSES = {
  [DRAFT]: 'Draft',
  [AWAITING_REVIEW]: 'Awaiting Review',
  [IN_REVIEW]: 'In Review',
  [ACCEPTED]: 'Accepted',
  [CHANGE_REQUIRED]: 'Change Required',
  [NOT_ELIGIBLE]: 'Not Eligible',
  [NOT_SHORTLISTED]: 'Not Shortlisted',
  [SHORTLISTED]: 'Shortlisted',
  [DELETED]: 'Deleted',
};
