import React from 'react';

export function IconRefresh() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 8.25002C14.8166 6.93019 14.2043 5.70727 13.2575 4.76965C12.3107 3.83202 11.0818 3.23171 9.76025 3.06119C8.43869 2.89066 7.09772 3.15939 5.9439 3.82596C4.79009 4.49253 3.88744 5.51998 3.375 6.75002M3 3.75002V6.75002H6"
        stroke="#0046FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.75C3.18342 11.0698 3.7957 12.2928 4.74252 13.2304C5.68934 14.168 6.91818 14.7683 8.23975 14.9388C9.56131 15.1094 10.9023 14.8406 12.0561 14.1741C13.2099 13.5075 14.1126 12.48 14.625 11.25M15 14.25V11.25H12"
        stroke="#0046FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
