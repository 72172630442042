import axios from 'axios';
import toast from 'react-hot-toast';
import { decodeToken } from 'react-jwt';

import { IEmail, IForgotPassword, IProfile, IResetPassword, ISignIn, ISignUp } from 'interfaces';
import { routes } from 'config';
import {
  CATCH_UNAUTHORIZED,
  RESET_AUTH_TOKEN,
  SET_AUTH_TOKEN,
  SET_PROFILE,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
} from '../types';
import {
  billingPortalAPI,
  changeEmailAPI,
  confirmUserAPI,
  forgotPasswordAPI,
  getProfileAPI,
  resetPasswordAPI,
  signInAPI,
  signUpAPI,
  verifyEmailAPI,
} from 'api';
import { FULL_DIRECTORY, INSTALLER_DIRECTORY } from 'utils';

export const setAuthToken = (token: string) => ({
  type: SET_AUTH_TOKEN,
  token,
});

export const setProfile = (profile: IProfile) => ({
  type: SET_PROFILE,
  profile,
});

export const resetAuthToken = () => ({
  type: RESET_AUTH_TOKEN,
});

export const signIn = (values: ISignIn) => {
  return async (dispatch) => {
    dispatch({ type: SIGN_IN });
    try {
      const { token, ...rest } = await signInAPI(values);

      localStorage.setItem('token', token);
      localStorage.setItem('profile', JSON.stringify(rest));
      const route = localStorage.getItem('pathType') || routes.home;

      window.location.replace(route);
    } catch (e: any) {
      toast.error(e.message);
    }
  };
};

export const signUp = (values: ISignUp) => {
  return async (dispatch) => {
    dispatch({ type: SIGN_UP });
    try {
      const { token, ...rest } = (await signUpAPI(values)) as any;

      localStorage.setItem('token', token);
      localStorage.setItem('profile', JSON.stringify(rest));
      const route = localStorage.getItem('pathType') || routes.home;

      window.location.replace(route);
    } catch (e: any) {
      toast.error(e.message);
    }
  };
};

export const confirmUser = (userToken: string, values: ISignUp) => {
  return async () => {
    try {
      const { token, ...rest } = (await confirmUserAPI(userToken, values)) as any;

      localStorage.setItem('token', token);
      localStorage.setItem('profile', JSON.stringify(rest));

      window.location.replace(routes.home);
    } catch (e: any) {
      toast.error(e.message);
    }
  };
};

export const signOut = () => {
  return async (dispatch) => {
    dispatch({ type: SIGN_OUT });
    dispatch(resetAuthToken());

    localStorage.removeItem('token');
    localStorage.removeItem('profile');

    const pathType = localStorage.getItem('pathType');

    if (pathType !== FULL_DIRECTORY && pathType !== INSTALLER_DIRECTORY) {
      localStorage.removeItem('pathType');
    }

    try {
      const regs = await navigator?.serviceWorker?.getRegistrations();

      await Promise.allSettled(regs.map((reg) => reg.unregister()));
    } catch (e) {
      console.log(e);
    }
    delete axios.defaults.headers.common.Authorization;
  };
};

export const catchUnauthorized = (onMessage?: () => void) => {
  return async (dispatch) => {
    dispatch({ type: CATCH_UNAUTHORIZED });
    onMessage && onMessage();
    dispatch(signOut());
  };
};

export const forgotPassword = (values: IForgotPassword) => {
  return async () => {
    try {
      const res = await forgotPasswordAPI(values);
      toast.success(res.message);
    } catch (e: any) {
      toast.error(e.message);
      throw new Error(e);
    }
  };
};

export const resetPassword = (userToken: string, values: IResetPassword) => {
  return async (dispatch) => {
    try {
      const { token, ...rest } = (await resetPasswordAPI(userToken, values)) as any;

      localStorage.setItem('token', token);
      localStorage.setItem('profile', JSON.stringify(rest));
      dispatch(setProfile(rest));

      window.location.replace(routes.home);
    } catch (e: any) {
      toast.error(e.message);
      throw new Error(e);
    }
  };
};

export const changeEmail = (values: IEmail) => {
  return async (dispatch) => {
    try {
      const { token, message } = await changeEmailAPI(values);
      const profile = JSON.parse(localStorage.getItem('profile') as string);
      const decoded = decodeToken(token) as { token: string; user: string };
      profile.email = decoded.user;

      localStorage.setItem('token', token);
      localStorage.setItem('profile', JSON.stringify(profile));

      dispatch(setProfile(profile));
      toast.success(message);
    } catch (e: any) {
      toast.error(e.message);
      throw new Error(e);
    }
  };
};

export const verifyEmail = (userToken: string) => {
  return async (dispatch) => {
    try {
      const { token, ...rest } = await verifyEmailAPI(userToken);

      localStorage.setItem('token', token);
      localStorage.setItem('profile', JSON.stringify(rest));

      dispatch(setProfile(rest));
    } catch (e: any) {
      throw new Error(e);
    }
  };
};

export const getProfile = () => {
  return async (dispatch) => {
    try {
      const res = await getProfileAPI();
      const profile = JSON.parse(localStorage.getItem('profile') as string);

      localStorage.setItem('profile', JSON.stringify({ ...profile, ...res }));
      dispatch(setProfile(res));
    } catch (e: any) {
      console.log(e);
    }
  };
};

export const getBillingPortal = (id, values) => {
  return async (dispatch) => {
    try {
      const { link } = await billingPortalAPI(id, values);

      const profile = JSON.parse(localStorage.getItem('profile') as string);
      profile.company.billingPortal = link;

      localStorage.setItem('profile', JSON.stringify(profile));
      dispatch(setProfile(profile));
    } catch (e: any) {
      return e;
    }
  };
};
